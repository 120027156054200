import { stage_url, sub_urls } from "../api-urls";
import axios from "axios";
import Headers from "./Headers";

export const ProductsApi = {
  GetProductsListByFilter: async (payload) => {
    const response = await axios.get(
      stage_url + sub_urls.products.getProductsByFilter,
      {
        params: {
          page: payload.page,
          size: payload.size,
          brandId: payload.brandId,
          isActive: payload.isActive,
        },
        headers: Headers.getHeaders(),
      }
    );
    return response;
  },
  SearchProducts: async (payload) => {
    const response = await axios.get(
      stage_url + sub_urls.products.searchProductByKeyword,
      {
        params: {
          page: payload.page,
          size: payload.size,
          keyword: payload.keyword,
          brandId: payload.brandId,
          isActive: payload.isActive,
        },
        headers: Headers.getHeaders(),
      }
    );
    return response;
  },
  AddProduct: async (payload) => {
    const response = await axios.post(
      stage_url + sub_urls.products.productBaseUrl,
      payload,
      {
        headers: Headers.getHeaders(),
      }
    );
    return response;
  },
  AddVariant: async (id, payload) => {
    const response = await axios.post(
      stage_url + sub_urls.products.productBaseUrl + "/" + id + "/variant",
      payload,
      {
        headers: Headers.getHeaders(),
      }
    );
    return response;
  },
  AddColor: async (id, payload) => {
    const response = await axios.post(
      stage_url + sub_urls.products.productBaseUrl + "/" + id + "/color",
      payload,
      {
        headers: Headers.getHeaders(),
      }
    );
    return response;
  },
  AddPrice: async (id, payload) => {
    const response = await axios.post(
      stage_url + sub_urls.products.productBaseUrl + "/" + id + "/price",
      payload,
      {
        headers: Headers.getHeaders(),
      }
    );
    return response;
  },
  UpdatePrice: async (id, payload, pId) => {
    const response = await axios.post(
      stage_url +
        sub_urls.products.productBaseUrl +
        "/" +
        id +
        "/price?priceId=" +
        pId,
      payload,
      {
        headers: Headers.getHeaders(),
      }
    );
    return response;
  },
  UpdateCurrentPrice: async (id, payload, pId) => {
    const response = await axios.put(
      stage_url + sub_urls.products.productBaseUrl + "/" + id + "/price/" + pId,
      payload,
      {
        headers: Headers.getHeaders(),
      }
    );
    return response;
  },
  AddMultiplePrice: async (id, payload) => {
    const response = await axios.post(
      stage_url +
        sub_urls.products.productAddMultiple +
        "/" +
        id +
        "/price/multiple",
      payload,
      {
        headers: Headers.getHeaders(),
      }
    );
    return response;
  },
  EditProduct: async (id, payload) => {
    const response = await axios.put(
      stage_url + sub_urls.products.productBaseUrl + "/" + id,
      payload,
      {
        headers: Headers.getHeaders(),
      }
    );
    return response;
  },
  EditVariant: async (pid, id, payload) => {
    const response = await axios.put(
      stage_url +
        sub_urls.products.productBaseUrl +
        "/" +
        pid +
        "/variant/" +
        id,
      payload,
      {
        headers: Headers.getHeaders(),
      }
    );
    return response;
  },
  EditColor: async (pid, id, payload) => {
    const response = await axios.put(
      stage_url + sub_urls.products.productBaseUrl + "/" + pid + "/color/" + id,
      payload,
      {
        headers: Headers.getHeaders(),
      }
    );
    return response;
  },
  EditPrice: async (pid, id, payload) => {
    const response = await axios.put(
      stage_url + sub_urls.products.productBaseUrl + "/" + pid + "/price/" + id,
      payload,
      {
        headers: Headers.getHeaders(),
      }
    );
    return response;
  },
  GetCategoryList: async (payload) => {
    const response = await axios.get(
      stage_url + sub_urls.category.getByFilter,
      {
        params: {
          page: payload.page,
          size: payload.size,
        },
        headers: Headers.getHeaders(),
      }
    );
    return response;
  },
  GetGroups: async (payload) => {
    const response = await axios.get(stage_url + sub_urls.group.search, {
      params: {
        page: payload.page,
        size: payload.size,
        keyword: payload.keyword,
      },
      headers: Headers.getHeaders(),
    });
    return response;
  },
};
