import React, { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Grid from "@mui/material/Unstable_Grid2";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import { ToastContainer, toast } from "react-toastify";
import { useNavigate } from "react-router";
import TableHeader from "components/Table/TableHeader";
import TableElement from "components/Table/TableElement";
import { NotificationApi } from "apis/NotificationApi";
import { NotificationTopicsApi } from "apis/NotificationTopicsApi";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import { createSvgIcon, Divider, useMediaQuery } from "@mui/material";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import MatAutoComplete from "components/MatAutoComplete/MatAutoComplete";
import { formatDateTime } from "utils";
import { formatDateTimeComponent } from "utils";
import CircularIndeterminateModal from "../../utils/Loading";
import { useDispatch, useSelector } from "react-redux";
import { setLoadingPanel } from "../../redux/features/loading";

const Notifications = () => {
  const navigate = useNavigate();
  const color = "light";
  const [notification, setNotification] = useState([]);
  const dispatch = useDispatch();
  const isLoading = useSelector((state) => state.loadingReducer.isLoading);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState();
  const [topics, setTopics] = useState([]);
  const [isEdit, setIsEdit] = useState(false);
  const [formData, setFormData] = useState({
    id: null,
    title: null,
    body: null,
    image: null,
    isActive: null,
    topics: [],
    isImportant: null,
  });
  const [open, setOpen] = useState(false);

  const handlePageChange = (event, page) => {
    setCurrentPage(page);
  };

  const addNotification = () => {
    NotificationApi.SendNotification(formData)
      .then((res) => {
        toast("Notification Sent and Save.", {
          type: "success",
          position: toast.POSITION.TOP_CENTER,
          autoClose: 1000,
        });
        setOpen(false);
        setCurrentPage(1);
      })
      .catch((err) => {
        if (err?.response?.data) {
          toast(err.response.status + " " + err?.response?.data?.message, {
            type: "error",
            position: toast.POSITION.TOP_CENTER,
            autoClose: 1000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            style: {
              maxWidth: "90vw",
              width: "auto",
              margin: "5px  auto",
              padding: "5px 5px",
              fontSize: "14px",
            },
            closeButton: (
              <div className="flex justify-end">
                <button className="text-gray-500 hover:text-gray-800 focus:outline">
                  <CloseIcon className="w-7 h-7" />
                </button>
              </div>
            ),
          });
        } else {
          toast(err?.response?.data?.message, {
            type: "error",
            position: toast.POSITION.TOP_CENTER,
            autoClose: 1000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            style: {
              maxWidth: "90vw",
              width: "auto",
              margin: "5px  auto",
              padding: "5px 5px",
              fontSize: "14px",
            },
            closeButton: (
              <div className="flex justify-end">
                <button className="text-gray-500 hover:text-gray-800 focus:outline">
                  <CloseIcon className="w-7 h-7" />
                </button>
              </div>
            ),
          });
        }
        if (err.response.status === 401) {
          localStorage.clear();
          navigate("/");
        }
      });
  };

  const EditNotification = (row) => {
    setIsEdit(true);
    setFormData({
      id: row.id,
      title: row.title,
      body: row.body,
      image: row.image,
      isActive: row.isActive,
      topics: row.topics,
      isImportant: row.isImportant,
    });
    setTopics(row.topics);
    setOpen(true);
  };

  const edit = () => {
    NotificationApi.EditNotification(formData.id, formData)
      .then((res) => {
        toast("Notification Updated", {
          type: "success",
          position: toast.POSITION.TOP_CENTER,
          autoClose: 1000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          style: {
            maxWidth: "90vw",
            width: "auto",
            margin: "5px  auto",
            padding: "5px 5px",
            fontSize: "14px",
          },
          closeButton: (
            <div className="flex justify-end">
              <button className="text-gray-500 hover:text-gray-800 focus:outline">
                <CloseIcon className="w-7 h-7" />
              </button>
            </div>
          ),
        });
        setOpen(false);
        setCurrentPage(1);
      })
      .catch((err) => {
        if (err?.response?.data) {
          toast(err.response.status + " " + err?.response?.data?.message, {
            type: "error",
            position: toast.POSITION.TOP_CENTER,
            autoClose: 1000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            style: {
              maxWidth: "90vw",
              width: "auto",
              margin: "5px  auto",
              padding: "5px 5px",
              fontSize: "14px",
            },
            closeButton: (
              <div className="flex justify-end">
                <button className="text-gray-500 hover:text-gray-800 focus:outline">
                  <CloseIcon className="w-7 h-7" />
                </button>
              </div>
            ),
          });
        } else {
          toast(err?.response?.data?.message, {
            type: "error",
            position: toast.POSITION.TOP_CENTER,
            autoClose: 1000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            style: {
              maxWidth: "90vw",
              width: "auto",
              margin: "5px  auto",
              padding: "5px 5px",
              fontSize: "14px",
            },
            closeButton: (
              <div className="flex justify-end">
                <button className="text-gray-500 hover:text-gray-800 focus:outline">
                  <CloseIcon className="w-7 h-7" />
                </button>
              </div>
            ),
          });
        }
        if (err.response.status === 401) {
          localStorage.clear();
          navigate("/");
        }
      });
  };

  const handleClose = () => {
    setOpen(false);
    setFormData({
      id: null,
      title: null,
      body: null,
      image: null,
      isActive: null,
      topics: [],
      isImportant: null,
    });
    setIsEdit(false);
  };

  useEffect(() => {
    const getNotification = async (payload) => {
      dispatch(setLoadingPanel(true));
      try {
        const { data } = await NotificationApi.GetNotification(payload);
        setNotification(data.data);
        setTotalPages(data.pages);
        dispatch(setLoadingPanel(false));
      } catch (err) {
        dispatch(setLoadingPanel(false));
        if (err?.response?.data) {
          toast(err.response.status + " " + err?.response?.data?.message, {
            type: "error",
            position: toast.POSITION.TOP_CENTER,
            autoClose: 1000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            style: {
              maxWidth: "90vw",
              width: "auto",
              margin: "5px  auto",
              padding: "5px 5px",
              fontSize: "14px",
            },
            closeButton: (
              <div className="flex justify-end">
                <button className="text-gray-500 hover:text-gray-800 focus:outline">
                  <CloseIcon className="w-7 h-7" />
                </button>
              </div>
            ),
          });
        } else {
          toast(err?.response?.data?.message, {
            type: "error",
            position: toast.POSITION.TOP_CENTER,
            autoClose: 1000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            style: {
              maxWidth: "90vw",
              width: "auto",
              margin: "5px  auto",
              padding: "5px 5px",
              fontSize: "14px",
            },
            closeButton: (
              <div className="flex justify-end">
                <button className="text-gray-500 hover:text-gray-800 focus:outline">
                  <CloseIcon className="w-7 h-7" />
                </button>
              </div>
            ),
          });
        }
        if (err.response.status === 401) {
          localStorage.clear();
          navigate("/");
        }
      }
    };
    getNotification({ page: currentPage - 1 });
  }, [currentPage]);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const getTopicsData = () => {
    NotificationTopicsApi.GetNotification({ page: 0 })
      .then((res) => {
        setTopics(res.data.data);
      })
      .catch((err) => {
        toast(err?.response?.data?.message, {
          type: "error",
          position: toast.POSITION.TOP_CENTER,
          autoClose: 1000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          style: {
            maxWidth: "90vw",
            width: "auto",
            margin: "5px  auto",
            padding: "5px 5px",
            fontSize: "14px",
          },
          closeButton: (
            <div className="flex justify-end">
              <button className="text-gray-500 hover:text-gray-800 focus:outline">
                <CloseIcon className="w-7 h-7" />
              </button>
            </div>
          ),
        });
        if (err.status === 401) {
          localStorage.clear();
          navigate("/");
        }
      });
  };

  const getSelectedTopics = (data) => {
    setFormData({
      ...formData,
      topics: data.map((e) => {
        const topicsData = { id: e.id, name: "", groups: [] };
        return topicsData;
      }),
    });
    setTopics(data);
  };
  const isMobile = useMediaQuery("(max-width:768px)");
  const PlusIcon = createSvgIcon(
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth={1.5}
      stroke="currentColor"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M12 4.5v15m7.5-7.5h-15"
      />
    </svg>,
    "Plus"
  );

  return (
    <>
      <CircularIndeterminateModal open={isLoading && !notification.length} />
      <div
        className={
          "relative flex flex-col min-w-0 break-words w-full p-4 mb-6 shadow-lg rounded " +
          (color === "light" ? "bg-white" : "bg-lightBlue-900 text-white")
        }
      >
        <div className="text-right">
          <Button
            onClick={(e) => setOpen(true)}
            fullWidth
            variant="contained"
            color="primary"
            sx={{
              height: { xs: "40px", md: "56px" },
              fontSize: {
                xs: "12px",
                sm: "13px",
                md: "14px",
                lg: "16px",
              },
              maxWidth: { xs: "100%", md: "fit-content" },
              width: "100%",
            }}
            startIcon={<PlusIcon className="h-4 w-4 md:h-5 md:w-5" />}
          >
            Send Notification
          </Button>
        </div>
      </div>

      <div
        className={
          "relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded " +
          (color === "light" ? "bg-white" : "bg-lightBlue-900 text-white")
        }
      >
        <div className="block w-full overflow-x-auto py-3 px-4 lg:p-8">
          <Stack
            spacing={2}
            direction={{ md: "row", xs: "column" }}
            justifyContent={{ md: "space-between", xs: "center" }}
            className="w-full"
          >
            <div className="mb-2 flex justify-center">
              <Pagination
                count={totalPages}
                page={currentPage}
                color="primary"
                onChange={handlePageChange}
                size={isMobile ? "small" : "medium"}
                className="mb-4"
              />
            </div>
          </Stack>
          <table className="min-w-full divide-y divide-gray-200">
            <thead className="bg-gray-100">
              <tr className="divide-x divide-gray-200 ">
                <TableHeader heading="" />
                <TableHeader heading="Title" />
                <TableHeader heading="Body" />
                <TableHeader heading="Image" />
                <TableHeader heading="Status" />
                <TableHeader heading="Topics" />
                <TableHeader heading="Created" />
                <TableHeader heading="" />
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-200 bg-white">
              {notification?.map((n, i) => {
                return (
                  <tr
                    key={i}
                    className="divide-x divide-gray-200 hover:bg-slate-100"
                  >
                    <TableElement value={25 * (currentPage - 1) + i + 1} />
                    <TableElement value={n.title} />
                    <TableElement value={n.body} />
                    <TableElement
                      value={
                        <img
                          loading="lazy"
                          className="w-32 aspect-square object-contain hover:scale-[1.5]"
                          src={
                            n?.image?.startsWith("https://") ||
                            n?.image?.startsWith("http://")
                              ? n?.image
                              : "https://" + n?.image
                          }
                          alt="notifiy"
                        />
                      }
                    />
                    <TableElement
                      value={
                        <>
                          <p
                            className={
                              n.isActive
                                ? " text-emerald-500 font-bold mb-2"
                                : "text-red-500 font-bold mb-2"
                            }
                          >
                            ACTIVE
                          </p>{" "}
                          <p
                            className={
                              n.isImportant
                                ? " text-emerald-500 font-bold mb-2"
                                : "text-red-500 font-bold mb-2"
                            }
                          >
                            IMPORTANT
                          </p>
                        </>
                      }
                    />
                    <TableElement
                      value={n.topics?.map((e) => e.name).join(" ")}
                    />
                    <TableElement
                      value={formatDateTimeComponent(n.createdOn)}
                    />
                    <TableElement
                      value={
                        <span
                          className="cursor-pointer text-sky-500 hover:text-sky-950 font-bold gr "
                          onClick={() => EditNotification(n)}
                        >
                          EDIT
                        </span>
                      }
                      type="element"
                    />
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>

      <Dialog maxWidth={"lg"} fullWidth open={open} onClose={handleClose}>
        <AppBar sx={{ position: "relative" }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} component="div">
              {!isEdit
                ? "Send Notification"
                : `Edit Notification - ${formData.title}`}
            </Typography>
          </Toolbar>
        </AppBar>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12} md={12}>
              <MatAutoComplete
                defaultValue={formData.topics}
                label={"Search by Topics name"}
                getData={getTopicsData}
                options={topics}
                setOptions={setTopics}
                value={topics}
                setValue={getSelectedTopics}
                isMultiple={true}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <TextField
                id="standard-requried"
                required
                sx={{ marginY: 2 }}
                label="Title"
                name="title"
                variant="standard"
                value={formData.title}
                onChange={handleChange}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <TextField
                id="standard-basic"
                required
                label="Body"
                name="body"
                variant="standard"
                sx={{ marginY: 2 }}
                value={formData.body}
                onChange={handleChange}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <TextField
                id="standard-basic"
                required
                label="Image"
                name="image"
                variant="standard"
                sx={{ marginY: 2 }}
                value={formData.image}
                onChange={handleChange}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <FormControl sx={{ marginY: 2, minWidth: "100%" }}>
                <FormLabel id="demo-row-radio-buttons-group-label">
                  Active
                </FormLabel>
                <RadioGroup
                  value={formData.isActive}
                  row
                  onChange={handleChange}
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="isActive"
                >
                  <FormControlLabel
                    value={true}
                    control={<Radio />}
                    label="True"
                  />
                  <FormControlLabel
                    value={false}
                    control={<Radio />}
                    label="False"
                  />
                </RadioGroup>
              </FormControl>
            </Grid>

            <Grid item xs={12} md={4}>
              <FormControl sx={{ marginY: 2, minWidth: "100%" }}>
                <FormLabel id="demo-row-radio-buttons-group-label">
                  Important
                </FormLabel>
                <RadioGroup
                  value={formData.isImportant}
                  row
                  onChange={handleChange}
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="isImportant"
                >
                  <FormControlLabel
                    value={true}
                    control={<Radio />}
                    label="True"
                  />
                  <FormControlLabel
                    value={false}
                    control={<Radio />}
                    label="False"
                  />
                </RadioGroup>
              </FormControl>
            </Grid>

            <Grid item xs={12} md={4}></Grid>

            <Grid item xs={12} md={4}></Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          {isEdit ? (
            <Button onClick={edit}>Save Changes</Button>
          ) : (
            <Button onClick={addNotification}>Save</Button>
          )}
        </DialogActions>
      </Dialog>
      <ToastContainer />
    </>
  );
};

export default Notifications;
