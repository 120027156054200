import React from "react";
import { Link } from "react-router-dom";
import UserDropdown from "components/Dropdowns/UserDropdown.js";

const navigationItems = [
  {
    title: "Brands",
    path: "/admin/brands",
    icon: "fas fa-tools",
  },
  {
    title: "Products",
    path: "/admin/products",
    icon: "fas fa-table",
  },
  {
    title: "Schemes",
    path: "/admin/schemes",
    icon: "fas fa-tv",
  },
  {
    title: "NLC",
    path: "/admin/nlc",
    icon: "fas fa-barcode",
  },
  {
    title: "NLC V2",
    path: "/admin/nlc-v2",
    icon: "fas fa-barcode",
  },
  {
    title: "Price Drop",
    path: "/admin/price-drop",
    icon: "fas fa-chart-simple",
  },
  {
    title: "Posts",
    path: "/admin/posts",
    icon: "fas fa-ioxhost",
  },
  {
    title: "Share",
    path: "/admin/share",
    icon: "fas fa-share",
  },
  {
    title: "Files",
    path: "/admin/files",
    icon: "fas fa-upload",
  },
  {
    title: "States",
    path: "/admin/states",
    icon: "fas fa-landmark",
  },
  {
    title: "District",
    path: "/admin/district",
    icon: "fas fa-monument",
  },
  {
    title: "Groups",
    path: "/admin/groups",
    icon: "fas fa-layer-group",
  },
  {
    title: "Subscriptions",
    path: "/admin/subscriptions",
    icon: "fas fa-person-shelter",
  },
  {
    title: "Bulk Subscription",
    path: "/admin/bulkSubscription",
    icon: "fas fa-chess-bishop",
  },
  {
    title: "Notifications",
    path: "/admin/notifications",
    icon: "fas fa-person-shelter",
  },
  {
    title: "TOTP",
    path: "/admin/totp",
    icon: "fas fa-lock",
  },
  {
    title: "Users",
    path: "/admin/users",
    icon: "fas fa-users",
  },
  {
    title: "Whatsapp",
    path: "/admin/whatsapp",
    icon: "fas fa-ioxhost",
  },
  {
    title: "Notification Topic",
    path: "/admin/notification-topics",
    icon: "fas fa-bell",
  },
  {
    title: "MyDukaan Subscriptions",
    path: "/admin/mydukanSubscriptions",
    icon: "fas fa-chart-simple",
  },
  {
    title: "Analytics",
    path: "/admin/analytics",
    icon: "fas fa-ioxhost",
  },
  {
    title: "Complaints",
    path: "/admin/complaint",
    icon: "fas fa-bug",
  },
];

export default function Sidebar() {
  const [collapseShow, setCollapseShow] = React.useState("hidden");
  const [active, setActive] = React.useState("");

  const NavItem = ({ item }) => {
    const isActive =
      active === item.title || window.location.href.indexOf(item.path) !== -1;
    return (
      <li className="items-center">
        <Link
          className={`text-xs uppercase py-3 font-bold block ${
            isActive
              ? "text-lightBlue-500 hover:text-lightBlue-600"
              : "text-blueGray-700 hover:text-blueGray-500"
          }`}
          onClick={() => {
            setActive(item.title);
            setCollapseShow("hidden");
          }}
          to={item.path}
        >
          <i
            className={`${item.icon} mr-2 text-sm ${
              isActive ? "opacity-75" : "text-blueGray-300"
            }`}
          ></i>{" "}
          {item.title}
        </Link>
      </li>
    );
  };

  return (
    <>
      {collapseShow !== "hidden" && (
        <div
          className="fixed inset-0 bg-black/30 backdrop-blur-sm z-30 transition-opacity md:hidden"
          onClick={() => setCollapseShow("hidden")}
        />
      )}
      <nav
        className={`
          fixed top-0 left-0 bottom-0 flex flex-col w-80 
          bg-white shadow-xl z-40 transition-transform duration-300 ease-in-out
          ${collapseShow === "hidden" ? "-translate-x-full" : "translate-x-0"}
          md:translate-x-0 md:w-64 md:z-10
        `}
      >
        <div className="flex-1 flex flex-col min-h-0">
          <div className="flex items-center justify-between px-2 sm:px-6 h-16 border-b border-gray-200">
            <Link
              className="md:block text-left md:pb-4 text-blueGray-600 mr-0 inline-block whitespace-nowrap text-sm uppercase font-bold p-4 px-0"
              to="/"
            >
              SB Admin
            </Link>
            <button
              className="md:hidden text-gray-500 hover:text-gray-700"
              onClick={() => setCollapseShow("hidden")}
            >
              <i className="fas fa-times text-xl"></i>
            </button>
          </div>

          <div className="flex-1 px-5 py-4 overflow-y-auto whitespace-nowrap h-full">
            <ul className="space-y-1">
              {navigationItems.map((item, index) => (
                <NavItem key={index} item={item} />
              ))}
            </ul>
          </div>
        </div>
      </nav>

      <div className="flex items-center justify-between px-6 h-16 md:h-0 border-b border-gray-200">
        <div className="flex flex-row-reverse gap-2">
          <Link
            className="md:block text-left md:pb-2 text-blueGray-600 mr-0 inline-block whitespace-nowrap text-sm uppercase font-bold p-4 px-0"
            to="/"
          >
            SB Admin
          </Link>
          <button
            className="top-4 left-4 md:hidden p-2 rounded-md"
            onClick={() => setCollapseShow("visible")}
          >
            <i className="fas fa-bars text-xl text-gray-600"></i>
          </button>
        </div>
        <div className="flex gap-2">
          <div className="flex justify-end">
            <ul className="items-center flex flex-wrap list-none">
              <li className="inline-block relative">
                <UserDropdown />
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
}
