import { AnalyticsApi } from "apis/AnalyticsApi";
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Unstable_Grid2";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import TableHeader from "components/Table/TableHeader";
import TableElement from "components/Table/TableElement";
import FormControl from "@mui/material/FormControl";
import OutlinedInput from "@mui/material/OutlinedInput";
import Divider from "@mui/material/Divider";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { formatDateTimeComponent } from "utils";
import { DialogActions } from "@mui/material";
import ExcelExport from "components/ExcelExport/ExcelExport";
import { BarChart } from "@mui/x-charts/BarChart";
import { LineChart } from "@mui/x-charts";
import CircularIndeterminateModal from "../../utils/Loading";
import { useDispatch, useSelector } from "react-redux";
import { setLoadingPanel } from "../../redux/features/loading";

function Analytics() {
  const navigate = useNavigate();
  const color = "light";
  const LastDayOfMonth = (y, m) => {
    return new Date(y, m, 0).getDate();
  };
  const date = new Date();
  const month = date.getMonth() + 1;
  const year = date.getFullYear();
  const periodFrom = `${year}-${month > 9 ? month : "0" + month}-01`;
  const periodTo = `${year}-${month > 9 ? month : "0" + month}-${LastDayOfMonth(
    year,
    month
  )}`;
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState();
  const [totalElements, setTotalElements] = useState(0);

  const [event, setEvent] = useState([]);
  const [xLabels, setXLabels] = useState([]);
  const [series, setSeries] = useState([]);
  const [eventCount, setEventCount] = useState([]);
  const [uniqueEvents, setUniqueEvents] = useState([]);
  const [filterPayload, setFilterPayload] = useState({
    from: periodFrom,
    to: periodTo,
    name: null,
    type: [],
    category: null,
    mobile: null,
    email: null,
    sortBy: null,
    direction: null,
  });

  const [excelData, setExcelData] = useState([]);

  useEffect(() => {
    if (filterPayload.category) {
      getQniqueEvent();
    }
  }, [filterPayload.category]);

  const category = [
    "OTP",
    "LOGIN",
    "FCM_TOKEN",
    "USER",
    "API",
    "OFFERS",
    "SHARE",
    "FCM_TOKEN",
    "USER",
    "API",
    "OFFERS",
    "PAYMENT",
    "HOME",
    "FEATURE",
    "SCHEMES",
    "NLC",
    "PRICE_DROP",
    "PRICE_LIST",
    "PROMOTIONS",
    "SAMACHAR",
    "NOTIFICATIONS",
    "SUPPORT",
    "PROFILE",
  ];

  const getEvent = (page, size) => {
    dispatch(setLoadingPanel(true));
    const typeValue =
      Array.isArray(filterPayload.type) && filterPayload.type.length > 0
        ? filterPayload.type.join(",")
        : null;

    AnalyticsApi.GetEventByFilter({
      page: page,
      size: size,
      from: `${filterPayload.from} 00:00:00`,
      to: `${filterPayload.to} 23:59:59`,
      name: filterPayload.name,
      type: typeValue,
      category: filterPayload.category,
      mobile: filterPayload.mobile,
      email: filterPayload.email,
      sortBy: filterPayload.sortBy,
      direction: filterPayload.direction,
    })
      .then((res) => {
        setEvent(res.data.data);
        const excelDataArray = res.data.data.map((m) => ({
          username: m?.user?.name,
          mobile: m?.user?.mobile,
          email: m?.user?.email,
          name: m?.name,
          category: m?.category,
          type: m?.type,
          datetime: m?.datetime,
        }));
        setExcelData(excelDataArray);
        setTotalElements(res.data.totalElements);
        setTotalPages(res.data.pages);
        dispatch(setLoadingPanel(false));
      })
      .catch((err) => {
        dispatch(setLoadingPanel(false));
        if (err?.response?.data) {
          toast(err.response.status + " " + err?.response?.data?.message, {
            type: "error",
            position: toast.POSITION.TOP_CENTER,
            autoClose: 1000,
          });
        } else {
          toast(err?.response?.data?.message, {
            type: "error",
            position: toast.POSITION.TOP_CENTER,
            autoClose: 1000,
          });
        }
        toast(err?.response?.data?.message, {
          type: "error",
          position: toast.POSITION.TOP_CENTER,
          autoClose: 1000,
        });
        if (err.response.status === 401) {
          localStorage.clear();
          navigate("/");
        }
      });
  };

  const getEventCount = () => {
    dispatch(setLoadingPanel(true));
    AnalyticsApi.GetEventCountByFilter({
      from: `${filterPayload.from}`,
      to: `${filterPayload.to}`,
      name: filterPayload.name
        ?.replaceAll(filterPayload.category, "")
        .replaceAll("_", " ")
        .trim(),
      category: filterPayload.category,
      sortBy: filterPayload.sortBy,
      direction: filterPayload.direction,
    })
      .then((res) => {
        setEventCount(res.data.data);

        const seriesData = {};
        const xLabel = [];

        res.data.data.forEach((data) => {
          if (!seriesData[data.name]) {
            seriesData[data.name] = [];
          }
          if (!xLabel.includes(data.date)) {
            xLabel.push(data.date);
          }
          seriesData[data.name].push(data.count);
        });

        // Convert series data into the required format
        const series = Object.keys(seriesData).map((name) => ({
          data: seriesData[name],
          label: name,
        }));

        setXLabels(xLabel);
        setSeries(series);
        dispatch(setLoadingPanel(false));
      })
      .catch((err) => {
        dispatch(setLoadingPanel(false));
        if (err?.response?.data) {
          toast(err.response.status + " " + err?.response?.data?.message, {
            type: "error",
            position: toast.POSITION.TOP_CENTER,
            autoClose: 1000,
          });
        } else {
          toast(err?.response?.data?.message, {
            type: "error",
            position: toast.POSITION.TOP_CENTER,
            autoClose: 1000,
          });
        }
        toast(err?.response?.data?.message, {
          type: "error",
          position: toast.POSITION.TOP_CENTER,
          autoClose: 1000,
        });
        if (err.response.status === 401) {
          localStorage.clear();
          navigate("/");
        }
      });
  };

  const getQniqueEvent = () => {
    dispatch(setLoadingPanel(true));
    AnalyticsApi.GetUniqueEventByFilter({
      category: filterPayload.category,
    })
      .then((res) => {
        setUniqueEvents(res.data.data);
        dispatch(setLoadingPanel(false));
      })
      .catch((err) => {
        dispatch(setLoadingPanel(false));
        if (err?.response?.data) {
          toast(err.response.status + " " + err?.response?.data?.message, {
            type: "error",
            position: toast.POSITION.TOP_CENTER,
            autoClose: 1000,
          });
        } else {
          toast(err?.response?.data?.message, {
            type: "error",
            position: toast.POSITION.TOP_CENTER,
            autoClose: 1000,
          });
        }
        toast(err?.response?.data?.message, {
          type: "error",
          position: toast.POSITION.TOP_CENTER,
          autoClose: 1000,
        });
        if (err.response.status === 401) {
          localStorage.clear();
          navigate("/");
        }
      });
  };

  let fileName = `AnalyticsData_${date.getDate()}${
    date.getMonth() + 1
  }${date.getFullYear()}_${date.getHours()}${date.getMinutes()}`;

  const clearSearch = () => {
    setFilterPayload({
      from: periodFrom,
      to: periodTo,
      name: null,
      type: [],
      category: null,
      mobile: null,
      email: null,
      sortBy: null,
      direction: null,
    });
    setUniqueEvents([]);

    setCurrentPage(1);
  };

  const handlePageChange = (e, page) => {
    setCurrentPage(page);
  };
  const [openExcelDialog, setOpenExcelDialog] = React.useState(false);
  useEffect(() => {
    setXLabels([]);
    setSeries([]);
    getEvent(currentPage - 1, 250);
    getEventCount();
  }, [filterPayload, currentPage]);
  const dispatch = useDispatch();
  const isLoading = useSelector((state) => state.loadingReducer.isLoading);

  return (
    <>
      <CircularIndeterminateModal open={isLoading} />
      <div
        className={
          "relative flex flex-col min-w-0 break-words w-full p-4 mb-6 shadow-lg rounded " +
          (color === "light" ? "bg-white" : "bg-lightBlue-900 text-white")
        }
      >
        <div className="block w-full overflow-x-auto p-4 md:p-8">
          <Grid container spacing={2}>
            <Grid item xs={12} md={4}>
              <FormControl fullWidth>
                <InputLabel
                  size="small"
                  id="demo-simple-select-label"
                  sx={{
                    fontSize: {
                      xs: "12px",
                      sm: "13px",
                      md: "13px",
                      lg: "16px",
                    },
                  }}
                >
                  Category
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={filterPayload?.category}
                  onChange={(e) => {
                    setFilterPayload({
                      ...filterPayload,
                      category: e.target.value,
                    });
                  }}
                  sx={{
                    fontSize: {
                      xs: "12px",
                      sm: "13px",
                      md: "13px",
                      lg: "16px",
                    },
                  }}
                >
                  <MenuItem
                    value={null}
                    sx={{
                      fontSize: {
                        xs: "12px",
                        sm: "13px",
                        md: "13px",
                        lg: "16px",
                      },
                    }}
                  >
                    --Select Category--
                  </MenuItem>
                  {category.map((c) => (
                    <MenuItem
                      sx={{
                        fontSize: {
                          xs: "12px",
                          sm: "13px",
                          md: "13px",
                          lg: "16px",
                        },
                      }}
                      value={c}
                    >
                      {c}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12} md={4}>
              <FormControl fullWidth>
                <InputLabel
                  size="small"
                  id="demo-simple-select-label"
                  sx={{
                    fontSize: {
                      xs: "12px",
                      sm: "13px",
                      md: "13px",
                      lg: "16px",
                    },
                  }}
                >
                  Name
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={filterPayload?.name}
                  onChange={(e) => {
                    setFilterPayload({
                      ...filterPayload,
                      name: e.target.value,
                    });
                  }}
                  sx={{
                    fontSize: {
                      xs: "12px",
                      sm: "13px",
                      md: "13px",
                      lg: "16px",
                    },
                  }}
                >
                  <MenuItem
                    value={null}
                    sx={{
                      fontSize: {
                        xs: "12px",
                        sm: "13px",
                        md: "13px",
                        lg: "16px",
                      },
                    }}
                  >
                    --Select Name--
                  </MenuItem>
                  {uniqueEvents.map((c) => (
                    <MenuItem
                      value={c}
                      sx={{
                        fontSize: {
                          xs: "12px",
                          sm: "13px",
                          md: "13px",
                          lg: "16px",
                        },
                      }}
                    >
                      {c
                        ?.trim()
                        .replaceAll(filterPayload.category, "")
                        .replaceAll("_", " ")}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12} md={4}>
              <FormControl sx={{ width: "100%" }} variant="outlined">
                <InputLabel
                  htmlFor="outlined-adornment-password"
                  sx={{
                    fontSize: {
                      xs: "12px",
                      sm: "13px",
                      md: "13px",
                      lg: "16px",
                    },
                  }}
                >
                  Search by Mobile Number
                </InputLabel>
                <OutlinedInput
                  id="outlined-adornment-weight"
                  label={
                    <div className="text-xs sm:text-sm md:text-base lg:text-lg font-medium">
                      Search by Mobile Number
                    </div>
                  }
                  aria-describedby="outlined-weight-helper-text"
                  onChange={(e) => {
                    setFilterPayload({
                      ...filterPayload,
                      mobile: e.target.value,
                    });
                  }}
                  value={filterPayload.mobile ? filterPayload.mobile : ""}
                  sx={{
                    fontSize: {
                      xs: "12px",
                      sm: "13px",
                      md: "13px",
                      lg: "16px",
                    },
                  }}
                />
              </FormControl>
            </Grid>

            <Grid item xs={12} md={4}>
              <FormControl sx={{ width: "100%" }} variant="outlined">
                <InputLabel
                  htmlFor="outlined-adornment-password"
                  sx={{
                    fontSize: {
                      xs: "12px",
                      sm: "13px",
                      md: "13px",
                      lg: "16px",
                    },
                  }}
                >
                  Search by Email
                </InputLabel>
                <OutlinedInput
                  id="outlined-adornment-weight"
                  label={
                    <div className="text-xs sm:text-sm md:text-base lg:text-lg font-medium">
                      Search by Email
                    </div>
                  }
                  sx={{
                    fontSize: {
                      xs: "12px",
                      sm: "13px",
                      md: "13px",
                      lg: "16px",
                    },
                  }}
                  aria-describedby="outlined-weight-helper-text"
                  onChange={(e) => {
                    setFilterPayload({
                      ...filterPayload,
                      email: e.target.value,
                    });
                  }}
                  value={filterPayload.email ? filterPayload.email : ""}
                />
              </FormControl>
            </Grid>

            <Grid item xs={12} md={4}>
              <FormControl fullWidth>
                <InputLabel
                  size="small"
                  id="demo-simple-select-label"
                  sx={{
                    fontSize: {
                      xs: "12px",
                      sm: "13px",
                      md: "13px",
                      lg: "16px",
                    },
                  }}
                >
                  Type
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  multiple
                  value={filterPayload?.type || []}
                  label="Direction"
                  onChange={(e) => {
                    setFilterPayload({
                      ...filterPayload,
                      type: e.target.value,
                    });
                  }}
                  sx={{
                    fontSize: {
                      xs: "12px",
                      sm: "13px",
                      md: "13px",
                      lg: "16px",
                    },
                  }}
                >
                  <MenuItem
                    sx={{
                      fontSize: {
                        xs: "12px",
                        sm: "13px",
                        md: "13px",
                        lg: "16px",
                      },
                    }}
                    value={null}
                  >
                    --Select Type--
                  </MenuItem>
                  <MenuItem
                    sx={{
                      fontSize: {
                        xs: "12px",
                        sm: "13px",
                        md: "13px",
                        lg: "16px",
                      },
                    }}
                    value={"ERROR"}
                  >
                    ERROR
                  </MenuItem>
                  <MenuItem
                    sx={{
                      fontSize: {
                        xs: "12px",
                        sm: "13px",
                        md: "13px",
                        lg: "16px",
                      },
                    }}
                    value={"INFO"}
                  >
                    INFO
                  </MenuItem>
                  <MenuItem
                    sx={{
                      fontSize: {
                        xs: "12px",
                        sm: "13px",
                        md: "13px",
                        lg: "16px",
                      },
                    }}
                    value={"WARN"}
                  >
                    WARN
                  </MenuItem>
                  <MenuItem
                    sx={{
                      fontSize: {
                        xs: "12px",
                        sm: "13px",
                        md: "13px",
                        lg: "16px",
                      },
                    }}
                    value={"DEBUG"}
                  >
                    DEBUG
                  </MenuItem>
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12} md={4}>
              <FormControl fullWidth>
                <InputLabel
                  size="small"
                  id="demo-simple-select-label"
                  sx={{
                    fontSize: {
                      xs: "12px",
                      sm: "13px",
                      md: "13px",
                      lg: "16px",
                    },
                  }}
                >
                  Direction
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={filterPayload?.direction}
                  label="Direction"
                  onChange={(e) =>
                    setFilterPayload({
                      ...filterPayload,
                      direction: e.target.value,
                    })
                  }
                  sx={{
                    fontSize: {
                      xs: "12px",
                      sm: "13px",
                      md: "13px",
                      lg: "16px",
                    },
                  }}
                >
                  <MenuItem
                    sx={{
                      fontSize: {
                        xs: "12px",
                        sm: "13px",
                        md: "13px",
                        lg: "16px",
                      },
                    }}
                    value={null}
                  >
                    --Select Direction--
                  </MenuItem>
                  <MenuItem
                    sx={{
                      fontSize: {
                        xs: "12px",
                        sm: "13px",
                        md: "13px",
                        lg: "16px",
                      },
                    }}
                    value={"ASC"}
                  >
                    Ascending: Low to High
                  </MenuItem>
                  <MenuItem
                    sx={{
                      fontSize: {
                        xs: "12px",
                        sm: "13px",
                        md: "13px",
                        lg: "16px",
                      },
                    }}
                    value={"DESC"}
                  >
                    Descending: High to Low
                  </MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </Grid>

          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={4}>
              <TextField
                type="date"
                onChange={(e) => {
                  setFilterPayload({
                    ...filterPayload,
                    from: e.target?.value,
                  });
                }}
                value={filterPayload?.from}
                fullWidth
                id="outlined-basic"
                helperText="From"
                variant="outlined"
                InputProps={{
                  sx: {
                    fontSize: {
                      xs: "12px",
                      sm: "13px",
                      md: "13px",
                      lg: "16px",
                    },
                  },
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <TextField
                type="date"
                onChange={(e) => {
                  setFilterPayload({
                    ...filterPayload,
                    to: e.target?.value,
                  });
                }}
                value={filterPayload?.to}
                fullWidth
                id="outlined-basic"
                helperText="To"
                variant="outlined"
                InputProps={{
                  sx: {
                    fontSize: {
                      xs: "12px",
                      sm: "13px",
                      md: "13px",
                      lg: "16px",
                    },
                  },
                }}
              />
            </Grid>
          </Grid>

          <Grid container spacing={2}>
            {/* <Grid item xs={12} md={4}>
              <FormControl sx={{ width: "350px" }} variant="outlined">
                <InputLabel htmlFor="outlined-adornment-password">
                  Sort By
                </InputLabel>
                <OutlinedInput
                  id="outlined-adornment-weight"
                  label="SortBy"
                  aria-describedby="outlined-weight-helper-text"
                  onChange={(e) => {
                    setSortBy(e.target.value);
                    setFilterPayload({
                      ...filterPayload,
                      sortBy: e.target.value,
                    });
                  }}
                  value={sortBy}
                />
              </FormControl>
            </Grid> */}
          </Grid>

          <div>
            <Grid
              container
              spacing={2}
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: "10px",
              }}
            >
              <Grid sx={{ width: "50%" }}>
                <Button
                  variant="contained"
                  size="medium"
                  color="secondary"
                  onClick={clearSearch}
                  sx={{
                    fontSize: {
                      xs: "12px",
                      sm: "13px",
                      md: "13px",
                      lg: "16px",
                    },
                  }}
                >
                  Reset All
                </Button>
              </Grid>

              <Grid>
                <div className="text-left  ">
                  <Button
                    variant="contained"
                    color="success"
                    onClick={() => setOpenExcelDialog(true)}
                    sx={{
                      fontSize: {
                        xs: "12px",
                        sm: "13px",
                        md: "13px",
                        lg: "16px",
                      },
                    }}
                  >
                    Export Data
                  </Button>
                  <Dialog
                    open={openExcelDialog}
                    onClose={() => setOpenExcelDialog(false)}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                  >
                    <DialogTitle id="alert-dialog-title">
                      Export Analytics Data
                    </DialogTitle>

                    <DialogActions>
                      <ExcelExport excelData={excelData} fileName={fileName} />
                    </DialogActions>
                  </Dialog>
                </div>
              </Grid>
            </Grid>
          </div>
        </div>
      </div>

      {filterPayload.category && xLabels && !!series.length && (
        <div
          className={
            "relative flex flex-col min-w-0 break-words w-full p-4 mb-6 shadow-lg rounded " +
            (color === "light" ? "bg-white" : "bg-lightBlue-900 text-white")
          }
        >
          <LineChart
            series={series}
            xAxis={[{ scaleType: "point", data: xLabels }]}
            height={350}
            margin={{ top: 10, bottom: 30, left: 40, right: 10 }}
          />
        </div>
      )}

      <div
        className={
          "relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded " +
          (color === "light" ? "bg-white" : "bg-lightBlue-900 text-white")
        }
      >
        <div className="block w-full overflow-x-auto py-3 px-5 md:p-8">
          <div className="mx-2 my-4">
            <Stack
              direction={{ md: "row", xs: "column" }}
              divider={<Divider orientation="vertical" flexItem />}
              spacing={2}
            >
              <Pagination
                count={totalPages}
                page={currentPage}
                color="primary"
                onChange={handlePageChange}
              />
              <div className="flex items-center">
                <span className="text-sm font-medium align-baseline">
                  Total Elements:- {totalElements}
                </span>
              </div>
            </Stack>
          </div>

          <table className="min-w-full divide-y divide-gray-200">
            <thead className="bg-gray-100">
              <tr className="divide-x divide-gray-200 ">
                <TableHeader heading="" />
                <TableHeader heading="Name" />
                <TableHeader heading="Category" />
                <TableHeader heading="Event Time" />
                <TableHeader heading="User" />
                <TableHeader heading="Message" />
                <TableHeader heading="Description" />
                <TableHeader heading="Logs" />
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-200 bg-white">
              {event.map((e, i) => {
                return <EventRow e={e} i={i} />;
              })}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
}

export default Analytics;

const EventRow = ({ e, i }) => {
  const [openUserDetails, setOpenUserDetails] = useState(false);
  const [openLogs, setOpenLogs] = useState(false);
  return (
    <>
      {/* User Details Dialog  */}
      <Dialog
        fullWidth
        maxWidth="md"
        open={openUserDetails}
        onClose={() => setOpenUserDetails(false)}
      >
        <DialogTitle>
          <div className=" relative flex justify-center w-full font-bold">
            <span>User details - {e?.name}</span>
            <span
              className="absolute top-0 right-0 text-red-500 cursor-pointer"
              variant="text"
              onClick={() => setOpenUserDetails(false)}
            >
              close
            </span>
          </div>
        </DialogTitle>
        <DialogContent dividers>
          <table className="items-center w-full bg-transparent border-collapse">
            <thead className="bg-gray-300">
              <tr className="divide-x  divide-gray-200 ">
                <TableHeader heading="Name" />
                <TableHeader heading="Mobile" />
                <TableHeader heading="Email" />
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-200 bg-white">
              <tr
                key={i}
                className="divide-x divide-gray-200 hover:bg-slate-100"
              >
                <TableElement value={e?.user?.name} />
                <TableElement value={e?.user?.mobile} />
                <TableElement value={e?.user?.email} />
              </tr>
            </tbody>
          </table>
        </DialogContent>
      </Dialog>

      {/* Logs dialog  */}
      <Dialog
        fullWidth
        maxWidth="md"
        open={openLogs}
        onClose={() => setOpenLogs(false)}
      >
        <DialogTitle>
          <div className=" relative flex justify-center w-full font-bold">
            <span>Logs</span>
            <span
              className="absolute top-0 right-0 text-red-500 cursor-pointer"
              variant="text"
              onClick={() => setOpenLogs(false)}
            >
              close
            </span>
          </div>
        </DialogTitle>
        <DialogContent dividers>
          <pre>{JSON.stringify(e.logs, null, 2)}</pre>
        </DialogContent>
      </Dialog>
      <tr key={i} className="divide-x divide-gray-200 hover:bg-slate-100">
        <TableElement value={i + 1} />
        <TableElement value={e?.name} />
        <TableElement value={e?.category} />
        <TableElement
          value={formatDateTimeComponent(e?.datetime)}
          type="element"
        />
        <TableElement
          value={
            <div className="flex flex-col">
              <span className="my-1">
                {e?.user?.name ? e?.user?.name : e?.user?.mobile}
              </span>
              <Button variant="text" onClick={() => setOpenUserDetails(true)}>
                More
              </Button>
            </div>
          }
          type="element"
        />
        <TableElement value={e?.message} />
        <TableElement value={e?.description} />

        <TableElement
          value={
            <>
              {e?.logs && (
                <Button
                  variant="text"
                  onClick={() => setOpenLogs(true)}
                  aria-describedby="description-popover"
                >
                  Show
                </Button>
              )}
            </>
          }
          type="element"
        />
      </tr>
    </>
  );
};
