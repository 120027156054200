import { useState, useEffect } from "react";
import axios from "axios";
import Button from "@mui/material/Button";
import { StatesApi } from "apis/StatesApi";
import StatesTable from "components/Tables/StatesTable";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Grid from "@mui/material/Unstable_Grid2";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import { ToastContainer, toast } from "react-toastify";
import { useNavigate } from "react-router";
import TableHeader from "components/Table/TableHeader";
import TableElement from "components/Table/TableElement";
import { useDispatch, useSelector } from "react-redux";
import CircularIndeterminateModal from "../../utils/Loading";
import { setLoadingPanel } from "../../redux/features/loading";

function States() {
  const navigate = useNavigate();
  const color = "light";
  const [states, setStates] = useState([]);
  const dispatch = useDispatch();
  const isLoading = useSelector((state) => state.loadingReducer.isLoading);
  const [open, setOpen] = useState(false);
  const [stateData, setStateData] = useState({
    id: "",
    name: "",
    code: "",
    isActive: true,
  });
  const handleChange = (event) => {
    const { name, value } = event.target;
    setStateData({
      ...stateData,
      [name]: value,
    });
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleSave = () => {
    StatesApi.AddState(stateData)
      .then((res) => {
        toast("State Added", {
          type: "success",
          position: toast.POSITION.TOP_CENTER,
          autoClose: 1000,
        });
        setOpen(false);
      })
      .catch((err) => {
        if (err?.response?.data) {
          toast(err.response.status + " " + err?.response?.data?.message, {
            type: "error",
            position: toast.POSITION.TOP_CENTER,
            autoClose: 1000,
          });
        } else {
          toast(err?.response?.data?.message, {
            type: "error",
            position: toast.POSITION.TOP_CENTER,
            autoClose: 1000,
          });
        }
        if (err.response.status === 401) {
          localStorage.clear();
          navigate("/");
        }
      });
  };

  const getStates = () => {
    dispatch(setLoadingPanel(true));
    StatesApi.GetStatesListByFilter()
      .then((res) => {
        setStates(res.data.data);
        dispatch(setLoadingPanel(false));
      })
      .catch((err) => {
        dispatch(setLoadingPanel(false));
        if (err?.response?.data) {
          toast(err.response.status + " " + err?.response?.data?.message, {
            type: "error",
            position: toast.POSITION.TOP_CENTER,
            autoClose: 1000,
          });
        } else {
          toast(err?.response?.data?.message, {
            type: "error",
            position: toast.POSITION.TOP_CENTER,
            autoClose: 1000,
          });
        }
        if (err.response.status === 401) {
          localStorage.clear();
          navigate("/");
        }
      });
  };

  useEffect(() => {
    axios.defaults.headers.common["x-api-key"] =
      "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiIxOTU4NDk0NDY3NDY0MzE1NjQ5ODc0NTY0ODkyNiIsIm5hbWUiOiJTY2hlbWVzIEJvb29rIiwiaWF0IjoxNTE2MjM5MDIyfQ.uVUOR2U82nTjNccumsyggdScQh0DA5PdlwF_l0VUdfd";
    axios.defaults.headers.common["Authorization"] =
      localStorage.getItem("token");
    getStates();
  }, []);

  return (
    <>
      <CircularIndeterminateModal open={isLoading && !states.length} />
      <div
        className={
          "relative flex flex-col min-w-0 break-words w-full p-4 mb-6 shadow-lg rounded " +
          (color === "light" ? "bg-white" : "bg-lightBlue-900 text-white")
        }
      >
        <span className="text-right">
          <Button
            variant="contained"
            size="medium"
            color="primary"
            sx={{
              height: { xs: "40px", md: "56px" },
              fontSize: {
                xs: "12px",
                sm: "13px",
                md: "14px",
                lg: "16px",
              },
              maxWidth: { xs: "100%", md: "200px" },
              width: "100%",
            }}
            onClick={handleClickOpen}
          >
            + Add State
          </Button>
        </span>
        <Dialog open={open} onClose={handleClose}>
          <DialogTitle>Add State</DialogTitle>
          <DialogContent>
            <Grid container columns={{ xs: 1, md: 12 }}>
              <Grid xs={6} md={6}>
                <TextField
                  id="outlined-basic"
                  sx={{ m: 2 }}
                  label="Name"
                  name="name"
                  variant="outlined"
                  value={stateData.name}
                  onChange={handleChange}
                />
                <TextField
                  id="outlined-basic"
                  type="number"
                  label="Code"
                  name="code"
                  variant="outlined"
                  sx={{ m: 2 }}
                  value={stateData.position}
                  onChange={handleChange}
                />
              </Grid>
              <Grid xs={6} md={6}>
                <FormControl sx={{ m: 2 }}>
                  <FormLabel id="demo-controlled-radio-buttons-group">
                    IsActive
                  </FormLabel>
                  <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group"
                    name="isActive"
                    value={stateData.isActive}
                    onChange={handleChange}
                  >
                    <FormControlLabel
                      value={true}
                      control={<Radio />}
                      label="True"
                    />
                    <FormControlLabel
                      value={false}
                      control={<Radio />}
                      label="False"
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleSave}>Save</Button>
          </DialogActions>
        </Dialog>
      </div>

      <div
        className={
          "relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded " +
          (color === "light" ? "bg-white" : "bg-lightBlue-900 text-white")
        }
      >
        <div className="block w-full overflow-x-auto py-3 px-4 lg:p-8 mt-2">
          <table className="min-w-full divide-y divide-gray-200">
            <thead className="bg-gray-100">
              <tr className="divide-x divide-gray-200 ">
                <TableHeader heading="" />
                <TableHeader heading="Name" />
                <TableHeader heading="Code" />
                <TableHeader heading="Is Active" />
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-200 bg-white">
              {states?.map((s, i) => {
                return (
                  <tr
                    key={i}
                    className="divide-x divide-gray-200 hover:bg-slate-100"
                  >
                    <TableElement value={i + 1} />
                    <TableElement value={s.name} />
                    <TableElement value={s.code} />
                    <TableElement value={s.isActive} type="boolean" />
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
      <ToastContainer />
    </>
  );
}

export default States;
