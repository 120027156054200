/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Grid from "@mui/material/Unstable_Grid2";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import ExcelExport from "components/ExcelExport/ExcelExport";
import TableHeader from "components/Table/TableHeader";
import TableElement from "components/Table/TableElement";
import Slide from "@mui/material/Slide";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import AppBar from "@mui/material/AppBar";
import { formatDate } from "utils";
import { getAllSubscriptions } from "apis/MydukanSubs";
import { getAllSubscriptionsByFilters } from "apis/MydukanSubs";
import { createSubscriptionApi } from "apis/MydukanSubs";
import { deleteSubscriptions } from "apis/MydukanSubs";
import { createSvgIcon, useMediaQuery } from "@mui/material";
import CircularIndeterminateModal from "../../utils/Loading";
import { useDispatch, useSelector } from "react-redux";
import { setLoadingPanel } from "../../redux/features/loading";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function MydukanSubscriptions() {
  const color = "light";
  const [usersSubscriptions, setUsersSubscriptions] = useState([]);
  const dispatch = useDispatch();
  const isLoading = useSelector((state) => state.loadingReducer.isLoading);
  const date = new Date();
  let fileName = `UserSubscriptionData_${date.getDate()}${
    date.getMonth() + 1
  }${date.getFullYear()}_${date.getHours()}${date.getMinutes()}`;
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState();
  const [mobileNo, setMobileNo] = useState("");
  const [totalElements, setTotalElements] = useState("");
  const [subscription, setSubscription] = useState([]);
  const [filterPayload, setFilterPayload] = useState({
    isActive: null,
    startDate: null,
    endDate: null,
    mobile: null,
  });
  const [userSize, setUserSize] = useState(25);

  const handleGetSubscription = async () => {
    dispatch(setLoadingPanel(true));
    try {
      const { data } = await getAllSubscriptions();
      setSubscription(data.data);
      dispatch(setLoadingPanel(false));
    } catch (error) {
      dispatch(setLoadingPanel(false));
      toast(error?.response?.data?.message, {
        type: "error",
        position: toast.POSITION.TOP_CENTER,
        autoClose: 1000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        style: {
          maxWidth: "90vw",
          width: "auto",
          margin: "5px  auto",
          padding: "5px 5px",
          fontSize: "14px",
        },
        closeButton: (
          <div className="flex justify-end">
            <button className="text-gray-500 hover:text-gray-800 focus:outline">
              <CloseIcon className="w-7 h-7" />
            </button>
          </div>
        ),
      });
    }
  };

  const handleGetUserSubscriptions = async (page, size) => {
    dispatch(setLoadingPanel(true));
    try {
      const { data } = await getAllSubscriptionsByFilters({
        ...filterPayload,
        page,
        size,
      });
      setTotalElements(data.totalElements);
      setTotalPages(data.pages);
      setUsersSubscriptions(data.data);
      setExcelData(data.data);
      dispatch(setLoadingPanel(false));
    } catch (error) {
      dispatch(setLoadingPanel(false));
      toast(error?.response?.data?.message, {
        type: "error",
        position: toast.POSITION.TOP_CENTER,
        autoClose: 1000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        style: {
          maxWidth: "90vw",
          width: "auto",
          margin: "5px  auto",
          padding: "5px 5px",
          fontSize: "14px",
        },
        closeButton: (
          <div className="flex justify-end">
            <button className="text-gray-500 hover:text-gray-800 focus:outline">
              <CloseIcon className="w-7 h-7" />
            </button>
          </div>
        ),
      });
    }
  };

  const handlePageChange = (event, page) => {
    setCurrentPage(page);
  };

  useEffect(() => {
    (async () => {
      await handleGetSubscription();
    })();
  }, []);

  useEffect(() => {
    (async () => {
      await handleGetUserSubscriptions(currentPage - 1, userSize);
    })();
  }, [currentPage, filterPayload, userSize]);

  const clearSearch = () => {
    setFilterPayload({
      isActive: null,
      startDate: null,
      endDate: null,
      sortBy: null,
      price: null,
      direction: null,
      mobile: null,
    });
    setCurrentPage(1);
  };

  const [openExcelDialog, setOpenExcelDialog] = React.useState(false);
  const handleClickOpenExcelDialog = () => {
    setOpenExcelDialog(true);
  };
  const handleCloseExcelDialog = () => {
    setOpenExcelDialog(false);
  };
  const [excelData, setExcelData] = useState([]);
  const isMobile = useMediaQuery("(max-width:768px)");

  return (
    <>
      <CircularIndeterminateModal open={isLoading && !subscription.length} />
      <div
        className={
          "relative flex flex-col min-w-0 break-words w-full p-4 mb-6 shadow-lg rounded " +
          (color === "light" ? "bg-white" : "bg-lightBlue-900 text-white")
        }
      >
        <div className="block w-full overflow-x-auto p-4 md:p-8">
          <Grid spacing={2}>
            <Grid xs={10}>
              <Grid spacing={2} sx={{ marginTop: "5px" }}>
                <Grid item xs={12} sm={12} md={8} lg={8}>
                  <TextField
                    fullWidth
                    label={
                      <span className="text-xs sm:text-sm md:text-base lg:text-lg font-medium">
                        Search by Mobile number
                      </span>
                    }
                    id="fullWidth"
                    value={mobileNo}
                    onChange={(e) => {
                      setMobileNo(e.target.value);
                      if (e.target.value?.length === 10) {
                        setFilterPayload({
                          ...filterPayload,
                          mobile: e.target.value,
                        });
                      } else if (e.target.value?.length === 0) {
                        setFilterPayload({
                          ...filterPayload,
                          mobile: null,
                        });
                      }
                    }}
                    InputProps={{
                      sx: {
                        fontSize: {
                          xs: "12px",
                          sm: "13px",
                          md: "13px",
                          lg: "16px",
                        },
                      },
                    }}
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={4}
                  lg={4}
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    marginY: "8px",
                  }}
                >
                  <AddUserSubscription subscription={subscription} />
                </Grid>
              </Grid>
              <Grid container spacing={2} style={{ marginTop: "10px" }}>
                <Grid sx={{ width: { lg: "25%", xs: "100%" } }}>
                  <TextField
                    type="date"
                    onChange={(e) => {
                      setFilterPayload({
                        ...filterPayload,
                        startDate: e.target?.value,
                      });
                    }}
                    value={filterPayload?.startDate}
                    fullWidth
                    id="outlined-basic"
                    helperText="Start Date"
                    InputProps={{
                      sx: {
                        fontSize: {
                          xs: "12px",
                          sm: "13px",
                          md: "13px",
                          lg: "16px",
                        },
                      },
                    }}
                    variant="outlined"
                  />
                </Grid>
                <Grid sx={{ width: { lg: "25%", xs: "100%" } }}>
                  <TextField
                    type="date"
                    onChange={(e) => {
                      setFilterPayload({
                        ...filterPayload,
                        endDate: e.target?.value,
                      });
                    }}
                    value={filterPayload?.endDate}
                    fullWidth
                    id="outlined-basic"
                    helperText="End Date"
                    variant="outlined"
                    InputProps={{
                      sx: {
                        fontSize: {
                          xs: "12px",
                          sm: "13px",
                          md: "13px",
                          lg: "16px",
                        },
                      },
                    }}
                  />
                </Grid>
                <Grid>
                  <FormControl>
                    <FormLabel
                      id="demo-controlled-radio-buttons-group"
                      sx={{
                        fontSize: {
                          xs: "12px",
                          sm: "13px",
                          md: "13px",
                          lg: "16px",
                        },
                      }}
                    >
                      Is Active
                    </FormLabel>
                    <RadioGroup
                      row
                      aria-labelledby="demo-row-radio-buttons-group"
                      name="Is Active"
                      value={filterPayload?.isActive}
                      onChange={(e) => {
                        setFilterPayload({
                          ...filterPayload,
                          isActive: e.target?.value,
                        });
                      }}
                      sx={{
                        fontSize: {
                          xs: "12px",
                          sm: "13px",
                          md: "13px",
                          lg: "16px",
                        },
                      }}
                    >
                      <FormControlLabel
                        value={true}
                        control={
                          <Radio
                            sx={{
                              padding: "8px",
                              "& .MuiSvgIcon-root": {
                                fontSize: {
                                  xs: "18px",
                                  md: "22px",
                                },
                              },
                            }}
                          />
                        }
                        label={
                          <span className="text-xs sm:text-sm md:text-base lg:text-lg font-medium">
                            True
                          </span>
                        }
                      />
                      <FormControlLabel
                        value={false}
                        control={
                          <Radio
                            sx={{
                              padding: "8px",
                              "& .MuiSvgIcon-root": {
                                fontSize: {
                                  xs: "18px",
                                  md: "22px",
                                },
                              },
                            }}
                          />
                        }
                        label={
                          <span className="text-xs sm:text-sm md:text-base lg:text-lg font-medium">
                            False
                          </span>
                        }
                      />
                    </RadioGroup>
                  </FormControl>
                </Grid>
              </Grid>
              <div>
                <Grid
                  container
                  spacing={2}
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    marginTop: "10px",
                  }}
                >
                  <Grid sx={{ width: { xs: "50%", md: "100%" } }}>
                    <Button
                      variant="contained"
                      size="medium"
                      color="secondary"
                      onClick={clearSearch}
                      sx={{
                        fontSize: {
                          xs: "12px",
                          sm: "13px",
                          md: "13px",
                          lg: "16px",
                        },
                      }}
                    >
                      Reset All
                    </Button>
                  </Grid>
                </Grid>
              </div>
            </Grid>
          </Grid>
        </div>
      </div>
      <div
        className={
          "relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded " +
          (color === "light" ? "bg-white" : "bg-lightBlue-900 text-white")
        }
      >
        <div className="block w-full overflow-x-auto py-3 px-4 lg:p-8">
          <table className="min-w-full divide-y divide-gray-200">
            <thead className="bg-gray-100">
              <tr className="divide-x divide-gray-200 ">
                <TableHeader heading="" />
                <TableHeader heading="Name" />
                <TableHeader heading="Validity" />
                <TableHeader heading="Price" />
                <TableHeader heading="Disc Price" />
                <TableHeader heading="Message" />
                <TableHeader heading="Active" />
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-200 bg-white">
              {subscription?.map((s, i) => {
                return (
                  <tr
                    key={i}
                    className="divide-x divide-gray-200 hover:bg-slate-100"
                  >
                    <TableElement value={i + 1} />
                    <TableElement value={<>{s.subscriptionName}</>} />
                    <TableElement value={s.subscriptionTime} />
                    <TableElement value={s.subscriptionPrice} />
                    <TableElement value={s.subscriptionDiscount} />
                    <TableElement value={s.subscriptionDesc} />
                    <TableElement value={s.active} type="boolean" />
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
      <div
        className={
          "relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded " +
          (color === "light" ? "bg-white" : "bg-lightBlue-900 text-white")
        }
      >
        <div className="block w-full overflow-x-auto py-3 px-4 lg:p-8">
          <div className="text-center p-2 mb-2">
            <Stack
              spacing={2}
              direction={{ md: "row", xs: "column" }}
              justifyContent={{ md: "space-between", xs: "center" }}
              className="w-full"
            >
              <div className="flex justify-center md:justify-end py-2 md:py-4 px-2 text-sky-500">
                Total: {totalElements}
              </div>
              <div className="mb-0 my-auto justify-center flex items-center">
                <Pagination
                  count={totalPages}
                  page={currentPage}
                  color="primary"
                  onChange={handlePageChange}
                  size={isMobile ? "small" : "medium"}
                  // className="mb-4"
                />
              </div>
            </Stack>
            <div className="md:text-left text-center ml-3 mt-2">
              <Button
                variant="contained"
                color="success"
                sx={{
                  height: { xs: "40px", md: "56px" },
                  fontSize: {
                    xs: "12px",
                    sm: "13px",
                    md: "14px",
                    lg: "16px",
                  },
                  // maxWidth: { xs: "100%", md: "200px" },
                  // width: "100%",
                }}
                onClick={handleClickOpenExcelDialog}
              >
                Export Data
              </Button>
              <Dialog
                open={openExcelDialog}
                onClose={handleCloseExcelDialog}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
              >
                <DialogTitle id="alert-dialog-title">
                  Export Subscription Data
                </DialogTitle>
                <DialogContent>
                  You are Downloading subscription data of{" "}
                  <TextField
                    id="filled-basic"
                    onChange={(e) => {
                      setUserSize(e.target.value);
                    }}
                    variant="standard"
                    sx={{ width: "60px" }}
                    value={userSize}
                  />{" "}
                  users
                </DialogContent>
                <DialogActions>
                  <ExcelExport excelData={excelData} fileName={fileName} />
                </DialogActions>
              </Dialog>
            </div>
          </div>
          <table className="min-w-full divide-y divide-gray-200">
            <thead className="bg-gray-100">
              <tr className="divide-x divide-gray-200 ">
                <TableHeader heading="" />
                <TableHeader heading="User" />
                <TableHeader heading="Active" />
                <TableHeader heading="Price" />
                <TableHeader heading="Sub NAME" />
                <TableHeader heading="Mobile" />
                <TableHeader heading="Start Date" />
                <TableHeader heading="End Date" />
                <TableHeader heading="" />
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-200 bg-white">
              {usersSubscriptions?.map((us, i) => {
                return (
                  <tr
                    key={i}
                    className="divide-x divide-gray-200 hover:bg-slate-100"
                  >
                    <TableElement
                      value={userSize * (currentPage - 1) + i + 1}
                    />
                    <TableElement value={us.userName} />
                    <TableElement value={us.isActive} type="boolean" />
                    <TableElement value={us.subscriptionPrice} />
                    <TableElement value={us.subscriptionName} />
                    <TableElement value={us.userPhoneNumber} />
                    <TableElement value={formatDate(us.startDate)} />
                    <TableElement value={formatDate(us.endDate)} />
                    <TableElement
                      value={
                        <span
                          className="cursor-pointer text-red-500 hover:text-sky-950 font-bold gr "
                          onClick={async () => {
                            try {
                              await deleteSubscriptions(us.userSubscriptionId);
                              toast("Subscription Deleted.", {
                                type: "success",
                                position: toast.POSITION.TOP_CENTER,
                                autoClose: 1000,
                              });
                            } catch (error) {
                              toast(error?.response?.data?.message, {
                                type: "error",
                                position: toast.POSITION.TOP_CENTER,
                                autoClose: 1000,
                              });
                            }
                          }}
                        >
                          DELETE
                        </span>
                      }
                    />
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
      <ToastContainer />
    </>
  );
}

const AddUserSubscription = ({ subscription }) => {
  const date = new Date();
  const currentDate = `${date.getFullYear()}-${String(
    date.getMonth() + 1
  ).padStart(2, "0")}-${String(date.getDate()).padStart(2, "0")}`;

  const PlusIcon = createSvgIcon(
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth={1.5}
      stroke="currentColor"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M12 4.5v15m7.5-7.5h-15"
      />
    </svg>,
    "Plus"
  );
  const [open, setOpen] = useState(false);

  const [payload, setPayload] = useState({
    mibile: null,
    subscriptionId: null,
    startDate: currentDate,
  });

  return (
    <>
      <div className="flex justify-center">
        <div className="w-full">
          <Button
            onClick={() => {
              setPayload({
                mobile: null,
                subscriptionId: null,
                startDate: currentDate,
              });
              setOpen(true);
            }}
            sx={{
              height: { xs: "40px", md: "56px" },
              fontSize: {
                xs: "12px",
                sm: "13px",
                md: "14px",
                lg: "16px",
              },
              width: { xs: "100%", md: "fit-content" },
            }}
            variant="contained"
            color="primary"
            startIcon={<PlusIcon className="h-4 w-4 md:h-5 md:w-5" />}
          >
            Add Subscription
          </Button>
        </div>
      </div>

      {/* Add Subscription */}
      <div>
        <Dialog
          maxWidth="md"
          fullWidth
          open={open}
          onClose={() => setOpen(false)}
          TransitionComponent={Transition}
        >
          <AppBar sx={{ position: "relative" }}>
            <Toolbar>
              <IconButton
                edge="start"
                color="inherit"
                onClick={() => setOpen(false)}
                aria-label="close"
              >
                <CloseIcon />
              </IconButton>
              <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                Add Subscription
              </Typography>
            </Toolbar>
          </AppBar>
          <DialogContent>
            <>
              <div className="p-3 lg:p-8">
                <table className="min-w-full divide-y divide-gray-200">
                  <thead className="bg-gray-100">
                    <tr className="divide-x divide-gray-200 ">
                      <TableHeader heading="" />
                      <TableHeader heading="Mobile" />
                      <TableHeader heading="Start Date" />
                      <TableHeader heading="Select Subscription" />
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-gray-200 bg-white">
                    <tr
                      style={{ padding: "5px" }}
                      className="divide-x divide-gray-200 hover:bg-slate-100"
                    >
                      <TableElement value={1} />
                      <TableElement
                        value={
                          <TextField
                            inputProps={{ maxLength: 10 }}
                            onChange={(e) => {
                              setPayload({
                                ...payload,
                                mobile: e.target?.value,
                              });
                            }}
                            value={payload?.mobile}
                            fullWidth
                            id="outlined-basic"
                            variant="outlined"
                            InputProps={{
                              sx: {
                                width: "100%",
                              },
                            }}
                          />
                        }
                        type="element"
                      />
                      <TableElement
                        value={
                          <>
                            <TextField
                              type="date"
                              onChange={(e) =>
                                setPayload({
                                  ...payload,
                                  startDate: e?.target.value,
                                })
                              }
                              value={payload.startDate}
                              fullWidth
                              id="outlined-basic"
                              label="Start Date"
                              variant="outlined"
                            />
                          </>
                        }
                      />
                      <TableElement
                        value={
                          <>
                            <div className="p-2">
                              <Select
                                fullWidth
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                label="Select Subscription"
                                value={payload.subscriptionId}
                                onChange={(e) => {
                                  setPayload({
                                    ...payload,
                                    subscriptionId: e?.target?.value,
                                  });
                                }}
                              >
                                {subscription?.map((m, i) => (
                                  <MenuItem key={i} value={m?.subscriptionId}>
                                    {m?.subscriptionName}
                                  </MenuItem>
                                ))}
                              </Select>
                            </div>
                          </>
                        }
                      />
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="text-right">
                <Button
                  variant="contained"
                  size="medium"
                  color="primary"
                  sx={{ m: 4 }}
                  onClick={async () => {
                    try {
                      await createSubscriptionApi(payload);
                      setOpen(false);
                      toast("Subscription Added.", {
                        type: "success",
                        position: toast.POSITION.TOP_CENTER,
                        autoClose: 1000,
                      });
                    } catch (error) {
                      toast(error?.response?.data?.message, {
                        type: "error",
                        position: toast.POSITION.TOP_CENTER,
                        autoClose: 1000,
                      });
                    }
                  }}
                >
                  Add Subscription
                </Button>
              </div>
            </>
          </DialogContent>
          <DialogActions></DialogActions>
        </Dialog>
      </div>
    </>
  );
};

export default MydukanSubscriptions;
