import React from "react";
const TableElement = ({ type = "text", value, style }) => {
  return (
    <td
      style={style}
      className="break-words w-fit whitespace-nowrap p-2 text-xs xl:text-sm text-center text-gray-500"
    >
      {" "}
      {
        {
          text: <span className=" w-full text-clip">{value} </span>,
          boolean: value ? (
            <i className="fas fa-circle text-emerald-500 mr-2"></i>
          ) : (
            <i className="fas fa-circle text-red-500 mr-2"></i>
          ),
          element: value,
        }[type]
      }{" "}
    </td>
  );
};
export default TableElement;
