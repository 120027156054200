import React, { useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import { PostsApi } from "apis/PostsApi";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import TextField from "@mui/material/TextField";
import ReactPlayer from "react-player";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import Slide from "@mui/material/Slide";
import DialogActions from "@mui/material/DialogActions";
import MatAutoComplete from "components/MatAutoComplete/MatAutoComplete";
import { useNavigate } from "react-router";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormLabel from "@mui/material/FormLabel";
import FormControlLabel from "@mui/material/FormControlLabel";
import { BrandsApi } from "apis/BrandsApi";
import { GroupsApi } from "apis/GroupsApi";
import Grid from "@mui/material/Unstable_Grid2";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import {
  createSvgIcon,
  DialogContent,
  DialogTitle,
  Tooltip,
  useMediaQuery,
} from "@mui/material";
import TableHeader from "components/Table/TableHeader";
import TableElement from "components/Table/TableElement";
import { formatDate } from "utils";
import { formatDateTimeComponent } from "utils";
import { MuiColorInput } from "mui-color-input";
import { useImageSize } from "react-image-size";
import { useDispatch, useSelector } from "react-redux";
import CircularIndeterminateModal from "../../utils/Loading";
import { setLoadingPanel } from "../../redux/features/loading";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const LastDayOfMonth = (y, m) => {
  return new Date(y, m, 0).getDate();
};

function Share() {
  const navigate = useNavigate();
  const color = "light";
  const [posts, setPosts] = useState();
  const [open, setOpen] = React.useState(false);
  const date = new Date();

  const month = date.getMonth() + 1;
  const year = date.getFullYear();
  const day = date.getDate();

  const periodFrom = `${year}-${month > 9 ? month : "0" + month}-${
    day > 9 ? day : "0" + day
  }`;
  date.setDate(date.getDate() + 5);
  const periodTo = `${year}-${month > 9 ? month : "0" + month}-${
    date.getDate() > 9 ? date.getDate() : "0" + date.getDate()
  }`;

  const dateForAdd = new Date(year, month - 1, day);
  const periodFromForAdd = `${dateForAdd.getFullYear()}-${(
    dateForAdd.getMonth() + 1
  )
    .toString()
    .padStart(2, "0")}-${dateForAdd.getDate().toString().padStart(2, "0")}`;

  dateForAdd.setDate(dateForAdd.getDate() + 7);
  const periodToForAdd = `${dateForAdd.getFullYear()}-${(
    dateForAdd.getMonth() + 1
  )
    .toString()
    .padStart(2, "0")}-${dateForAdd.getDate().toString().padStart(2, "0")}`;

  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState();

  const [brands, setBrands] = useState([]);
  const [selectedBrandInAddBrand, setSelectedBrandInAddBrand] = useState({});

  const [postAddPayload, setPostAddPayload] = useState({
    id: "",
    brand: null,
    url: "",
    periodFrom: periodFrom,
    periodTo: periodTo,
    name: {
      size: 42,
      padding: 20,
      font: "PaytoneOne-Regular.ttf",
      positionX: 0,
      positionY: 0,
      bgColor: "FFFFFF",
      textColor: "000000",
    },
    mobile: {
      size: 42,
      padding: 20,
      font: "PaytoneOne-Regular.ttf",
      positionX: 0,
      positionY: 500,
      bgColor: "FFFFFF",
      textColor: "000000",
    },
    type: "IMAGE",
    groups: [],
    isActive: true,
    isDeleted: false,
  });

  const [convertedURL, setConvertedURL] = useState("");

  const [brandFilter, setBrandFilter] = useState({});

  const handlePageChange = (event, page) => {
    setCurrentPage(page);

    getPosts(page - 1);
    return currentPage;
  };
  const [groupOptions, setGroupOptions] = useState([]);
  const getGroupData = (searchTerm) => {
    GroupsApi.GetGroupsListBySearch({
      page: 0,
      size: 10,
      keyword: searchTerm,
    }).then((res) => {
      const filter = res.data.data.filter((ele) => ele.isActive);
      const updatedOptions = filter.map((p) => {
        return {
          name: p.name,
          id: p.id,
        };
      });
      setGroupOptions(updatedOptions);
    });
  };
  const getSelectedGroups = (data) => {
    setPostAddPayload({
      ...postAddPayload,
      groups: data,
    });
  };

  const [totalElements, setTotalElements] = useState("");

  const getPosts = (page) => {
    dispatch(setLoadingPanel(true));
    PostsApi.GetSharePostsListByFilter({
      page: page,
      name: "Your Shop Name",
      mobile: "Your Number",
      size: 50,
      periodFrom: periodFrom,
      periodTo: periodTo,
      brandId: brandFilter?.id,
    })
      .then((res) => {
        setPosts(res.data.data);
        setTotalPages(res.data.pages);
        setTotalElements(res.data.totalElements);
        dispatch(setLoadingPanel(false));
      })
      .catch((err) => {
        dispatch(setLoadingPanel(false));
        if (err?.response?.data) {
          toast(err.response.status + " " + err?.response?.data?.message, {
            type: "error",
            position: toast.POSITION.TOP_CENTER,
            autoClose: 1000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            style: {
              maxWidth: "90vw",
              width: "auto",
              margin: "5px  auto",
              padding: "5px 5px",
              fontSize: "14px",
            },
            closeButton: (
              <div className="flex justify-end">
                <button className="text-gray-500 hover:text-gray-800 focus:outline">
                  <CloseIcon className="w-7 h-7" />
                </button>
              </div>
            ),
          });
        } else {
          toast(err?.response?.data?.message, {
            type: "error",
            position: toast.POSITION.TOP_CENTER,
            autoClose: 1000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            style: {
              maxWidth: "90vw",
              width: "auto",
              margin: "5px  auto",
              padding: "5px 5px",
              fontSize: "14px",
            },
            closeButton: (
              <div className="flex justify-end">
                <button className="text-gray-500 hover:text-gray-800 focus:outline">
                  <CloseIcon className="w-7 h-7" />
                </button>
              </div>
            ),
          });
        }
        if (err.response.status === 401) {
          localStorage.clear();
          navigate("/");
        }
      });
  };
  const handleResetFilter = () => {
    setBrandFilter("");
    getPosts(0);
    setCurrentPage(1);
  };
  const onBrandSelectInAddBrand = (event) => {
    const brand = event.target.value;
    if (event.target.value === null) {
      setPostAddPayload({
        ...postAddPayload,
        brand: null,
      });
      setSelectedBrandInAddBrand(null);
    } else {
      setSelectedBrandInAddBrand(brand);
      setPostAddPayload({
        ...postAddPayload,
        brand: {
          id: brand.id,
          name: brand.name,
          image: brand.image,
        },
      });
    }
  };

  const save = () => {
    if (!postAddPayload?.brand?.id) {
      toast("Add Brand", {
        type: "error",
        position: toast.POSITION.TOP_CENTER,
        autoClose: 1000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        style: {
          maxWidth: "90vw",
          width: "auto",
          margin: "5px  auto",
          padding: "5px 5px",
          fontSize: "14px",
        },
        closeButton: (
          <div className="flex justify-end">
            <button className="text-gray-500 hover:text-gray-800 focus:outline">
              <CloseIcon className="w-7 h-7" />
            </button>
          </div>
        ),
      });
      return;
    }
    if (!postAddPayload.groups.length) {
      toast("Add Group", {
        type: "error",
        position: toast.POSITION.TOP_CENTER,
        autoClose: 1000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        style: {
          maxWidth: "90vw",
          width: "auto",
          margin: "5px  auto",
          padding: "5px 5px",
          fontSize: "14px",
        },
        closeButton: (
          <div className="flex justify-end">
            <button className="text-gray-500 hover:text-gray-800 focus:outline">
              <CloseIcon className="w-7 h-7" />
            </button>
          </div>
        ),
      });
      return;
    }
    PostsApi.AddSharePost(postAddPayload)
      .then((res) => {
        toast("Post Share Added", {
          type: "success",
          position: toast.POSITION.TOP_CENTER,
          autoClose: 1000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          style: {
            maxWidth: "90vw",
            width: "auto",
            margin: "5px  auto",
            padding: "5px 5px",
            fontSize: "14px",
          },
          closeButton: (
            <div className="flex justify-end">
              <button className="text-gray-500 hover:text-gray-800 focus:outline">
                <CloseIcon className="w-7 h-7" />
              </button>
            </div>
          ),
        });
        setOpen(false);
        getPosts(0);
      })
      .catch((err) => {
        if (err?.response?.data) {
          toast(err.response.status + " " + err?.response?.data?.message, {
            type: "error",
            position: toast.POSITION.TOP_CENTER,
            autoClose: 1000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            style: {
              maxWidth: "90vw",
              width: "auto",
              margin: "5px  auto",
              padding: "5px 5px",
              fontSize: "14px",
            },
            closeButton: (
              <div className="flex justify-end">
                <button className="text-gray-500 hover:text-gray-800 focus:outline">
                  <CloseIcon className="w-7 h-7" />
                </button>
              </div>
            ),
          });
        } else {
          toast(err?.response?.data?.message, {
            type: "error",
            position: toast.POSITION.TOP_CENTER,
            autoClose: 1000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            style: {
              maxWidth: "90vw",
              width: "auto",
              margin: "5px  auto",
              padding: "5px 5px",
              fontSize: "14px",
            },
            closeButton: (
              <div className="flex justify-end">
                <button className="text-gray-500 hover:text-gray-800 focus:outline">
                  <CloseIcon className="w-7 h-7" />
                </button>
              </div>
            ),
          });
        }
        if (err.response.status === 401) {
          localStorage.clear();
          navigate("/");
        }
      });
  };

  const edit = (payload) => {
    PostsApi.UpdateSharePosts(payload).then((res) => {
      toast("Post Updated", {
        type: "success",
        position: toast.POSITION.TOP_CENTER,
        autoClose: 1000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        style: {
          maxWidth: "90vw",
          width: "auto",
          margin: "5px  auto",
          padding: "5px 5px",
          fontSize: "14px",
        },
        closeButton: (
          <div className="flex justify-end">
            <button className="text-gray-500 hover:text-gray-800 focus:outline">
              <CloseIcon className="w-7 h-7" />
            </button>
          </div>
        ),
      });
      getPosts(currentPage - 1);
      setOpen(false);
    });
  };
  const handleClose = () => {
    setOpen(false);
  };

  const openAddUpdateBrands = () => {
    setPostAddPayload({
      id: "",
      brand: null,
      url: "",
      periodFrom: periodFromForAdd,
      periodTo: periodToForAdd,
      name: {
        size: 42,
        padding: 20,
        font: "PaytoneOne-Regular.ttf",
        positionX: 0,
        positionY: 0,
        bgColor: "FFFFFF",
        textColor: "000000",
      },
      mobile: {
        size: 42,
        padding: 20,
        font: "PaytoneOne-Regular.ttf",
        positionX: 0,
        positionY: 500,
        bgColor: "FFFFFF",
        textColor: "000000",
      },
      type: "IMAGE",
      groups: [],
      isActive: true,
      isDeleted: false,
    });
    setConvertedURL("");
    setOpen(true);
  };
  const getBrands = () => {
    dispatch(setLoadingPanel(true));
    BrandsApi.GetBrandsListByFilter({
      page: 0,
      size: 99,
    })
      .then((res) => {
        setBrands(res.data.data.filter((d) => d.isActive));
        dispatch(setLoadingPanel(false));
      })
      .catch((err) => {
        dispatch(setLoadingPanel(false));
        if (err?.response?.data) {
          toast(err.response.status + " " + err?.response?.data?.message, {
            type: "error",
            position: toast.POSITION.TOP_CENTER,
            autoClose: 1000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            style: {
              maxWidth: "90vw",
              width: "auto",
              margin: "5px  auto",
              padding: "5px 5px",
              fontSize: "14px",
            },
            closeButton: (
              <div className="flex justify-end">
                <button className="text-gray-500 hover:text-gray-800 focus:outline">
                  <CloseIcon className="w-7 h-7" />
                </button>
              </div>
            ),
          });
        } else {
          toast(err?.response?.data?.message, {
            type: "error",
            position: toast.POSITION.TOP_CENTER,
            autoClose: 1000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            style: {
              maxWidth: "90vw",
              width: "auto",
              margin: "5px  auto",
              padding: "5px 5px",
              fontSize: "14px",
            },
            closeButton: (
              <div className="flex justify-end">
                <button className="text-gray-500 hover:text-gray-800 focus:outline">
                  <CloseIcon className="w-7 h-7" />
                </button>
              </div>
            ),
          });
        }
        if (err.response.status === 401) {
          localStorage.clear();
          navigate("/");
        }
      });
  };

  useEffect(() => {
    getPosts(currentPage - 1);
  }, [currentPage, brandFilter]);

  useEffect(() => {
    getBrands();
  }, []);
  const [dimensions] = useImageSize(
    postAddPayload.url.includes("?")
      ? postAddPayload.url.split("?")[0]
      : postAddPayload.url
  );

  // useEffect(() => {
  //   getDimensions("https://ik.imagekit.io/9liehnrc3/vivo_7th9LzhWs.mp4").then(
  //     function (dimensions) {
  //       console.log(dimensions.width);
  //       console.log(dimensions.height);
  //     }
  //   );
  // }, []);

  useEffect(() => {
    if (dimensions) {
      setPostAddPayload((prev) => ({
        ...prev,
        name: {
          ...prev.name,
          positionX: prev.name?.positionX
            ? prev.name.positionX
            : dimensions.width,
          positionY: prev.name?.positionY
            ? prev.name.positionY
            : dimensions.height,
        },
        mobile: {
          ...prev.mobile,
          positionX: prev.mobile?.positionX
            ? prev.mobile.positionX
            : dimensions.width,
          positionY: prev.mobile?.positionY
            ? prev.mobile.positionY
            : dimensions.height,
        },
      }));
    }
  }, [dimensions]);

  useEffect(() => {
    if (postAddPayload.url) {
      let urlArray = [];
      if (postAddPayload.url.includes("?")) {
        urlArray = postAddPayload.url?.split("?");
      } else {
        urlArray[0] = postAddPayload.url;
      }
      const converted = `${urlArray[0]}?tr=l-text,pa-${postAddPayload.name.padding},ff-${postAddPayload.name.font},i-Your Shop Name,ly-${postAddPayload.name.positionY},lx-${postAddPayload.name.positionX},bg-${postAddPayload.name.bgColor},co-${postAddPayload.name.textColor},tg-b,fs-${postAddPayload.name.size},l-end:l-text,i-Your Number,ly-${postAddPayload.mobile.positionY},co-${postAddPayload.mobile.textColor},lx-${postAddPayload.mobile.positionX},pa-${postAddPayload.mobile.padding},ff-${postAddPayload.mobile.font},bg-${postAddPayload.mobile.bgColor},fs-${postAddPayload.mobile.size},tg-b,l-end`;
      setConvertedURL(converted);
    }
  }, [postAddPayload.url, postAddPayload.name, postAddPayload.mobile]);

  const isMobile = useMediaQuery("(max-width:768px)");
  const PlusIcon = createSvgIcon(
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth={1.5}
      stroke="currentColor"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M12 4.5v15m7.5-7.5h-15"
      />
    </svg>,
    "Plus"
  );

  const dispatch = useDispatch();
  const isLoading = useSelector((state) => state.loadingReducer.isLoading);

  return (
    <>
      <CircularIndeterminateModal open={isLoading && !brands.length} />
      <div
        className={
          "relative flex flex-col min-w-0 break-words w-full p-4 mb-6 shadow-lg rounded " +
          (color === "light" ? "bg-white" : "bg-lightBlue-900 text-white")
        }
      >
        <div className="block w-full overflow-x-auto p-4 md:p-8">
          <Grid
            container
            spacing={2}
            direction={{ md: "row", xs: "column" }}
            justifyContent={{ md: "space-between", xs: "center" }}
          >
            <Grid
              item
              xs={12}
              md={10}
              sx={{
                width: { md: "80%", xs: "100%" },
              }}
            >
              <FormControl sx={{ width: "100%" }}>
                <InputLabel
                  id="demo-simple-select-label"
                  sx={{
                    fontSize: {
                      xs: "12px",
                      sm: "13px",
                      md: "13px",
                      lg: "16px",
                    },
                    width: "100%",
                  }}
                >
                  Select Brand
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={brandFilter || ""}
                  label={
                    <span className="text-sm md:text-base font-medium">
                      Brand
                    </span>
                  }
                  sx={{
                    fontSize: {
                      xs: "12px",
                      sm: "13px",
                      md: "13px",
                      lg: "16px",
                    },
                  }}
                  className="min-h-[40px] md:min-h-[52px]"
                  onChange={(e) => {
                    setBrandFilter(e.target.value);
                  }}
                >
                  <MenuItem
                    key={0}
                    value=""
                    sx={{
                      fontSize: {
                        xs: "12px",
                        sm: "13px",
                        md: "13px",
                        lg: "16px",
                      },
                    }}
                  >
                    --Select Brand--
                  </MenuItem>
                  {brands.map((b, i) => (
                    <MenuItem
                      key={i + 1}
                      value={b}
                      sx={{
                        fontSize: {
                          xs: "12px",
                          sm: "13px",
                          md: "13px",
                          lg: "16px",
                        },
                      }}
                    >
                      {b.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid
              item
              xs={2}
              sx={{
                width: { md: "20%", xs: "100%" },
              }}
              display="flex"
              justifyContent="center"
            >
              <Button
                onClick={openAddUpdateBrands}
                fullWidth
                variant="contained"
                color="primary"
                sx={{
                  height: { xs: "40px", md: "56px" },
                  fontSize: {
                    xs: "12px",
                    sm: "13px",
                    md: "14px",
                    lg: "16px",
                  },
                  maxWidth: { xs: "100%", md: "200px" },
                  width: "100%",
                }}
                startIcon={<PlusIcon className="h-4 w-4 md:h-5 md:w-5" />}
              >
                Add Share
              </Button>
            </Grid>
          </Grid>

          <Grid
            style={{
              display: "flex",
              justifyContent: "center",
              width: "100%",
            }}
            sx={{
              marginTop: { xs: "18px", lg: "22px" },
            }}
          >
            <Grid
              sx={{
                width: "100%",
              }}
              container
              display={"flex"}
              justifyContent={"center"}
              spacing={2}
            >
              <Button
                fullWidth
                variant="contained"
                sx={{
                  height: { xs: "40px", md: "56px" },
                  fontSize: {
                    xs: "12px",
                    sm: "13px",
                    md: "14px",
                    lg: "16px",
                  },
                  maxWidth: { xs: "100%", md: "200px" },
                  width: "100%",
                }}
                color="secondary"
                onClick={handleResetFilter}
              >
                Reset All
              </Button>
            </Grid>
          </Grid>
        </div>
      </div>
      <div
        className={
          "relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded " +
          (color === "light" ? "bg-white" : "bg-lightBlue-900 text-white")
        }
      >
        <div className="block w-full overflow-x-auto py-3 px-4 lg:p-8">
          <Stack
            spacing={2}
            direction={{ md: "row", xs: "column" }}
            justifyContent={{ md: "space-between", xs: "center" }}
            className="w-full"
          >
            <div className="flex justify-center md:justify-end py-2 md:py-4 px-2 text-sky-500">
              Total: {totalElements}
            </div>
            <div className="mb-2 flex justify-center">
              <Pagination
                count={totalPages}
                page={currentPage}
                color="primary"
                onChange={handlePageChange}
                size={isMobile ? "small" : "medium"}
                className="mb-4"
              />
            </div>
          </Stack>
          <table className="min-w-full divide-y divide-gray-200">
            <thead className="bg-gray-100">
              <tr className="divide-x divide-gray-200 ">
                <TableHeader heading="" />
                <TableHeader heading="Brand Name" />
                <TableHeader heading="Media " />
                <TableHeader heading=" From - To" />
                <TableHeader heading="Status" />
                <TableHeader heading="Groups" />
                <TableHeader heading="Created On" />
                <TableHeader heading="Type" />
                <TableHeader heading="" />
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-200 bg-white">
              {posts &&
                posts.map((p, i) => (
                  <PostRow
                    edit={edit}
                    brands={brands}
                    currentPage={currentPage}
                    setPostAddPayload={setPostAddPayload}
                    setOpen={setOpen}
                    p={p}
                    i={i}
                    periodFrom={periodFrom}
                    periodTo={periodTo}
                  />
                ))}
            </tbody>
          </table>
        </div>
      </div>

      {/* ADD POST  */}
      <Dialog
        fullScreen
        maxWidth="lg"
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <AppBar sx={{ position: "relative" }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              Add Post Share
            </Typography>
          </Toolbar>
        </AppBar>
        <DialogContent dividers>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <div className="flex flex-col">
                {postAddPayload.type === "VIDEO" ? (
                  <ReactPlayer
                    onStart={(e) => {}}
                    playing={true}
                    className="w-full h-full aspect-square"
                    url={convertedURL}
                  />
                ) : (
                  <div className="w-[50%] aspect-square self-center bg-gray-300 ">
                    <img
                      loading="lazy"
                      style={{ objectFit: "contain" }}
                      className="h-full aspect-square "
                      src={convertedURL}
                      alt=""
                    />
                  </div>
                )}
              </div>
            </Grid>

            <Grid item xs={12} md={6}>
              <div className=" flex flex-row justify-between">
                <TextField
                  onChange={(e) =>
                    setPostAddPayload({
                      ...postAddPayload,
                      url: e.target.value,
                    })
                  }
                  value={postAddPayload.url}
                  fullWidth
                  id="outlined-basic"
                  label="URL"
                  variant="standard"
                />
              </div>
              <div className="my-5">
                <MatAutoComplete
                  label={"Search by Group name"}
                  getData={getGroupData}
                  options={groupOptions}
                  setOptions={setGroupOptions}
                  setValue={getSelectedGroups}
                  isMultiple={true}
                />
              </div>

              <div className="my-5 flex md:flex-row flex-col w-full md:gap-0 gap-4 justify-between">
                <FormControl sx={{ width: { md: "49%", xs: "100%" } }}>
                  <InputLabel id="demo-simple-select-label" variant="standard">
                    Type
                  </InputLabel>
                  <Select
                    variant="standard"
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={postAddPayload.type}
                    label="type"
                    onChange={(e) =>
                      setPostAddPayload({
                        ...postAddPayload,
                        type: e.target.value,
                      })
                    }
                  >
                    <MenuItem sx={{ color: "GrayText" }} value={null}>
                      --Select Type--
                    </MenuItem>
                    <MenuItem value={"IMAGE"}>IMAGE</MenuItem>
                    <MenuItem value={"VIDEO"}>VIDEO</MenuItem>
                  </Select>
                </FormControl>
                <FormControl sx={{ width: { md: "49%", xs: "100%" } }}>
                  <InputLabel id="demo-simple-select-label" variant="standard">
                    Brand
                  </InputLabel>
                  <Select
                    variant="standard"
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={selectedBrandInAddBrand}
                    label="Brand"
                    onChange={onBrandSelectInAddBrand}
                  >
                    <MenuItem key={0} value={null}>
                      --Select Brand--
                    </MenuItem>
                    {brands.map((b, i) => (
                      <MenuItem key={i + 1} value={b}>
                        {b.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
              <div className="my-5 flex flex-col md:flex-row justify-between">
                <FormControl sx={{ width: { md: "24%", xs: "100%" } }}>
                  <FormLabel id="demo-controlled-radio-buttons-group">
                    Active
                  </FormLabel>
                  <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group"
                    name="isActive"
                    value={postAddPayload.isActive}
                    onChange={(e) =>
                      setPostAddPayload({
                        ...postAddPayload,
                        isActive: e.target.value,
                      })
                    }
                  >
                    <FormControlLabel
                      value={true}
                      control={<Radio />}
                      label="True"
                    />
                    <FormControlLabel
                      value={false}
                      control={<Radio />}
                      label="False"
                    />
                  </RadioGroup>
                </FormControl>
                <FormControl sx={{ width: { md: "24%", xs: "100%" } }}>
                  <FormLabel id="demo-controlled-radio-buttons-group">
                    Deleted
                  </FormLabel>
                  <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group"
                    name="isDeleted"
                    value={postAddPayload.isDeleted}
                    onChange={(e) =>
                      setPostAddPayload({
                        ...postAddPayload,
                        isDeleted: e.target.value,
                      })
                    }
                  >
                    <FormControlLabel
                      value={true}
                      control={<Radio />}
                      label="True"
                    />
                    <FormControlLabel
                      value={false}
                      control={<Radio />}
                      label="False"
                    />
                  </RadioGroup>
                </FormControl>
                <TextField
                  sx={{
                    width: { md: "24%", xs: "100%" },
                    marginTop: { xs: "15px", md: "0px" },
                  }}
                  type="date"
                  onChange={(e) =>
                    setPostAddPayload({
                      ...postAddPayload,
                      periodFrom: e.target.value,
                    })
                  }
                  value={postAddPayload.periodFrom}
                  fullWidth
                  id="outlined-basic"
                  label="Period From"
                  variant="standard"
                />
                <TextField
                  sx={{
                    width: { md: "24%", xs: "100%" },
                    marginTop: { xs: "15px", md: "0px" },
                  }}
                  type="date"
                  onChange={(e) =>
                    setPostAddPayload({
                      ...postAddPayload,
                      periodTo: e.target.value,
                    })
                  }
                  value={postAddPayload.periodTo}
                  fullWidth
                  id="outlined-basic"
                  label="Period To"
                  variant="standard"
                />
              </div>
              <div className="my-3 md:my-5 flex flex-row justify-between">
                {dimensions && (
                  <>
                    height - {dimensions.height} px, width{" - "}
                    {dimensions.width} px
                  </>
                )}
              </div>
            </Grid>
            <Grid item xs={12} md={12}>
              <div>Name</div>
            </Grid>
            <Grid item xs={12} md={1.5}>
              <TextField
                type="number"
                onChange={(e) => {
                  setPostAddPayload((prev) => ({
                    ...prev,
                    name: { ...prev.name, size: e.target.value },
                  }));
                }}
                value={postAddPayload.name.size}
                fullWidth
                label="Size"
                variant="standard"
              />
            </Grid>
            <Grid item xs={12} md={1.5}>
              <TextField
                type="number"
                onChange={(e) => {
                  setPostAddPayload((prev) => ({
                    ...prev,
                    name: { ...prev.name, padding: e.target.value },
                  }));
                }}
                value={postAddPayload.name.padding}
                fullWidth
                label="Padding"
                variant="standard"
              />
            </Grid>
            <Grid item xs={12} md={1.5}>
              <TextField
                type="number"
                onChange={(e) => {
                  setPostAddPayload((prev) => ({
                    ...prev,
                    name: {
                      ...prev.name,
                      positionX:
                        e.target.value > dimensions.width
                          ? dimensions.width
                          : e.target.value,
                    },
                  }));
                }}
                value={postAddPayload.name.positionX}
                fullWidth
                label="Position X"
                variant="standard"
              />
            </Grid>
            <Grid item xs={12} md={1.5}>
              <TextField
                type="number"
                onChange={(e) => {
                  setPostAddPayload((prev) => ({
                    ...prev,
                    name: {
                      ...prev.name,
                      positionY:
                        e.target.value > dimensions.height
                          ? dimensions.height
                          : e.target.value,
                    },
                  }));
                }}
                value={postAddPayload.name.positionY}
                fullWidth
                label="Position Y"
                variant="standard"
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label" variant="standard">
                  Font
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={postAddPayload.name.font}
                  label="Font"
                  variant="standard"
                  onChange={(e) => {
                    setPostAddPayload((prev) => ({
                      ...prev,
                      name: { ...prev.name, font: e.target.value },
                    }));
                  }}
                >
                  <MenuItem value={"PaytoneOne-Regular.ttf"}>
                    Paytone One
                  </MenuItem>
                  <MenuItem value={"Gabarito-ExtraBold.ttf"}>Gabarito</MenuItem>
                  <MenuItem value={"Comfortaa-Bold.ttf"}>Comfortaa</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={1.5}>
              <MuiColorInput
                format="hex"
                label="BG Color"
                variant="standard"
                value={
                  postAddPayload.name.bgColor
                    ? "#" + postAddPayload.name.bgColor
                    : "#FFFFFF"
                }
                onChange={(color) =>
                  setPostAddPayload((prev) => ({
                    ...prev,
                    name: {
                      ...prev.name,
                      bgColor: color.toString().replaceAll("#", ""),
                    },
                  }))
                }
              />
            </Grid>
            <Grid item xs={12} md={1.5}>
              <MuiColorInput
                format="hex"
                label="Text Color"
                variant="standard"
                value={
                  postAddPayload.name.textColor
                    ? "#" + postAddPayload.name.textColor
                    : "#000000"
                }
                onChange={(color) =>
                  setPostAddPayload((prev) => ({
                    ...prev,
                    name: {
                      ...prev.name,
                      textColor: color.toString().replaceAll("#", ""),
                    },
                  }))
                }
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <div>Mobile</div>
            </Grid>
            <Grid item xs={12} md={1.5}>
              <TextField
                type="number"
                onChange={(e) => {
                  setPostAddPayload((prev) => ({
                    ...prev,
                    mobile: { ...prev.mobile, size: e.target.value },
                  }));
                }}
                value={postAddPayload.mobile.size}
                fullWidth
                label="Size"
                variant="standard"
              />
            </Grid>
            <Grid item xs={12} md={1.5}>
              <TextField
                type="number"
                onChange={(e) => {
                  setPostAddPayload((prev) => ({
                    ...prev,
                    mobile: { ...prev.mobile, padding: e.target.value },
                  }));
                }}
                value={postAddPayload.mobile.padding}
                fullWidth
                label="Padding"
                variant="standard"
              />
            </Grid>
            <Grid item xs={12} md={1.5}>
              <TextField
                type="number"
                onChange={(e) => {
                  setPostAddPayload((prev) => ({
                    ...prev,
                    mobile: {
                      ...prev.mobile,
                      positionX:
                        e.target.value > dimensions.width
                          ? dimensions.width
                          : e.target.value,
                    },
                  }));
                }}
                value={postAddPayload.mobile.positionX}
                fullWidth
                label="Position X"
                variant="standard"
              />
            </Grid>
            <Grid item xs={12} md={1.5}>
              <TextField
                type="number"
                onChange={(e) => {
                  setPostAddPayload((prev) => ({
                    ...prev,
                    mobile: {
                      ...prev.mobile,
                      positionY:
                        e.target.value > dimensions.height
                          ? dimensions.height
                          : e.target.value,
                    },
                  }));
                }}
                value={postAddPayload.mobile.positionY}
                fullWidth
                label="Position Y"
                variant="standard"
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label" variant="standard">
                  Font
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={postAddPayload.mobile.font}
                  label="Font"
                  variant="standard"
                  onChange={(e) => {
                    setPostAddPayload((prev) => ({
                      ...prev,
                      mobile: { ...prev.mobile, font: e.target.value },
                    }));
                  }}
                >
                  <MenuItem value={"PaytoneOne-Regular.ttf"}>
                    Paytone One
                  </MenuItem>
                  <MenuItem value={"Gabarito-ExtraBold.ttf"}>Gabarito</MenuItem>
                  <MenuItem value={"Comfortaa-Bold.ttf"}>Comfortaa</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={1.5}>
              <MuiColorInput
                format="hex"
                label="BG Color"
                variant="standard"
                value={`#${postAddPayload.mobile?.bgColor || "#FFFFFF"}`}
                onChange={(color) => {
                  const newBgColor = color.replace("#", "");
                  setPostAddPayload((prev) => {
                    const updatedPayload = {
                      ...prev,
                      mobile: {
                        ...prev.mobile,
                        bgColor: newBgColor,
                      },
                    };
                    return updatedPayload;
                  });
                }}
              />
            </Grid>
            <Grid item xs={12} md={1.5}>
              <MuiColorInput
                format="hex"
                label="Text Color"
                variant="standard"
                value={
                  postAddPayload.mobile.textColor
                    ? "#" + postAddPayload.mobile.textColor
                    : "#000000"
                }
                onChange={(color) =>
                  setPostAddPayload((prev) => ({
                    ...prev,
                    mobile: {
                      ...prev.mobile,
                      textColor: color.toString().replaceAll("#", ""),
                    },
                  }))
                }
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              if (postAddPayload.id) {
                edit(postAddPayload);
              } else {
                save();
              }
            }}
          >
            {postAddPayload.id ? "Save Changes" : "Save"}
          </Button>
        </DialogActions>
      </Dialog>

      <ToastContainer />
    </>
  );
}

export default Share;

const PostRow = ({ p, i, currentPage, setPostAddPayload, setOpen, edit }) => {
  const [isGroupOpen, setIsGroupOpen] = useState(false);

  return (
    <>
      <Dialog
        maxWidth="sm"
        fullWidth
        open={isGroupOpen}
        onClose={() => setIsGroupOpen(false)}
      >
        <DialogTitle>Groups</DialogTitle>
        <DialogContent dividers>
          <div className="flex justify-center items-center">
            {p.groups.map((e) => e.name).join(" ")}
          </div>
        </DialogContent>
      </Dialog>
      <tr key={i} className="divide-x divide-gray-200 hover:bg-slate-100">
        <TableElement value={50 * (currentPage - 1) + i + 1} />
        <TableElement value={p.brand?.name} />

        <TableElement
          value={
            <>
              {p.url && p.type === "IMAGE" && (
                <img
                  loading="lazy"
                  className="w-60 aspect-square bg-gray-300 object-contain "
                  src={
                    p.url.startsWith("https://") ? p.url : "https://" + p.url
                  }
                  alt="post"
                />
              )}
            </>
          }
        />

        <TableElement
          value={
            <>
              <span>{formatDate(p.periodFrom)}</span>
              <br />
              <span>{formatDate(p.periodTo)}</span>
            </>
          }
          type="element"
        />

        <TableElement
          value={
            <>
              <Button
                onDoubleClick={() => {
                  edit({
                    ...p,
                    isActive: !p.isActive,
                  });
                }}
              >
                <Tooltip title={"Double click to change."} arrow>
                  <span
                    className={
                      p.isActive
                        ? " text-emerald-500 font-bold"
                        : "text-red-500 font-bold "
                    }
                  >
                    ACTIVE
                  </span>
                </Tooltip>
              </Button>
              <br />

              <p
                className={
                  p.isDeleted
                    ? " text-emerald-500 font-bold mb-2"
                    : "text-red-500 font-bold mb-2"
                }
              >
                DELETED
              </p>
            </>
          }
          type="element"
        />

        <TableElement
          value={
            <>
              <Button
                onClick={() => setIsGroupOpen(true)}
                variant="text"
                aria-describedby="groups-popover"
              >
                Show
              </Button>
            </>
          }
        />
        <TableElement
          value={formatDateTimeComponent(p.createdOn)}
          type="element"
        />
        <TableElement value={p.type} />

        <TableElement
          value={
            <span
              className="cursor-pointer text-sky-500 hover:text-sky-950 font-bold gr "
              onClick={() => {
                setPostAddPayload(p);
                setOpen(true);
              }}
            >
              EDIT
            </span>
          }
        />
      </tr>
    </>
  );
};
