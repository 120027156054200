import React, { useEffect, useState } from "react";
import { BrandsApi } from "apis/BrandsApi";
import { toast } from "react-toastify";
import { MuiColorInput } from "mui-color-input";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { createSvgIcon } from "@mui/material/utils";
import Grid from "@mui/material/Unstable_Grid2";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import { ProductsApi } from "apis/ProductsApi";
import { useNavigate } from "react-router";
import TableHeader from "components/Table/TableHeader";
import TableElement from "components/Table/TableElement";
import { formatDateTime } from "utils";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import { setLoadingPanel } from "../../redux/features/loading";
import { useDispatch, useSelector } from "react-redux";
import CircularIndeterminateModal from "../../utils/Loading";

export default function Maps() {
  const navigate = useNavigate();
  const color = "light";
  const [open, setOpen] = React.useState(false);
  const [formData, setFormData] = useState({
    name: null,
    image: null,
    description: null,
    isActive: true,
    textColorHexCode: null,
    bgColorHexCode: null,
    bottomBgColorHexCode: null,
    bottomTextColorHexCode: null,
    position: 0,
    categoryId: null,
    brandSeries: [],
  });
  const dispatch = useDispatch();
  const isLoading = useSelector((state) => state.loadingReducer.isLoading);
  const [brands, setBrands] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredBrands, setfilteredBrands] = useState([]);
  const [category, setCategory] = useState([]);
  const [isEdit, setIsEdit] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setFormData({
      name: null,
      image: null,
      description: null,
      isActive: true,
      textColorHexCode: null,
      bgColorHexCode: null,
      bottomBgColorHexCode: null,
      bottomTextColorHexCode: null,
      position: 0,
      categoryId: null,
      brandSeries: [],
    });
    setIsEdit(false);
  };

  const PlusIcon = createSvgIcon(
    // credit: plus icon from https://heroicons.com/
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth={1.5}
      stroke="currentColor"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M12 4.5v15m7.5-7.5h-15"
      />
    </svg>,
    "Plus"
  );

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleColorChange = (color, name) => {
    setFormData({
      ...formData,
      [name]: color,
    });
  };

  const handleSave = () => {
    BrandsApi.AddBrand(formData)
      .then((res) => {
        toast("Brand Added", {
          type: "success",
          position: toast.POSITION.TOP_CENTER,
          autoClose: 1000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          style: {
            maxWidth: "90vw",
            width: "auto",
            margin: "5px  auto",
            padding: "5px 5px",
            fontSize: "14px",
          },
          closeButton: (
            <div className="flex justify-end">
              <button className="text-gray-500 hover:text-gray-800 focus:outline">
                <CloseIcon className="w-7 h-7" />
              </button>
            </div>
          ),
        });
        setOpen(false);
        getBrands();
      })
      .catch((err) => {
        if (err?.response?.data) {
          toast(err.response.status + " " + err?.response?.data?.message, {
            type: "error",
            position: toast.POSITION.TOP_CENTER,
            autoClose: 1000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            style: {
              maxWidth: "90vw",
              width: "auto",
              margin: "5px  auto",
              padding: "5px 5px",
              fontSize: "14px",
            },
            closeButton: (
              <div className="flex justify-end">
                <button className="text-gray-500 hover:text-gray-800 focus:outline">
                  <CloseIcon className="w-7 h-7" />
                </button>
              </div>
            ),
          });
        } else {
          toast(err?.response?.data?.message, {
            type: "error",
            position: toast.POSITION.TOP_CENTER,
            autoClose: 1000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            style: {
              maxWidth: "90vw",
              width: "auto",
              margin: "5px  auto",
              padding: "5px 5px",
              fontSize: "14px",
            },
            closeButton: (
              <div className="flex justify-end">
                <button className="text-gray-500 hover:text-gray-800 focus:outline">
                  <CloseIcon className="w-7 h-7" />
                </button>
              </div>
            ),
          });
        }
        if (err.response.status === 401) {
          localStorage.clear();
          navigate("/");
        }
      });
  };

  const searchBrands = (e) => {
    setSearchTerm(e.target.value);
  };

  useEffect(() => {
    const filtered = brands.filter((brand) =>
      brand.name.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setfilteredBrands(filtered);
  }, [searchTerm, brands]);

  useEffect(() => {
    getBrands();
    getCategories();
  }, []);

  const getBrands = () => {
    dispatch(setLoadingPanel(true));
    BrandsApi.GetBrandsListByFilter({
      page: 0,
      size: 99,
    })
      .then((res) => {
        setBrands(res.data.data);
        setfilteredBrands(brands);
        dispatch(setLoadingPanel(false));
      })
      .catch((err) => {
        dispatch(setLoadingPanel(false));
        if (err?.response?.data) {
          toast(err.response.status + " " + err?.response?.data?.message, {
            type: "error",
            position: toast.POSITION.TOP_CENTER,
            autoClose: 1000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            style: {
              maxWidth: "90vw",
              width: "auto",
              margin: "5px  auto",
              padding: "5px 5px",
              fontSize: "14px",
            },
            closeButton: (
              <div className="flex justify-end">
                <button className="text-gray-500 hover:text-gray-800 focus:outline">
                  <CloseIcon className="w-7 h-7" />
                </button>
              </div>
            ),
          });
        } else {
          toast(err?.response?.data?.message, {
            type: "error",
            position: toast.POSITION.TOP_CENTER,
            autoClose: 1000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            style: {
              maxWidth: "90vw",
              width: "auto",
              margin: "5px  auto",
              padding: "5px 5px",
              fontSize: "14px",
            },
            closeButton: (
              <div className="flex justify-end">
                <button className="text-gray-500 hover:text-gray-800 focus:outline">
                  <CloseIcon className="w-7 h-7" />
                </button>
              </div>
            ),
          });
        }
        if (err.response.status === 401) {
          localStorage.clear();
          navigate("/");
        }
      });
  };

  const getCategories = () => {
    dispatch(setLoadingPanel(true));
    ProductsApi.GetCategoryList({
      page: 0,
      size: 99,
    })
      .then((res) => {
        setCategory(res.data.data.filter((d) => d.isActive));
        dispatch(setLoadingPanel(false));
      })
      .catch((err) => {
        dispatch(setLoadingPanel(false));
        if (err?.response?.data) {
          toast(err.response.status + " " + err?.response?.data?.message, {
            type: "error",
            position: toast.POSITION.TOP_CENTER,
            autoClose: 1000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            style: {
              maxWidth: "90vw",
              width: "auto",
              margin: "5px  auto",
              padding: "5px 5px",
              fontSize: "14px",
            },
            closeButton: (
              <div className="flex justify-end">
                <button className="text-gray-500 hover:text-gray-800 focus:outline">
                  <CloseIcon className="w-7 h-7" />
                </button>
              </div>
            ),
          });
        } else {
          toast(err?.response?.data?.message, {
            type: "error",
            position: toast.POSITION.TOP_CENTER,
            autoClose: 1000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            style: {
              maxWidth: "90vw",
              width: "auto",
              margin: "5px  auto",
              padding: "5px 5px",
              fontSize: "14px",
            },
            closeButton: (
              <div className="flex justify-end">
                <button className="text-gray-500 hover:text-gray-800 focus:outline">
                  <CloseIcon className="w-7 h-7" />
                </button>
              </div>
            ),
          });
        }
        if (err.response.status === 401) {
          localStorage.clear();
          navigate("/");
        }
      });
  };

  const editBrand = (row) => {
    setIsEdit(true);

    setFormData({
      name: row.name,
      image: row.image,
      id: row.id,
      textColorHexCode: row.textColorHexCode,
      bgColorHexCode: row.bgColorHexCode,
      bottomBgColorHexCode: row.bottomBgColorHexCode,
      bottomTextColorHexCode: row.bottomTextColorHexCode,
      description: row.description,
      isActive: row.isActive,
      position: row.position,
      categoryId: row.categoryId,
      brandSeries: [""],
    });
    setOpen(true);
  };

  const edit = () => {
    BrandsApi.EditBrand(formData.id, formData)
      .then((res) => {
        toast("Brand Updated", { type: "success" });
        setOpen(false);
        getBrands();
      })
      .catch((err) => {
        if (err?.response?.data) {
          toast(err.response.status + " " + err?.response?.data?.message, {
            type: "error",
            position: toast.POSITION.TOP_CENTER,
            autoClose: 1000,
          });
        } else {
          toast(err?.response?.data?.message, {
            type: "error",
            position: toast.POSITION.TOP_CENTER,
            autoClose: 1000,
          });
        }
        if (err.response.status === 401) {
          localStorage.clear();
          navigate("/");
        }
      });
  };
  const [id, setId] = useState("");
  const [seriesData, setSeriesData] = useState({
    name: "",
    description: "",
    isActive: true,
    position: 0,
    templateUrl: "",
  });
  const handleChangeSeries = (event) => {
    const { name, value } = event.target;
    setSeriesData({
      ...seriesData,
      [name]: value,
    });
  };
  const handleAddSeries = () => {
    BrandsApi.AddSeries(id.id, seriesData)
      .then((res) => {
        toast("Series Added", { type: "success" });
        setOpenSeries(false);
        getBrands();
      })
      .catch((err) => {
        if (err?.response?.data) {
          toast(err.response.status + " " + err?.response?.data?.message, {
            type: "error",
            position: toast.POSITION.TOP_CENTER,
            autoClose: 1000,
          });
        } else {
          toast(err?.response?.data?.message, {
            type: "error",
            position: toast.POSITION.TOP_CENTER,
            autoClose: 1000,
          });
        }
        if (err.response.status === 401) {
          localStorage.clear();
          navigate("/");
        }
      });
  };

  const [openSeries, setOpenSeries] = React.useState(false);

  const handleClickOpenSeries = (i) => {
    setOpenSeries(true);
    setId(i);
    // handleAddSeries(i);
  };

  const handleCloseSeries = () => {
    setOpenSeries(false);
  };
  return (
    <>
      <CircularIndeterminateModal open={isLoading} />
      <div
        className={
          "relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded " +
          (color === "light" ? "bg-white" : "bg-lightBlue-900 text-white")
        }
      >
        <div className="block w-full overflow-x-auto p-4 xl:p-8">
          <Grid
            container
            spacing={2}
            sx={{
              flexDirection: {
                xs: "column",
                md: "row",
              },
              alignItems: {
                xs: "stretch",
                md: "center",
              },
              width: "100%",
            }}
          >
            <Grid
              item
              xs={12}
              md={9}
              sx={{
                mb: { xs: 1, md: 0 },
              }}
            >
              <TextField
                fullWidth
                variant="outlined"
                label={
                  <div className="text-xs sm:text-sm md:text-base lg:text-lg font-medium">
                    Search by Brand Name
                  </div>
                }
                placeholder="Search by brand name"
                onChange={searchBrands}
                InputProps={{
                  sx: {
                    fontSize: {
                      xs: "12px",
                      sm: "13px",
                      md: "13px",
                      lg: "16px",
                    },
                  },
                }}
              />
            </Grid>

            <Grid item xs={12} md={3}>
              <Button
                fullWidth
                variant="contained"
                color="primary"
                onClick={handleClickOpen}
                sx={{
                  height: { xs: "40px", md: "52px" },
                  fontSize: {
                    xs: "12px",
                    sm: "13px",
                    md: "13px",
                    lg: "16px",
                  },
                }}
                startIcon={<PlusIcon sx={{ fontSize: "small" }} />}
              >
                Add Brand
              </Button>
            </Grid>
          </Grid>
          <Dialog fullScreen open={open} onClose={handleClose}>
            <AppBar sx={{ position: "relative" }}>
              <Toolbar>
                <IconButton
                  edge="start"
                  color="inherit"
                  onClick={handleClose}
                  aria-label="close"
                >
                  <CloseIcon />
                </IconButton>
                <Typography sx={{ ml: 2, flex: 1 }} component="div">
                  {!isEdit ? "Add Brand" : `Edit Brand - ${formData.name}`}
                </Typography>
              </Toolbar>
            </AppBar>
            <DialogContent>
              <Grid container spacing={2}>
                <Grid item xs={12} md={4}>
                  <TextField
                    id="standard-requried"
                    required
                    sx={{ m: 2 }}
                    label="Name"
                    name="name"
                    variant="standard"
                    value={formData.name}
                    onChange={handleChange}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <TextField
                    id="standard-basic"
                    type="number"
                    label="Position"
                    name="position"
                    variant="standard"
                    sx={{ m: 2 }}
                    value={formData.position}
                    onChange={handleChange}
                    fullWidth
                  />
                </Grid>

                <Grid item xs={12} md={4}>
                  <FormControl sx={{ m: 2, minWidth: "100%" }}>
                    <FormLabel id="demo-row-radio-buttons-group-label">
                      Active
                    </FormLabel>
                    <RadioGroup
                      value={formData.isActive}
                      row
                      onChange={handleChange}
                      aria-labelledby="demo-row-radio-buttons-group-label"
                      name="isActive"
                      className="text-xs sm:text-sm md:text-base lg:text-lg font-medium"
                    >
                      <FormControlLabel
                        value={true}
                        control={
                          <Radio
                            sx={{
                              padding: "8px",
                              "& .MuiSvgIcon-root": {
                                fontSize: {
                                  xs: "18px",
                                  md: "22px",
                                },
                              },
                            }}
                          />
                        }
                        label="True"
                      />
                      <FormControlLabel
                        value={false}
                        control={
                          <Radio
                            sx={{
                              padding: "8px",
                              "& .MuiSvgIcon-root": {
                                fontSize: {
                                  xs: "18px",
                                  md: "22px",
                                },
                              },
                            }}
                          />
                        }
                        label="False"
                      />
                    </RadioGroup>
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={4}>
                  <TextField
                    id="standard-basic"
                    label="Image"
                    name="image"
                    variant="standard"
                    sx={{ m: 2 }}
                    value={formData.image}
                    onChange={handleChange}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <FormControl
                    variant="standard"
                    sx={{ m: 2, minWidth: "100%" }}
                  >
                    <InputLabel id="demo-simple-select-standard-label">
                      Category
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-standard-label"
                      id="demo-simple-select-standard"
                      value={formData.categoryId}
                      name="categoryId"
                      onChange={handleChange}
                      label="Category"
                    >
                      {category.map((i, index) => {
                        if (i.name) {
                          return (
                            <MenuItem key={index} value={i.id}>
                              {i.name}
                            </MenuItem>
                          );
                        } else {
                          return null;
                        }
                      })}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={4}>
                  <TextField
                    id="standard-basic"
                    label="Description"
                    name="description"
                    variant="standard"
                    sx={{ m: 2 }}
                    value={formData.description}
                    onChange={handleChange}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <Typography alignSelf={"baseline"} align="center">
                    Text Color HexCode
                  </Typography>
                </Grid>
                <Grid item xs={12} md={4}>
                  <MuiColorInput
                    sx={{ m: 2 }}
                    format="hex"
                    value={
                      formData.textColorHexCode
                        ? formData.textColorHexCode
                        : "#FFFFFF"
                    }
                    onChange={(color) =>
                      handleColorChange(color, "textColorHexCode")
                    }
                  />
                </Grid>
                <Grid item xs={12} md={4}></Grid>
                <Grid item xs={12} md={4}>
                  <Typography alignSelf={"baseline"} align="center">
                    BG Color HexCode
                  </Typography>
                </Grid>
                <Grid item xs={12} md={4}>
                  <MuiColorInput
                    sx={{ m: 2 }}
                    format="hex"
                    value={
                      formData.bgColorHexCode
                        ? formData.bgColorHexCode
                        : "#FFFFFF"
                    }
                    onChange={(color) =>
                      handleColorChange(color, "bgColorHexCode")
                    }
                  />
                </Grid>
                <Grid item xs={12} md={4}></Grid>
                <Grid item xs={12} md={4}>
                  <Typography alignSelf={"baseline"} align="center">
                    Buttom Color HexCode
                  </Typography>
                </Grid>
                <Grid item xs={12} md={4}>
                  <MuiColorInput
                    sx={{ m: 2 }}
                    format="hex"
                    value={
                      formData.bottomBgColorHexCode
                        ? formData.bottomBgColorHexCode
                        : "#FFFFFF"
                    }
                    onChange={(color) =>
                      handleColorChange(color, "bottomBgColorHexCode")
                    }
                  />
                </Grid>
                <Grid item xs={12} md={4}></Grid>
                <Grid item xs={12} md={4}>
                  <Typography alignSelf={"baseline"} align="center">
                    Buttom Text Color HexCode
                  </Typography>
                </Grid>
                <Grid item xs={12} md={4}>
                  <MuiColorInput
                    sx={{ m: 2 }}
                    format="hex"
                    value={
                      formData.bottomTextColorHexCode
                        ? formData.bottomTextColorHexCode
                        : "#FFFFFF"
                    }
                    onChange={(color) =>
                      handleColorChange(color, "bottomTextColorHexCode")
                    }
                  />
                </Grid>
                <Grid item xs={12} md={4}></Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              {isEdit ? (
                <Button onClick={edit}>Save Changes</Button>
              ) : (
                <Button onClick={handleSave}>Save</Button>
              )}
            </DialogActions>
          </Dialog>
        </div>
      </div>

      {/* ADD SERIES DIALOG */}
      <Dialog open={openSeries} onClose={handleCloseSeries}>
        <DialogTitle>Add Series</DialogTitle>
        <DialogContent>
          <Grid container>
            <Grid xs={6} md={6}>
              <TextField
                id="outlined-basic"
                sx={{ m: 2 }}
                label="Name"
                name="name"
                variant="outlined"
                value={seriesData.name}
                onChange={handleChangeSeries}
              />
              <TextField
                id="outlined-multiline-static"
                sx={{
                  width: { sm: 200, md: 225 },
                  m: 2,
                }}
                multiline
                rows={4}
                label="Description"
                name="description"
                value={seriesData.description}
                onChange={handleChangeSeries}
              />
            </Grid>
            <Grid xs={6} md={6}>
              <FormControl sx={{ m: 2 }}>
                <FormLabel id="demo-controlled-radio-buttons-group">
                  IsActive
                </FormLabel>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group"
                  name="isActive"
                  value={seriesData.isActive}
                  onChange={handleChangeSeries}
                >
                  <FormControlLabel
                    value={true}
                    control={<Radio />}
                    label="True"
                  />
                  <FormControlLabel
                    value={false}
                    control={<Radio />}
                    label="False"
                  />
                </RadioGroup>
              </FormControl>
              <TextField
                id="outlined-basic"
                type="number"
                label="Position"
                name="position"
                variant="outlined"
                sx={{ m: 2 }}
                value={seriesData.position}
                onChange={handleChangeSeries}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleAddSeries}>Save</Button>
        </DialogActions>
      </Dialog>
      <div
        className={
          "relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded " +
          (color === "light" ? "bg-white" : "bg-lightBlue-900 text-white")
        }
      >
        <div className="block w-full overflow-x-auto py-3 px-5 lg:p-8">
          <table className="min-w-full divide-y divide-gray-200">
            <thead className="bg-gray-100">
              <tr className="divide-x divide-gray-200 ">
                <TableHeader heading="" />
                <TableHeader heading="Name" />
                <TableHeader heading="Position" />
                <TableHeader heading="Category" />
                <TableHeader heading="status" />
                <TableHeader heading="Audit" />
                <TableHeader heading="" />
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-200 bg-white">
              {filteredBrands.map((brand, i) => {
                return (
                  <tr
                    key={i}
                    className="divide-x divide-gray-200 hover:bg-slate-100"
                  >
                    <TableElement value={i} />
                    <TableElement
                      value={
                        <span className="font-semibold">{brand.name}</span>
                      }
                      type="element"
                    />
                    <TableElement value={brand.position} />
                    <TableElement value={brand.category.name} />
                    <TableElement value={brand.isActive} type="boolean" />
                    <TableElement
                      value={
                        <div className="flex flex-col">
                          <span>
                            Created:- {formatDateTime(brand.createdOn)}
                          </span>
                          <span>
                            Updated:- {formatDateTime(brand.lastUpdatedOn)}
                          </span>
                        </div>
                      }
                      type="element"
                    />
                    <TableElement
                      value={
                        <span
                          className="cursor-pointer text-sky-500 hover:text-sky-950 font-bold gr "
                          onClick={() => editBrand(brand)}
                        >
                          EDIT
                        </span>
                      }
                      type="element"
                    />
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
}
