import React, { useEffect, useState } from "react";
import axios from "axios";
import { ProductsApi } from "apis/ProductsApi";
import { BrandsApi } from "apis/BrandsApi";
import { NlcApi } from "apis/NlcApi";
import { GroupsApi } from "apis/GroupsApi";
import { ToastContainer, toast } from "react-toastify";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import TextField from "@mui/material/TextField";
import ListItem from "@mui/material/ListItem";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import Slide from "@mui/material/Slide";
import DialogActions from "@mui/material/DialogActions";
import MatAutoComplete from "components/MatAutoComplete/MatAutoComplete";
import { SchemesApi } from "apis/SchemesApi";
import AddNlcTable from "../../components/Tables/AddNlcTable";
import { useNavigate } from "react-router";
import Chip from "@mui/material/Chip";
import { Badge, createSvgIcon, useMediaQuery } from "@mui/material";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormLabel from "@mui/material/FormLabel";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import TableHeader from "components/Table/TableHeader";
import TableElement from "components/Table/TableElement";
import Popover from "@mui/material/Popover";
import { formatDateTimeComponent } from "utils";
import CircularIndeterminateModal from "../../utils/Loading";
import { useDispatch, useSelector } from "react-redux";
import { setLoadingPanel } from "../../redux/features/loading";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const LastDayOfMonth = (y, m) => {
  return new Date(y, m, 0).getDate();
};

export default function Nlc() {
  const navigate = useNavigate();
  const date = new Date();
  // const day = date.getDay();
  const month = date.getMonth() + 1;
  const year = date.getFullYear();
  const periodFrom = `${year}-${month > 9 ? month : "0" + month}-01`;
  const periodTo = `${year}-${month > 9 ? month : "0" + month}-${LastDayOfMonth(
    year,
    month
  )}`;

  const color = "light";
  const [brands, setBrands] = useState([]);
  const [nlcList, setNlcList] = useState([]);
  const [selectedBrand, setSelectedBrand] = useState("");
  const [open, setOpen] = React.useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState();
  const [nlcAddPayload, setNlcAddPayload] = useState({
    brandId: "",
    productId: "",
    retailerTypeId: null,
    retailerTypeName: null,
    priceId: "",
    templateUrl: "",
    helpText: "",
    isActive: true,
    productImages: null,
    amazon: null,
    flipkart: null,
    periodFrom: periodFrom,
    periodTo: periodTo,
  });
  const [selectedBrandInAddBrand, setSelectedBrandInAddBrand] = useState("");
  const [isEdit, setIsEdit] = useState(false);
  const [schemeOptions, setSchemeOptions] = useState([]);
  const [modelOptions, setModelOptions] = useState([]);
  const [selectedScheme, setSelectedScheme] = useState("");
  const [multipleNlcPayload, setMultipleNlcPayload] = useState([]);
  const dispatch = useDispatch();
  const isLoading = useSelector((state) => state.loadingReducer.isLoading);
  const [editNlcPayload, setEditNlcPayload] = useState({
    helpText: "",
    isActive: true,
    periodFrom: "",
    periodTo: "",
    price: {
      mrp: 0,
      mop: 0,
      dp: 0,
    },
    schemesDTO: [],
    schemes: [],
    amazon: {
      mrp: 0,
      mop: 0,
      dp: 0,
      nlc: 0,
      remark: null,
    },
    flipkart: {
      mrp: 0,
      mop: 0,
      dp: 0,
      nlc: 0,
      remark: null,
    },
  });
  const [nlcGroups, setNlcGroups] = useState({});

  const openAddUpdateNLC = () => {
    setOpen(true);
    setSchemeOptions([]);
    setMultipleNlcPayload([]);
    setModelOptions([]);
  };

  const handleGetGroupById = async (id) => {
    try {
      return await GroupsApi.GetGroupsListById(id);
    } catch (err) {}
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    axios.defaults.headers.common["x-api-key"] =
      "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiIxOTU4NDk0NDY3NDY0MzE1NjQ5ODc0NTY0ODkyNiIsIm5hbWUiOiJTY2hlbWVzIEJvb29rIiwiaWF0IjoxNTE2MjM5MDIyfQ.uVUOR2U82nTjNccumsyggdScQh0DA5PdlwF_l0VUdfd";
    axios.defaults.headers.common["Authorization"] =
      localStorage.getItem("token");
    getBrands();
  }, []);

  const getBrands = () => {
    dispatch(setLoadingPanel(true));
    BrandsApi.GetBrandsListByFilter({
      page: 0,
      size: 99,
    })
      .then((res) => {
        const b = res.data.data.filter((d) => d.isActive);
        setBrands(b);
        setSelectedBrand(b[0]);
        getNlc(b[0], 0);
      })
      .catch((err) => {
        dispatch(setLoadingPanel(false));
        if (err?.response?.data) {
          toast(err.response.status + " " + err?.response?.data?.message, {
            type: "error",
            position: toast.POSITION.TOP_CENTER,
            autoClose: 1000,
          });
        } else {
          toast(err?.response?.data?.message, {
            type: "error",
            position: toast.POSITION.TOP_CENTER,
            autoClose: 1000,
          });
        }
        if (err.response.status === 401) {
          localStorage.clear();
          navigate("/");
        }
      });
  };

  const getNlc = (brand, page) => {
    dispatch(setLoadingPanel(true));
    NlcApi.GetNlcListByFilter({
      page: page,
      size: 100,
      periodFrom: periodFrom,
      periodTo: periodTo,
      brandId: brand.id,
    })
      .then((res) => {
        setTotalPages(res.data.pages);
        const uniqueGroups = [
          ...new Set(
            res.data.data
              .map((n) => n.groups.join(","))
              .join(",")
              .split(",")
          ),
        ];
        uniqueGroups.forEach(async (group) => {
          await handleGetGroupById(group)
            .then((res) => {
              setNlcGroups((groups) => {
                const newGroups = { ...groups, [group]: res.data?.data?.name };
                return newGroups;
              });
            })
            .catch((err) => {
              toast(err?.response?.data?.message, {
                type: "error",
                position: toast.POSITION.TOP_CENTER,
                autoClose: 1000,
              });
            });
          setNlcList(res.data.data);
        });
        dispatch(setLoadingPanel(false));
      })
      .catch((err) => {
        dispatch(setLoadingPanel(false));
        if (err?.response?.data) {
          toast(err.response.status + " " + err?.response?.data?.message, {
            type: "error",
            position: toast.POSITION.TOP_CENTER,
            autoClose: 1000,
          });
        } else {
          toast(err?.response?.data?.message, {
            type: "error",
            position: toast.POSITION.TOP_CENTER,
            autoClose: 1000,
          });
        }
        if (err.response.status === 401) {
          localStorage.clear();
          navigate("/");
        }
      });
  };

  const searchNlc = (e) => {
    NlcApi.SearchNlc({
      page: 0,
      size: 20,
      periodFrom: periodFrom,
      periodTo: periodTo,
      brandId: selectedBrand.id,
      keyword: e.target.value,
    })
      .then((res) => {
        setNlcList(res.data.data);
      })
      .catch((err) => {
        if (err?.response?.data) {
          toast(err.response.status + " " + err?.response?.data?.message, {
            type: "error",
            position: toast.POSITION.TOP_CENTER,
            autoClose: 1000,
          });
        } else {
          toast(err?.response?.data?.message, {
            type: "error",
            position: toast.POSITION.TOP_CENTER,
            autoClose: 1000,
          });
        }
        if (err.response.status === 401) {
          localStorage.clear();
          navigate("/");
        }
      });
  };

  const handleChange = (event) => {
    setSelectedBrand(event.target.value);
    getNlc(event.target.value, 0);
  };

  const onBrandSelectInAddBrand = (event) => {
    const brand = event.target.value;
    setSelectedBrandInAddBrand(brand);
    setNlcAddPayload({
      ...nlcAddPayload,
      categoryId: brand.category.id,
      brandId: brand.id,
    });
  };
  const handlePageChange = (event, page) => {
    setCurrentPage(page);

    getNlc(selectedBrand, page - 1);
    return currentPage;
  };
  const [userGroups, setUserGroups] = useState(null);
  const getSchemeData = (searchTerm) => {
    SchemesApi.SearchByKeyword({
      page: 0,
      size: 20,
      brandId: selectedBrandInAddBrand.id,
      periodFrom: periodFrom,
      periodTo: periodTo,
      keyword: searchTerm,
      userGroups: userGroups,
    }).then((res) => {
      const updatedOptions = res.data.data.map((p) => {
        return {
          name: p.name,
          id: p.id,
          groups: p.groups,
          periodFrom: p.periodFrom,
          periodTo: p.periodTo,
        };
      });
      setSchemeOptions(updatedOptions);
    });
  };

  const [groupOptions, setGroupOptions] = useState([]);
  const getGroupData = (searchTerm) => {
    GroupsApi.GetGroupsListBySearch({
      page: 0,
      size: 10,
      keyword: searchTerm,
    }).then((res) => {
      const filter = res.data.data.filter((ele) => ele.isActive);
      const updatedOptions = filter.map((p) => {
        return {
          name: p.name,
          id: p.id,
        };
      });
      setGroupOptions(updatedOptions);
    });
  };
  const getSelectedGroups = (data) => {
    setUserGroups(data.id);
  };

  const getModelData = (searchTerm) => {
    ProductsApi.SearchProducts({
      page: 0,
      size: 10,
      keyword: searchTerm,
      brandId: selectedBrandInAddBrand.id,
    }).then((res) => {
      const filter = res.data.data.filter((ele) => ele.isActive);
      const updatedOptions = filter.map((p) => {
        return {
          name: p.primaryName + " " + p?.secondaryName,
          id: p.id,
          prices: p.prices,
        };
      });

      setModelOptions(updatedOptions);
    });
  };
  const [selectedSchemePeriodFrom, setSelectedSchemePeriodFrom] = useState();
  const [selectedSchemePeriodTo, setSelectedSchemePeriodTo] = useState();
  const getSelectedSchemes = (data) => {
    setSelectedScheme(data);
    setSelectedSchemePeriodFrom(data.periodFrom);
    setSelectedSchemePeriodTo(data.periodTo);
    setNlcAddPayload({
      ...nlcAddPayload,
      schemesDTO: [
        {
          id: data.id,
          amount: 0,
          remark: "",
        },
      ],
    });
  };
  const getSelectedModels = (data) => {
    const array = [];
    data.forEach((m, i) => {
      let payload = multipleNlcPayload.filter((e) => e.productId === m.id);
      if (payload.length > 0) {
        array.push(payload[0]);
      } else {
        let obj = {};
        obj.brandId = nlcAddPayload.brandId;
        obj.productId = m.id;
        obj.productName = m.name;
        obj.prices = m.prices;
        obj.selectedPrice = m?.prices?.length > 0 ? m.prices[0] : null;
        obj.retailerTypeId = nlcAddPayload.retailerTypeId;
        obj.retailerTypeName = nlcAddPayload.retailerTypeName;
        obj.priceId = nlcAddPayload.priceId
          ? nlcAddPayload.priceId
          : m?.prices?.length > 0
          ? m.prices[0].id
          : null;
        obj.templateUrl = nlcAddPayload.templateUrl;
        obj.helpText = nlcAddPayload.helpText;
        obj.isActive = nlcAddPayload.isActive;
        obj.schemesDTO = nlcAddPayload.schemesDTO;
        obj.productImages = nlcAddPayload.productImages;
        obj.amazon = nlcAddPayload.amazon;
        obj.flipkart = nlcAddPayload.flipkart;
        obj.periodFrom = selectedSchemePeriodFrom;
        obj.periodTo = selectedSchemePeriodTo;
        obj.templateUrl = nlcAddPayload.templateUrl;
        array.push(obj);
      }
    });

    setMultipleNlcPayload(array);
  };
  const PlusIcon = createSvgIcon(
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth={1.5}
      stroke="currentColor"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M12 4.5v15m7.5-7.5h-15"
      />
    </svg>,
    "Plus"
  );
  const save = () => {
    const error = {};
    const valid = multipleNlcPayload.every((m, payloadIndex) => {
      if (
        m.priceId &&
        m.schemesDTO.every((s, schemeIndex) => {
          if (s.amount || s.remark) {
            return true;
          } else {
            error[payloadIndex] = schemeIndex;
            return false;
          }
        })
      ) {
        return true;
      } else {
        return false;
      }
    });
    if (valid) {
      multipleNlcPayload.forEach((m, i) => {
        setTimeout(() => {
          NlcApi.GetNlcByProduct({
            page: 0,
            size: 20,
            periodFrom: periodFrom,
            periodTo: periodTo,
            productId: m.productId,
            userGroups: selectedScheme?.groups?.map((e) => e.id).join(","),
          }).then((res) => {
            if (res.data.data.length > 0) {
              m.nlcId = res.data.data[0].id;
              NlcApi.UpdateNlc(m)
                .then((res) => {
                  toast("NLC Updated", {
                    type: "success",
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 1000,
                  });
                  setOpen(false);
                  getNlc(selectedBrand, 0);
                  setSchemeOptions([]);
                  setModelOptions([]);
                })
                .catch((err) => {
                  toast(err?.response?.data?.message, {
                    type: "error",
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 1000,
                  });
                });
            } else {
              NlcApi.AddNlc(m)
                .then((res) => {
                  toast("NLC Created", {
                    type: "success",
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 1000,
                  });
                  setOpen(false);
                  getNlc(selectedBrand, 0);
                  setSchemeOptions([]);
                  setModelOptions([]);
                })
                .catch((err) => {
                  toast(err?.response?.data?.message, {
                    type: "error",
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 1000,
                  });
                });
            }
          });
        }, i * 100);
      });
    } else {
      var keys = Object.keys(error);
      keys.forEach((e) => {
        toast(
          multipleNlcPayload[e].productName +
            " Price  :  " +
            multipleNlcPayload[e].priceId +
            " Remark :  " +
            multipleNlcPayload[e].remark +
            " Amount :  " +
            multipleNlcPayload[e].amount,
          {
            type: "error",
            position: toast.POSITION.TOP_CENTER,
            autoClose: 2000,
          }
        );
      });
      toast(
        "Enter Amount or Remark in for All Products and Select price for all",
        {
          type: "error",
          position: toast.POSITION.TOP_CENTER,
          autoClose: 2000,
        }
      );
    }
  };

  const onEditClick = (row) => {
    setEditNlcPayload(row);
    setIsEdit(true);
  };

  const edit = () => {
    const payload = {
      id: editNlcPayload.id,
      brandId: editNlcPayload.brand.id,
      productId: editNlcPayload.product.id,
      retailerTypeId: null,
      retailerTypeName: null,
      helpText: editNlcPayload.helpText,
      isActive: editNlcPayload.isActive,
      schemes: editNlcPayload.schemes,
      schemesDTO: editNlcPayload.schemesDTO,
      productImages: null,
      price: editNlcPayload.price,
      amazon: editNlcPayload.amazon,
      flipkart: editNlcPayload.flipkart,
      periodFrom: editNlcPayload.periodFrom,
      periodTo: editNlcPayload.periodTo,
      nlcId: editNlcPayload.id,
    };

    NlcApi.UpdateNlc(payload).then((res) => {
      toast("Success", {
        type: "success",
        position: toast.POSITION.TOP_CENTER,
        autoClose: 1000,
      });
      setIsEdit(false);
      getNlc(selectedBrand, 0);
    });
  };

  const [schemesAnchorEl, setSchemesAnchorEl] = React.useState(null);
  const [popoverSchemes, setPopoverSchemes] = React.useState([]);
  const isMobile = useMediaQuery("(max-width:768px)");
  const handleClickSchemesPopover = (event, schemes) => {
    setSchemesAnchorEl(event.currentTarget);
    setPopoverSchemes(schemes);
  };

  const handleCloseSchemesPopover = () => {
    setSchemesAnchorEl(null);
    setPopoverSchemes([]);
  };
  const openSchemes = Boolean(schemesAnchorEl);
  const idSchemes = openSchemes ? "simpleSchemes-popover" : undefined;

  return (
    <>
      <CircularIndeterminateModal open={isLoading && !brands.length} />
      <div
        className={`
    relative flex flex-col lg:flex-row items-center
    min-w-0 break-words w-full mb-6 shadow-lg rounded
    ${color === "light" ? "bg-white" : "bg-lightBlue-900 text-white"}
  `}
      >
        <div className="w-full lg:w-[70%] p-4 md:p-8">
          <Box className="w-full">
            <FormControl fullWidth>
              <InputLabel
                id="demo-simple-select-label"
                className="text-sm md:text-base"
              >
                Select Brand
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={selectedBrand}
                label={
                  <span className="text-sm md:text-base font-medium">
                    Brand
                  </span>
                }
                onChange={handleChange}
                sx={{
                  fontSize: {
                    xs: "12px",
                    sm: "13px",
                    md: "13px",
                    lg: "16px",
                  },
                }}
                className="min-h-[40px] md:min-h-[52px]"
              >
                {brands.map((b, i) => (
                  <MenuItem
                    key={i}
                    value={b}
                    sx={{
                      fontSize: {
                        xs: "12px",
                        sm: "13px",
                        md: "13px",
                        lg: "16px",
                      },
                    }}
                  >
                    {b.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
        </div>

        <div className="w-full lg:w-[30%] md:p-8 p-4 flex items-center">
          <Button
            onClick={openAddUpdateNLC}
            fullWidth
            variant="contained"
            color="primary"
            sx={{
              height: { xs: "40px", md: "52px" },
              fontSize: {
                xs: "12px",
                sm: "13px",
                md: "13px",
                lg: "16px",
              },
            }}
            startIcon={<PlusIcon className="h-4 w-4 md:h-5 md:w-5" />}
          >
            Add NLC
          </Button>
        </div>
      </div>

      <div
        className={
          "relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded " +
          (color === "light" ? "bg-white" : "bg-lightBlue-900 text-white")
        }
      >
        <div className="block w-full overflow-x-auto p-4 md:p-8">
          <div className="mb-3 pt-0">
            <input
              onChange={searchNlc}
              type="text"
              placeholder="Search Model by name"
              className="px-3 py-3 placeholder-blueGray-300 text-blueGray-600 relative bg-white rounded text-sm shadow outline-none focus:outline-none focus:shadow-outline w-full"
            />
          </div>
        </div>
      </div>

      <div
        className={
          "relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded " +
          (color === "light" ? "bg-white" : "bg-lightBlue-900 text-white")
        }
      >
        <div className="block w-full overflow-x-auto py-3 px-4 lg:p-8">
          <div className="flex justify-center p-2 mb-2">
            <Stack spacing={2}>
              <Pagination
                count={totalPages}
                page={currentPage}
                color="primary"
                onChange={handlePageChange}
                size={isMobile ? "small" : "medium"}
              />
            </Stack>
          </div>

          <table className="min-w-full divide-y divide-gray-200">
            <thead className="bg-gray-100">
              <tr className="divide-x divide-gray-200 ">
                <TableHeader value="" />
                <TableHeader heading="Name" />
                <TableHeader heading="NLC" />
                <TableHeader heading="DP" />
                <TableHeader heading="MOP" />
                <TableHeader heading="MRP" />
                <TableHeader heading="Schemes" />
                <TableHeader heading="Groups" />
                <TableHeader heading="Updated" />
                <TableHeader heading="" />
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-200 bg-white">
              {nlcList.map((p, index, arr) => {
                return (
                  <tr
                    key={index}
                    className="divide-x divide-gray-200 hover:bg-slate-100"
                  >
                    <TableElement value={index + 1} />
                    <TableElement
                      value={
                        p.product.primaryName + " " + p.product.secondaryName
                      }
                    />
                    <TableElement value={Number(p.price.nlc).toFixed(2)} />
                    <TableElement value={Number(p.price.dp).toFixed(2)} />
                    <TableElement value={Number(p.price.mop).toFixed(2)} />
                    <TableElement value={Number(p.price.mrp).toFixed(2)} />

                    <TableElement
                      value={
                        <>
                          {p.schemes && p.schemes.length > 0 ? (
                            <Button
                              variant="text"
                              onClick={(event) =>
                                handleClickSchemesPopover(event, p.schemes)
                              }
                            >
                              <span className="font-bold"> Show</span>
                            </Button>
                          ) : (
                            <></>
                          )}

                          <Popover
                            elevation={1}
                            id={idSchemes}
                            sx={{ boxShadow: "none" }}
                            open={openSchemes}
                            anchorEl={schemesAnchorEl}
                            onClose={handleCloseSchemesPopover}
                            anchorOrigin={{
                              vertical: "bottom",
                              horizontal: "left",
                            }}
                          >
                            <Typography sx={{ p: 2 }}>
                              <table className="min-w-full divide-y divide-gray-200">
                                <thead className="bg-gray-100">
                                  <tr className="divide-x divide-gray-200 ">
                                    <TableHeader heading="" />
                                    <TableHeader heading="Name" />
                                    <TableHeader heading="Amount" />
                                    <TableHeader heading="Remark" />
                                  </tr>
                                </thead>
                                <tbody className="divide-y divide-gray-200 bg-white">
                                  {popoverSchemes.map((s, i) => (
                                    <tr
                                      key={i}
                                      className="divide-x divide-gray-200 hover:bg-slate-100"
                                    >
                                      <TableElement value={i + 1} />
                                      <TableElement
                                        value={<>{s.name ? s.name : ""}</>}
                                        type="element"
                                      />
                                      <TableElement value={s.amount} />
                                      <TableElement
                                        style={{ whiteSpace: "pre" }}
                                        value={s.remark}
                                      />
                                    </tr>
                                  ))}
                                </tbody>
                              </table>
                            </Typography>
                          </Popover>
                        </>
                      }
                    />
                    <TableElement
                      value={p.groups
                        .map((g) => {
                          return nlcGroups[g];
                        })
                        .join(", ")}
                    />
                    <TableElement
                      value={formatDateTimeComponent(p.lastUpdatedOn)}
                      type="element"
                    />

                    <TableElement
                      value={
                        <span
                          className="cursor-pointer text-sky-500 hover:text-sky-950 font-bold gr "
                          onClick={() => onEditClick(p)}
                        >
                          EDIT
                        </span>
                      }
                    />
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>

      {/* nlcList Add */}
      <div>
        <Dialog
          fullScreen
          open={open}
          onClose={handleClose}
          TransitionComponent={Transition}
        >
          <AppBar sx={{ position: "relative" }}>
            <Toolbar>
              <IconButton
                edge="start"
                color="inherit"
                onClick={handleClose}
                aria-label="close"
              >
                <CloseIcon />
              </IconButton>
              <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                Add Nlc
              </Typography>
            </Toolbar>
          </AppBar>
          <List>
            {isEdit ? (
              ""
            ) : (
              <ListItem className="mt-4">
                <div className="block w-full overflow-x-auto">
                  <Box sx={{ minWidth: 120 }}>
                    <FormControl fullWidth>
                      <InputLabel id="demo-simple-select-label">
                        Select Brand
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={selectedBrandInAddBrand}
                        label="Brand"
                        onChange={onBrandSelectInAddBrand}
                      >
                        {brands.map((b, i) => (
                          <MenuItem key={i} value={b}>
                            {b.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Box>
                </div>
              </ListItem>
            )}
            <ListItem className="mt-4">
              <div className="block w-full overflow-x-auto">
                <TextField
                  onChange={(e) =>
                    setNlcAddPayload({
                      ...nlcAddPayload,
                      helpText: e.target.value,
                    })
                  }
                  value={nlcAddPayload.helpText}
                  fullWidth
                  id="outlined-basic"
                  label="Global Remark"
                  variant="outlined"
                />
              </div>
            </ListItem>
            <ListItem className="mt-4">
              <div className="block w-full overflow-x-auto">
                <FormControl sx={{ m: 2 }}>
                  <FormLabel id="demo-controlled-radio-buttons-group">
                    IsActive
                  </FormLabel>
                  <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group"
                    name="isActive"
                    value={nlcAddPayload.isActive}
                    onChange={(e) =>
                      setNlcAddPayload({
                        ...nlcAddPayload,
                        isActive: e.target.value,
                      })
                    }
                  >
                    <FormControlLabel
                      value={true}
                      control={<Radio />}
                      label="True"
                    />
                    <FormControlLabel
                      value={false}
                      control={<Radio />}
                      label="False"
                    />
                  </RadioGroup>
                </FormControl>
              </div>
            </ListItem>
            {/* <ListItem className="mt-4">
              <div className="block w-full overflow-x-auto">
                <TextField
                  type="date"
                  onChange={(e) =>
                    setNlcAddPayload({
                      ...nlcAddPayload,
                      periodFrom: e.target.value,
                    })
                  }
                  value={nlcAddPayload.periodFrom}
                  fullWidth
                  id="outlined-basic"
                  label="Start Date"
                  variant="outlined"
                />
              </div>
            </ListItem> */}
            {/* <ListItem className="mt-4">
              <div className="block w-full overflow-x-auto">
                <TextField
                  type="date"
                  onChange={(e) =>
                    setNlcAddPayload({
                      ...nlcAddPayload,
                      periodTo: e.target.value,
                    })
                  }
                  value={nlcAddPayload.periodTo}
                  fullWidth
                  id="outlined-basic"
                  label="End Date"
                  variant="outlined"
                />
              </div>
            </ListItem> */}
            <ListItem className="mt-4">
              <div className="block w-full overflow-x-auto">
                <MatAutoComplete
                  label={"Select Scheme by Group name"}
                  getData={getGroupData}
                  options={groupOptions}
                  setOptions={setGroupOptions}
                  setValue={getSelectedGroups}
                  isMultiple={false}
                />
              </div>
            </ListItem>
            <ListItem className="mt-4">
              <div className="block w-full overflow-x-auto">
                <MatAutoComplete
                  label={"Search by scheme name"}
                  getData={getSchemeData}
                  options={schemeOptions}
                  setOptions={setSchemeOptions}
                  setValue={getSelectedSchemes}
                  isMultiple={false}
                />
              </div>
            </ListItem>
            <ListItem className="mt-4">
              <div className="block w-full overflow-x-auto">
                <MatAutoComplete
                  label={"Search by model name"}
                  getData={getModelData}
                  options={modelOptions}
                  setOptions={setModelOptions}
                  setValue={getSelectedModels}
                  isMultiple={true}
                />
              </div>
            </ListItem>
          </List>
          <AddNlcTable
            multipleNlcPayload={multipleNlcPayload}
            setMultipleNlcPayload={setMultipleNlcPayload}
            selectedSchemePeriodFrom={selectedSchemePeriodFrom}
            selectedSchemePeriodTo={selectedSchemePeriodTo}
          />
          <DialogActions>
            {isEdit ? (
              <Button onClick={edit}>Save Changes</Button>
            ) : (
              <Button onClick={save}>Save</Button>
            )}
          </DialogActions>
        </Dialog>
      </div>

      {/*?! EDIT NLC */}
      <div>
        <Dialog
          fullScreen
          open={isEdit}
          onClose={() => setIsEdit(false)}
          TransitionComponent={Transition}
        >
          <AppBar sx={{ position: "relative" }}>
            <Toolbar>
              <IconButton
                edge="start"
                color="inherit"
                onClick={() => setIsEdit(false)}
                aria-label="close"
              >
                <CloseIcon />
              </IconButton>
              <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                Edit Nlc -{" "}
                <span style={{ color: "black" }}>
                  {editNlcPayload?.product?.primaryName}{" "}
                  {editNlcPayload?.product?.secondaryName}
                </span>
              </Typography>
            </Toolbar>
          </AppBar>
          <List>
            <ListItem className="mt-4">
              <div className="block w-full overflow-x-auto">
                <TextField
                  onChange={(e) =>
                    setEditNlcPayload({
                      ...editNlcPayload,
                      helpText: e.target.value,
                    })
                  }
                  value={editNlcPayload.helpText}
                  fullWidth
                  id="outlined-basic"
                  label="Global Remark"
                  variant="outlined"
                />
              </div>
            </ListItem>
            <ListItem className="mt-4">
              <div className="block w-full overflow-x-auto">
                <FormControl sx={{ m: 2 }}>
                  <FormLabel id="demo-controlled-radio-buttons-group">
                    IsActive
                  </FormLabel>
                  <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group"
                    name="isActive"
                    value={editNlcPayload.isActive}
                    onChange={(e) =>
                      setEditNlcPayload({
                        ...editNlcPayload,
                        isActive: e.target.value,
                      })
                    }
                  >
                    <FormControlLabel
                      value={true}
                      control={<Radio />}
                      label="True"
                    />
                    <FormControlLabel
                      value={false}
                      control={<Radio />}
                      label="False"
                    />
                  </RadioGroup>
                </FormControl>
              </div>
            </ListItem>
            <ListItem className="mt-4">
              <div className="block w-full overflow-x-auto">
                <TextField
                  type="date"
                  onChange={(e) =>
                    setEditNlcPayload({
                      ...editNlcPayload,
                      periodFrom: e.target.value,
                    })
                  }
                  value={editNlcPayload.periodFrom}
                  fullWidth
                  id="outlined-basic"
                  label="Start Date"
                  variant="outlined"
                />
              </div>
            </ListItem>
            <ListItem className="mt-4">
              <div className="block w-full overflow-x-auto">
                <TextField
                  type="date"
                  onChange={(e) =>
                    setEditNlcPayload({
                      ...editNlcPayload,
                      periodTo: e.target.value,
                    })
                  }
                  value={editNlcPayload.periodTo}
                  fullWidth
                  id="outlined-basic"
                  label="End Date"
                  variant="outlined"
                />
              </div>
            </ListItem>
            <Divider />
            <Chip className="m-4" label="Product Price Details" />
            <ListItem className="mt-4 flex">
              <div className="block overflow-x-auto">
                <TextField
                  onChange={(e) =>
                    setEditNlcPayload({
                      ...editNlcPayload,
                      price: {
                        dp: e.target.value,
                        mop: editNlcPayload.price.mop,
                        mrp: editNlcPayload.price.mrp,
                      },
                    })
                  }
                  value={editNlcPayload.price?.dp}
                  fullWidth
                  id="outlined-basic"
                  label="DP"
                  variant="outlined"
                />
              </div>
              <div className="block overflow-x-auto ml-2">
                <TextField
                  onChange={(e) =>
                    setEditNlcPayload({
                      ...editNlcPayload,
                      price: {
                        dp: editNlcPayload.price.dp,
                        mop: e.target.value,
                        mrp: editNlcPayload.price.mrp,
                      },
                    })
                  }
                  value={editNlcPayload.price?.mop}
                  fullWidth
                  id="outlined-basic"
                  label="MOP"
                  variant="outlined"
                />
              </div>
              <div className="block overflow-x-auto ml-2">
                <TextField
                  onChange={(e) =>
                    setEditNlcPayload({
                      ...editNlcPayload,
                      price: {
                        dp: editNlcPayload.price.dp,
                        mop: editNlcPayload.price.mop,
                        mrp: e.target.value,
                      },
                    })
                  }
                  value={editNlcPayload.price?.mrp}
                  fullWidth
                  id="outlined-basic"
                  label="MRP"
                  variant="outlined"
                />
              </div>
            </ListItem>

            <Divider />
            <Chip className="m-4" label="Amazon Price Details" />
            <ListItem className="mt-4 flex">
              <div className="block overflow-x-auto">
                <TextField
                  onChange={(e) =>
                    setEditNlcPayload({
                      ...editNlcPayload,
                      amazon: {
                        mrp: e.target.value,
                        mop: editNlcPayload.amazon?.mop,
                        dp: editNlcPayload.amazon?.dp,
                        nlc: editNlcPayload.amazon?.nlc,
                        remark: editNlcPayload.amazon?.remark,
                      },
                    })
                  }
                  value={editNlcPayload.amazon?.mrp}
                  fullWidth
                  id="outlined-basic"
                  label="MRP"
                  variant="outlined"
                />
              </div>
              <div className="block overflow-x-auto ml-2">
                <TextField
                  onChange={(e) =>
                    setEditNlcPayload({
                      ...editNlcPayload,
                      amazon: {
                        mrp: editNlcPayload.amazon?.mrp,
                        mop: e.target.value,
                        dp: editNlcPayload.amazon?.dp,
                        nlc: editNlcPayload.amazon?.nlc,
                        remark: editNlcPayload.amazon?.remark,
                      },
                    })
                  }
                  value={editNlcPayload.amazon?.mop}
                  fullWidth
                  id="outlined-basic"
                  label="MOP"
                  variant="outlined"
                />
              </div>
              <div className="block overflow-x-auto ml-2">
                <TextField
                  onChange={(e) =>
                    setEditNlcPayload({
                      ...editNlcPayload,
                      amazon: {
                        mrp: editNlcPayload.amazon?.mrp,
                        mop: editNlcPayload.amazon?.mop,
                        dp: e.target.value,
                        nlc: editNlcPayload.amazon?.nlc,
                        remark: editNlcPayload.amazon?.remark,
                      },
                    })
                  }
                  value={editNlcPayload.amazon?.dp}
                  fullWidth
                  id="outlined-basic"
                  label="DP"
                  variant="outlined"
                />
              </div>
              <div className="block overflow-x-auto ml-2">
                <TextField
                  onChange={(e) =>
                    setEditNlcPayload({
                      ...editNlcPayload,
                      amazon: {
                        mrp: editNlcPayload.amazon?.mrp,
                        mop: editNlcPayload.amazon?.mop,
                        dp: editNlcPayload.amazon?.dp,
                        nlc: e.target.value,
                        remark: editNlcPayload.amazon?.remark,
                      },
                    })
                  }
                  value={editNlcPayload.amazon?.nlc}
                  fullWidth
                  id="outlined-basic"
                  label="NLC"
                  variant="outlined"
                />
              </div>
              <div className="block overflow-x-auto ml-2">
                <TextField
                  onChange={(e) =>
                    setEditNlcPayload({
                      ...editNlcPayload,
                      amazon: {
                        mrp: editNlcPayload.amazon?.mrp,
                        mop: editNlcPayload.amazon?.mop,
                        dp: editNlcPayload.amazon?.dp,
                        nlc: editNlcPayload.amazon?.nlc,
                        remark: e.target.value,
                      },
                    })
                  }
                  value={editNlcPayload.amazon?.remark}
                  fullWidth
                  id="outlined-basic"
                  label="Remark"
                  variant="outlined"
                />
              </div>
            </ListItem>

            <Divider />
            <Chip className="m-4" label="Flipkart Price Details" />
            <ListItem className="mt-4 flex">
              <div className="block overflow-x-auto">
                <TextField
                  onChange={(e) =>
                    setEditNlcPayload({
                      ...editNlcPayload,
                      flipkart: {
                        mrp: e.target.value,
                        mop: editNlcPayload.flipkart?.mop,
                        dp: editNlcPayload.flipkart?.dp,
                        nlc: editNlcPayload.flipkart?.nlc,
                        remark: editNlcPayload.flipkart?.remark,
                      },
                    })
                  }
                  value={editNlcPayload.flipkart?.mrp}
                  fullWidth
                  id="outlined-basic"
                  label="MRP"
                  variant="outlined"
                />
              </div>
              <div className="block overflow-x-auto ml-2">
                <TextField
                  onChange={(e) =>
                    setEditNlcPayload({
                      ...editNlcPayload,
                      flipkart: {
                        mrp: editNlcPayload.flipkart?.mrp,
                        mop: e.target.value,
                        dp: editNlcPayload.flipkart?.dp,
                        nlc: editNlcPayload.flipkart?.nlc,
                        remark: editNlcPayload.flipkart?.remark,
                      },
                    })
                  }
                  value={editNlcPayload.flipkart?.mop}
                  fullWidth
                  id="outlined-basic"
                  label="MOP"
                  variant="outlined"
                />
              </div>
              <div className="block overflow-x-auto ml-2">
                <TextField
                  onChange={(e) =>
                    setEditNlcPayload({
                      ...editNlcPayload,
                      flipkart: {
                        mrp: editNlcPayload.flipkart?.mrp,
                        mop: editNlcPayload.flipkart?.mop,
                        dp: e.target.value,
                        nlc: editNlcPayload.flipkart?.nlc,
                        remark: editNlcPayload.flipkart?.remark,
                      },
                    })
                  }
                  value={editNlcPayload.flipkart?.dp}
                  fullWidth
                  id="outlined-basic"
                  label="DP"
                  variant="outlined"
                />
              </div>
              <div className="block overflow-x-auto ml-2">
                <TextField
                  onChange={(e) =>
                    setEditNlcPayload({
                      ...editNlcPayload,
                      flipkart: {
                        mrp: editNlcPayload.flipkart?.mrp,
                        mop: editNlcPayload.flipkart?.mop,
                        dp: editNlcPayload.flipkart?.dp,
                        nlc: e.target.value,
                        remark: editNlcPayload.flipkart?.remark,
                      },
                    })
                  }
                  value={editNlcPayload.flipkart?.nlc}
                  fullWidth
                  id="outlined-basic"
                  label="NLC"
                  variant="outlined"
                />
              </div>
              <div className="block overflow-x-auto ml-2">
                <TextField
                  onChange={(e) =>
                    setEditNlcPayload({
                      ...editNlcPayload,
                      flipkart: {
                        mrp: editNlcPayload.flipkart?.mrp,
                        mop: editNlcPayload.flipkart?.mop,
                        dp: editNlcPayload.flipkart?.dp,
                        nlc: editNlcPayload.flipkart?.nlc,
                        remark: e.target.value,
                      },
                    })
                  }
                  value={editNlcPayload.flipkart?.remark}
                  fullWidth
                  id="outlined-basic"
                  label="Remark"
                  variant="outlined"
                />
              </div>
            </ListItem>

            <Divider />
            <Chip className="m-4" label="Scheme Details" />
            {editNlcPayload.schemes.map((scheme, index) => (
              <ListItem key={index} className="mt-4 flex">
                <div className="block overflow-x-auto min-w-200-px">
                  <TextField
                    disabled
                    value={scheme.name}
                    fullWidth
                    id="outlined-basic"
                    variant="outlined"
                  />
                </div>
                <div className="block ml-2">
                  <Badge
                    max={99999999}
                    badgeContent={scheme.amount}
                    color="primary"
                  >
                    <TextField
                      onChange={(e) => {
                        const array = editNlcPayload.schemes;
                        scheme.amount = e.target.value;
                        array[index] = scheme;
                        setEditNlcPayload({
                          ...editNlcPayload,
                          schemesDTO: array,
                          schemes: array,
                        });
                      }}
                      fullWidth
                      id="outlined-basic"
                      label="Amount"
                      variant="outlined"
                      value={scheme.amount}
                    />
                  </Badge>
                </div>
                <div className="block ml-2">
                  <TextField
                    onChange={(e) => {
                      const array = editNlcPayload.schemes;
                      scheme.remark = e.target.value;
                      array[index] = scheme;
                      setEditNlcPayload({
                        ...editNlcPayload,
                        schemesDTO: array,
                        schemes: array,
                      });
                    }}
                    fullWidth
                    multiline
                    id="outlined-basic"
                    label="Remark"
                    variant="outlined"
                    value={scheme.remark}
                  />
                </div>
                <div className="block ml-2">
                  <TextField
                    type="date"
                    onChange={(e) => {
                      const array = editNlcPayload.schemes;
                      scheme.periodFrom = e.target.value;
                      array[index] = scheme;
                      setEditNlcPayload({
                        ...editNlcPayload,
                        schemesDTO: array,
                        schemes: array,
                      });
                    }}
                    value={scheme.periodFrom}
                    fullWidth
                    id="outlined-basic"
                    label="Period From"
                    variant="outlined"
                  />
                </div>
                <div className="block ml-2">
                  <TextField
                    type="date"
                    onChange={(e) => {
                      const array = editNlcPayload.schemes;
                      scheme.periodTo = e.target.value;
                      array[index] = scheme;
                      setEditNlcPayload({
                        ...editNlcPayload,
                        schemes: array,
                        schemesDTO: array,
                      });
                    }}
                    value={scheme.periodTo}
                    fullWidth
                    id="outlined-basic"
                    label="Period To"
                    variant="outlined"
                  />
                </div>
              </ListItem>
            ))}
          </List>
          <DialogActions>
            <Button onClick={edit}>Save Changes</Button>
          </DialogActions>
        </Dialog>
      </div>
      <ToastContainer />
    </>
  );
}
