import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { createTheme, ThemeProvider } from "@mui/material";

import "@fortawesome/fontawesome-free/css/all.min.css";
import "assets/styles/tailwind.css";
import "assets/styles/index.css";

// layouts

import Admin from "../src/layouts/Admin.js";
import Login from "views/auth/Login.js";
import Nlc from "../src/views/admin/Nlc.js";
import Brands from "../src/views/admin/Brands.js";
import Products from "../src/views/admin/Products.js";
import Schemes from "../src/views/admin/Schemes.js";
import PriceDrop from "views/admin/PriceDrop.js";
import States from "views/admin/States.js";
import District from "views/admin/District.js";
import Groups from "views/admin/Groups.js";
import Subscriptions from "views/admin/Subscriptions.js";
import Users from "views/admin/Users.js";
import Posts from "views/admin/Posts.js";
import TOTP from "views/admin/TOTP.js";
import { ToastContainer } from "react-toastify";
import Whatsapp from "views/admin/Whatsapp.js";
import NotificationTopics from "views/admin/NotificationTopics.js";
import Notifications from "views/admin/Notifications.js";
import BulkSubscription from "views/admin/BulkSubscription.js";
import Files from "views/admin/Files.js";
import Analytics from "views/admin/Analytics.js";
import Share from "views/admin/Share.js";
import MydukanSubscriptions from "views/admin/MydukanSubscriptions.js";
import NlcV2 from "views/admin/NlcV2.js";
import { Provider } from "react-redux";
import store from "./redux/store.js";
import Complaints from "views/admin/Complaint.js";

// views without layouts
const theme = createTheme({
  typography: {
    fontFamily: ["Montserrat"].join(","),
  },
});
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Provider store={store}>
    <ThemeProvider theme={theme}>
      <ToastContainer />
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="admin" element={<Admin />}>
            <Route path="nlc" element={<Nlc />} />
            <Route path="nlc-v2" element={<NlcV2 />} />
            <Route path="brands" element={<Brands />} />
            <Route path="totp" element={<TOTP />} />
            <Route path="products" element={<Products />} />
            <Route path="schemes" element={<Schemes />} />
            <Route path="price-drop" element={<PriceDrop />} />
            <Route path="states" element={<States />} />
            <Route path="district" element={<District />} />

            <Route path="complaint" element={<Complaints />} />
            <Route path="groups" element={<Groups />} />
            <Route path="subscriptions" element={<Subscriptions />} />
            <Route path="bulkSubscription" element={<BulkSubscription />} />
            <Route path="users" element={<Users />} />
            <Route path="posts" element={<Posts />} />
            <Route path="files" element={<Files />} />
            <Route path="whatsapp" element={<Whatsapp />} />
            <Route
              path="notification-topics"
              element={<NotificationTopics />}
            />
            <Route path="share" element={<Share />} />
            <Route path="notifications" element={<Notifications />} />
            <Route path="analytics" element={<Analytics />} />

            <Route
              path="mydukanSubscriptions"
              element={<MydukanSubscriptions />}
            />
          </Route>
        </Routes>
      </BrowserRouter>
    </ThemeProvider>
  </Provider>
);
