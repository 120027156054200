import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { FilesApi } from "apis/FilesApi";
import {
  FormControl,
  Input,
  InputLabel,
  MenuItem,
  Select,
  Tooltip,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { setLoadingPanel } from "../../redux/features/loading";
import CircularIndeterminateModal from "../../utils/Loading";

export default function Files() {
  const color = "light";
  const dispatch = useDispatch();
  const isLoading = useSelector((state) => state.loadingReducer.isLoading);
  const [files, setFiles] = useState([]);
  const [usage, setUsage] = useState({
    bandwidthBytes: 0,
    mediaLibraryStorageBytes: 0,
  });
  const [filter, setFilter] = useState({
    category: "WHATSAPP_POST",
    skip: 0,
    page: 0,
    limit: 48,
  });

  const toBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = reject;
    });

  useEffect(() => {
    const handleGetFiles = async (filter) => {
      try {
        dispatch(setLoadingPanel(true));
        const filesResp = await FilesApi.GetFiles(filter);
        window.scrollTo({ top: 0, behavior: "smooth" });
        if (!filesResp.data.data.length) {
          toast("This is last page.", {
            type: "warning",
            position: toast.POSITION.TOP_CENTER,
            autoClose: 1000,
          });
          setFilter({
            ...filter,
            page: filter.page - 1,
            skip: (filter.page - 1) * filter.limit,
          });
        }
        setFiles(filesResp.data.data);
        setUsage(filesResp.data?.extras?.usage);
        dispatch(setLoadingPanel(false));
      } catch (err) {
        dispatch(setLoadingPanel(false));
        toast("Error....", {
          type: "error",
          position: toast.POSITION.TOP_CENTER,
          autoClose: 500,
        });
      }
    };
    handleGetFiles(filter);
  }, [filter]);

  const handleDeleteFiles = async (id, category) => {
    try {
      toast("Deleting....", {
        type: "success",
        position: toast.POSITION.TOP_CENTER,
        autoClose: 500,
      });
      await FilesApi.DeleteFile(id, { category });
      toast("Deleted.", {
        type: "success",
        position: toast.POSITION.TOP_CENTER,
        autoClose: 500,
      });
      setFilter({
        ...filter,
        page: 0,
        skip: 0 * filter.limit,
      });
    } catch (err) {
      toast(err?.response?.data?.message, {
        type: "error",
        position: toast.POSITION.TOP_CENTER,
        autoClose: 1000,
      });
    }
  };

  const bytesToSize = (bytes, seperator = "") => {
    const sizes = ["Bytes", "KB", "MB", "GB", "TB"];
    if (bytes === 0) return "n/a";
    const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)), 10);
    if (i === 0) return `${bytes}${seperator}${sizes[i]}`;
    return `${(bytes / 1024 ** i).toFixed(1)}${seperator}${sizes[i]}`;
  };
  const handleUploadFile = async (file, category) => {
    toast("Uploading....", {
      type: "success",
      position: toast.POSITION.TOP_CENTER,
      autoClose: 500,
    });
    try {
      await FilesApi.UploadFiles({
        category,
        name: file.name,
        content: await toBase64(file),
      });
      setFilter({
        ...filter,
        page: 0,
        skip: 0 * filter.limit,
      });
      toast("Uploaded.", {
        type: "success",
        position: toast.POSITION.TOP_CENTER,
        autoClose: 500,
      });
    } catch (error) {
      toast("Error in uploading..... ", {
        type: "error",
        position: toast.POSITION.TOP_CENTER,
        autoClose: 500,
      });
    }
  };
  //&& !files.length

  return (
    <>
      <CircularIndeterminateModal open={isLoading} />
      <div
        className={
          "relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded " +
          (color === "light" ? "bg-white" : "bg-lightBlue-900 text-white")
        }
      >
        <div className="flex flex-col items-center w-full p-4 md:p-8 min-h-[85vh]">
          <Typography sx={{ paddingY: 2 }} variant="h4" gutterBottom>
            Files Manager
          </Typography>

          <div className="flex flex-col items-center w-[90%] justify-evenly h-auto">
            <FormControl fullWidth>
              <InputLabel
                id="demo-simple-select-label"
                className="text-sm md:text-base"
              >
                Category
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={filter.category}
                label={
                  <span className="text-sm md:text-base font-medium">
                    Category
                  </span>
                }
                sx={{
                  fontSize: {
                    xs: "12px",
                    sm: "13px",
                    md: "13px",
                    lg: "16px",
                  },
                }}
                className="min-h-[40px] md:min-h-[52px]"
                onChange={(e) => {
                  setFilter({ ...filter, category: e.target.value });
                }}
              >
                {[
                  "WHATSAPP_POST",
                  "WHATSAPP_POST_1",
                  "WHATSAPP_POST_2",
                  "SAMACHAR_POST",
                  "SCHEMES",
                ].map((b, i) => (
                  <MenuItem
                    key={i}
                    value={b}
                    sx={{
                      fontSize: {
                        xs: "12px",
                        sm: "13px",
                        md: "13px",
                        lg: "16px",
                      },
                    }}
                  >
                    {b}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <div className="flex md:flex-row flex-col w-full my-4 justify-between">
              {usage && (
                <div className="flex flex-col justify-center text-sm text-center">
                  <p>Bandwidth: {bytesToSize(usage?.bandwidthBytes)}</p>
                  <p>Storgae: {bytesToSize(usage?.mediaLibraryStorageBytes)}</p>
                </div>
              )}

              <div className="flex justify-evenly">
                <Input
                  type="file"
                  onChange={async (e) =>
                    handleUploadFile(e.target.files[0], filter.category)
                  }
                  style={{ display: "none" }}
                  id="file-input"
                />
                <div className="flex justify-center w-full my-3">
                  <label htmlFor="file-input" className="w-full">
                    <Button
                      variant="contained"
                      component="span"
                      sx={{
                        height: { xs: "40px", md: "56px" },
                        fontSize: {
                          xs: "12px",
                          sm: "13px",
                          md: "14px",
                          lg: "16px",
                        },
                        maxWidth: { xs: "100%", md: "200px" },
                        width: "100%",
                      }}
                    >
                      Upload File
                    </Button>
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4 w-full  justify-evenly flex-wrap my-10 h-auto ">
            {files.map((f, i) => (
              <div
                key={i}
                className="flex flex-col  justify-evenly items-center hover:scale-150 bg-white  shadow-lg p-2 my-2 mx-1  rounded-lg overflow-hidden   "
              >
                <img
                  loading="lazy"
                  className="w-32 aspect-square object-contain border-2"
                  src={f.thumbnail}
                  alt="post"
                />
                <span className="text-[0.6rem] text-center p-1">
                  {f.mime.split("/")[1]}, {(f.size / 1024).toFixed(1)} KB
                  <br />
                  {f.height} X {f.width}
                </span>

                <span className="text-[0.65rem] p-1 text-center uppercase">
                  {f?.tags?.join(" ")}
                </span>
                <div className="flex">
                  <Button
                    onClick={(e) => {
                      navigator.clipboard.writeText(f.url);
                      toast("Copied.", {
                        type: "success",
                        position: toast.POSITION.TOP_CENTER,
                        autoClose: 500,
                      });
                    }}
                    type="text"
                    size="small"
                  >
                    COPY
                  </Button>
                </div>
                <div className="flex">
                  <Tooltip title={"Double click to delete."} arrow>
                    <Button
                      onDoubleClick={async (e) => {
                        await handleDeleteFiles(f.fileId, filter.category);
                      }}
                      color="error"
                      type="text"
                      size="small"
                    >
                      DELETE
                    </Button>
                  </Tooltip>
                </div>
              </div>
            ))}
          </div>
          <div className="flex flex-col  items-center w-[90%] justify-evenly h-auto">
            <div className="flex w-full my-2 justify-between">
              <div className="flex w-[95%] justify-evenly ">
                <div
                  className="w-[25%] flex justify-center cursor-pointer items-center border-blue-500 border-2 p-1 hover:bg-blue-100 shadow-lg rounded-lg"
                  disabled={filter.page <= 0}
                  onClick={() => {
                    if (filter.page > 0) {
                      setFilter({
                        ...filter,
                        page: filter.page - 1,
                        skip: (filter.page - 1) * filter.limit,
                      });
                    }
                  }}
                >
                  <span className="uppercase  text-blue-500">Previous</span>
                </div>
                <div
                  className="w-[25%] flex justify-center cursor-pointer items-center border-blue-500 border-2 p-1 hover:bg-blue-100 shadow-lg rounded-lg"
                  onClick={() => {
                    setFilter({
                      ...filter,
                      page: filter.page + 1,
                      skip: (filter.page + 1) * filter.limit,
                    });
                  }}
                >
                  <span className="uppercase  text-blue-500">Next</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
