import React, { useState } from "react";
import {
  Button,
  createSvgIcon,
  Input,
  TextField,
  Typography,
} from "@mui/material";
import * as XLSX from "xlsx";
import TableHeader from "components/Table/TableHeader";
import TableElement from "components/Table/TableElement";

const Whatsapp = () => {
  const [userData, setUserData] = useState([]);
  const [message, setMessage] = useState("");

  const handleFileChange = (event) => {
    const file = event.target.files[0];

    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const data = new Uint8Array(e.target.result);
        const workbook = XLSX.read(data, { type: "array" });
        const sheetName = workbook.SheetNames[0];
        const sheet = workbook.Sheets[sheetName];
        // Extract headers from the first row
        const headers = XLSX.utils.sheet_to_json(sheet, {
          header: 1,
          range: 0,
        })[0];

        // Extract data starting from the second row
        const jsonData = XLSX.utils.sheet_to_json(sheet, {
          header: headers,
          range: 1,
        });

        setUserData(jsonData);
      };

      reader.readAsArrayBuffer(file);
    } else {
      setUserData([]);
    }
  };
  const PlusIcon = createSvgIcon(
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth={1.5}
      stroke="currentColor"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M12 4.5v15m7.5-7.5h-15"
      />
    </svg>,
    "Plus"
  );
  return (
    <div
      className={
        "relative flex flex-col justify-start items-center p-2 md:p-4 break-words w-full m-h-[80dvh] shadow-lg rounded bg-white"
      }
    >
      <Typography
        sx={{ paddingY: 2, textAlign: "center" }}
        variant="h4"
        gutterBottom
      >
        Whatsapp Message Sender
      </Typography>
      <div className="md:w-1/2 w-full px-1">
        <TextField
          sx={{ marginBottom: "15px" }}
          fullWidth
          multiline={true}
          variant="standard"
          label={
            <span className="text-sm md:text-base font-medium">Message</span>
          }
          InputProps={{
            sx: {
              fontSize: {
                xs: "12px",
                sm: "13px",
                md: "13px",
                lg: "16px",
              },
            },
          }}
          value={message}
          onChange={(e) => setMessage(e.target.value)}
        />
      </div>
      <Input
        key={userData.length}
        type="file"
        accept=".xlsx, .xls"
        onChange={handleFileChange}
        style={{ display: "none" }}
        id="file-input"
      />
      <div className="flex flex-col justify-center items-center">
        <div className=" flex justify-center w-full md:w-1/2 mb-4">
          <label htmlFor="file-input">
            <Button
              component="span"
              fullWidth
              variant="contained"
              color="primary"
              sx={{
                height: { xs: "40px", md: "56px" },
                fontSize: {
                  xs: "12px",
                  sm: "13px",
                  md: "14px",
                  lg: "16px",
                },
              }}
              startIcon={<PlusIcon className="h-4 w-4 md:h-5 md:w-5" />}
            >
              Upload Excel File
            </Button>
          </label>
        </div>
        <span className="capitalize text-center">
          upload a excel file containg coloum "name" and "mobile" in small case
        </span>
        <Button
          sx={{ marginY: 2 }}
          type="reset"
          variant="contained"
          onClick={() => {
            setUserData([]);
            setMessage("");
          }}
        >
          Reset
        </Button>
      </div>

      <div className="w-full py-3 px-4 lg:p-8 overflow-x-auto">
        <table className=" py-4 items-center w-full bg-transparent border-collapse">
          <thead className="bg-gray-300">
            <tr className="divide-x  divide-gray-200 ">
              <TableHeader heading="" />
              <TableHeader heading="Name" />
              <TableHeader heading="Mobile " />
              <TableHeader heading="Send" />
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-200 bg-white">
            {userData &&
              userData.map((e, i) => {
                return (
                  <tr
                    key={i + 1}
                    className="divide-x divide-gray-200 hover:bg-slate-100"
                  >
                    <TableElement value={i + 1} />
                    <TableElement value={e.name} />
                    <TableElement value={e.mobile} />
                    <TableElement
                      value={
                        <a
                          href={`https://api.whatsapp.com/send?phone=+91${e.mobile}&text=${message}`}
                          target="_blank"
                          rel="noreferrer"
                        >
                          <span className="text-sm text-blue-400 hover:text-blue-700">
                            SEND
                          </span>
                        </a>
                      }
                      type="element"
                    />
                  </tr>
                );
              })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Whatsapp;
