import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
} from "@mui/material";
import React, { useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { toast } from "react-toastify";
import { ComplaintsApi } from "apis/Complaint";

export default function ComplaintAddDialog({
  ResolveComplaint,
  setResolveComplaint,
  id,
  fetchComplaints,
}) {
  const [resolveData, setResolveData] = useState({
    resolution: "",
  });

  //   try {
  //     const { data } = await ComplaintsApi.GetComplaintsById(id);
  //     setResolveData(data.data);
  //   } catch (err) {
  //     const status = err?.response?.status;
  //     const message =
  //       err?.response?.data?.message || "An unexpected error occurred";
  //     toast(`${status ? `${status} ` : ""}${message}`, {
  //       type: "error",
  //       position: toast.POSITION.TOP_CENTER,
  //       autoClose: 1000,
  //       hideProgressBar: true,
  //       closeOnClick: true,
  //       pauseOnHover: true,
  //       draggable: true,
  //       style: {
  //         maxWidth: "90vw",
  //         width: "auto",
  //         margin: "5px auto",
  //         padding: "5px",
  //         fontSize: "14px",
  //       },
  //       closeButton: (
  //         <div className="flex justify-end">
  //           <button className="text-gray-500 hover:text-gray-800 focus:outline">
  //             <CloseIcon className="w-7 h-7" />
  //           </button>
  //         </div>
  //       ),
  //     });
  //     if (status === 401) {
  //       localStorage.clear();
  //       navigate("/");
  //     }
  //   }
  // };

  const validateFunction = (payload) => {
    if (!payload.resolution || payload.resolution.trim() === "")
      return "Resolution is required.";
    return null;
  };

  const handleSubmitForm = async () => {
    try {
      const validationError = validateFunction(resolveData);
      if (validationError) {
        toast(validationError, {
          type: "warning",
          position: toast.POSITION.TOP_CENTER,
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          style: {
            maxWidth: "90vw",
            width: "auto",
            margin: "5px auto",
            padding: "5px",
            fontSize: "14px",
          },
          closeButton: (
            <div className="flex justify-end">
              <button className="text-gray-500 hover:text-gray-800 focus:outline">
                <CloseIcon className="w-7 h-7" />
              </button>
            </div>
          ),
        });
        return;
      }

      await ComplaintsApi.ResolveComplaint(id, resolveData.resolution);
      toast("Complaint resolved successfully.", {
        type: "success",
        position: toast.POSITION.TOP_CENTER,
        autoClose: 1000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        style: {
          maxWidth: "90vw",
          width: "auto",
          margin: "5px auto",
          padding: "5px",
          fontSize: "14px",
        },
        closeButton: (
          <div className="flex justify-end">
            <button className="text-gray-500 hover:text-gray-800 focus:outline">
              <CloseIcon className="w-7 h-7" />
            </button>
          </div>
        ),
      });
      setResolveComplaint(false);
      fetchComplaints();
      setResolveData({
        resolution: "",
      });
    } catch (err) {
      const status = err?.response?.status;
      const message =
        err?.response?.data?.message || "An unexpected error occurred";
      toast(`${status ? `${status} ` : ""}${message}`, {
        type: "error",
        position: toast.POSITION.TOP_CENTER,
        autoClose: 1000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        style: {
          maxWidth: "90vw",
          width: "auto",
          margin: "5px auto",
          padding: "5px",
          fontSize: "14px",
        },
        closeButton: (
          <div className="flex justify-end">
            <button className="text-gray-500 hover:text-gray-800 focus:outline">
              <CloseIcon className="w-7 h-7" />
            </button>
          </div>
        ),
      });
      //   if (status === 401) {
      //     localStorage.clear();
      //     navigate("/");
      //   }
    }
  };

  //   useEffect(() => {
  //     if (id) fetchComplaintsResolve();
  //   }, [id]);

  return (
    <div>
      <Dialog
        open={ResolveComplaint}
        onClose={() => setResolveComplaint(false)}
      >
        <DialogTitle>Resolve Complaint</DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                fullWidth
                name="resolution"
                multiline
                rows={5}
                label="Resolution"
                variant="standard"
                value={resolveData.resolution}
                onChange={(e) =>
                  setResolveData((prev) => ({
                    ...prev,
                    resolution: e.target.value,
                  }))
                }
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleSubmitForm} color="primary">
            Resolve
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
