import React, { useState } from "react";
import TextField from "@mui/material/TextField";
import { Label } from "@mui/icons-material";
import { Badge } from "@mui/material";

const AddPriceTable = ({ renderPrice, setRenderPrice }) => {
  const color = "light";
  const [amount, setAmount] = useState("");

  const dpChange = (e, model) => {
    model.dp = e.target.value;
  };

  const mopChange = (e, model) => {
    model.mrp = e.target.value;
  };

  const mrpChange = (e, model) => {
    model.mop = e.target.value;
  };

  return (
    <div
      className={
        "relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded " +
        (color === "light" ? "bg-white" : "bg-lightBlue-900 text-white")
      }
    >
      <div className="block w-full overflow-x-auto p-8">
        <table className="items-center w-full bg-transparent border-collapse">
          <thead>
            <tr>
              <th
                className={
                  "px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left " +
                  (color === "light"
                    ? "bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                    : "bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700")
                }
              >
                Name
              </th>
              <th
                className={
                  "px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left " +
                  (color === "light"
                    ? "bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                    : "bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700")
                }
              >
                DP
              </th>
              <th
                className={
                  "px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left " +
                  (color === "light"
                    ? "bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                    : "bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700")
                }
              >
                MOP
              </th>
              <th
                className={
                  "px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left " +
                  (color === "light"
                    ? "bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                    : "bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700")
                }
              >
                MRP
              </th>
              <th
                className={
                  "px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left " +
                  (color === "light"
                    ? "bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                    : "bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700")
                }
              >
                Start Date
              </th>
              <th
                className={
                  "px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left " +
                  (color === "light"
                    ? "bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                    : "bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700")
                }
              >
                End Date
              </th>
            </tr>
          </thead>
          <tbody>
            {renderPrice.map((price, i) => (
              <tr key={i}>
                <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                  {price.name}
                </td>
                <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                  <Badge max={99999999} badgeContent={price.dp} color="primary">
                    <TextField
                      onChange={(e) => dpChange(e, price)}
                      size="small"
                      id="outlined-basic"
                      variant="outlined"
                    />
                  </Badge>
                </td>
                <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                  <Badge
                    max={99999999}
                    badgeContent={price.mop}
                    color="primary"
                  >
                    <TextField
                      onChange={(e) => mopChange(e, price)}
                      size="small"
                      id="outlined-basic"
                      variant="outlined"
                    />
                  </Badge>
                </td>
                <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                  <Badge
                    max={99999999}
                    badgeContent={price.mrp}
                    color="primary"
                  >
                    <TextField
                      onChange={(e) => mrpChange(e, price)}
                      size="small"
                      id="outlined-basic"
                      variant="outlined"
                    />
                  </Badge>
                </td>
                <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                  <TextField
                    type="date"
                    value={price.startDate}
                    size="small"
                    id="outlined-basic"
                    variant="outlined"
                  />
                </td>
                <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                  <TextField
                    type="date"
                    value={price.endDate}
                    size="small"
                    id="outlined-basic"
                    variant="outlined"
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default AddPriceTable;
