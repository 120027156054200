import React, { useEffect, useState } from "react";
import { UsersApi } from "apis/UsersApi";
import { toast } from "react-toastify";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Grid from "@mui/material/Unstable_Grid2";
import { useNavigate } from "react-router";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import { StatesApi } from "apis/StatesApi";
import { UsersSubscriptionsApi } from "apis/UsersSubscriptionsApi";
import { DistrictApi } from "apis/DistrictApi";
import Chip from "@mui/material/Chip";
import MatAutoComplete from "components/MatAutoComplete/MatAutoComplete";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import ExcelExport from "components/ExcelExport/ExcelExport";
import TableHeader from "components/Table/TableHeader";
import TableElement from "components/Table/TableElement";
import { formatDateTimeComponent } from "utils";
import { MasterApi } from "apis/MasterApi";
import { Divider } from "@mui/material";
import { formatDate } from "utils";
import { FilesApi } from "apis/FilesApi";
import InputAdornment from "@mui/material/InputAdornment";
import OutlinedInput from "@mui/material/OutlinedInput";
import Tooltip from "@mui/material/Tooltip";
import { BrandsApi } from "apis/BrandsApi";
import { useDispatch, useSelector } from "react-redux";
import { setLoadingPanel } from "../../redux/features/loading";
import CircularIndeterminateModal from "../../utils/Loading";

export default function Users() {
  const dispatch = useDispatch();
  const isLoading = useSelector((state) => state.loadingReducer.isLoading);
  const navigate = useNavigate();
  const color = "light";
  const [users, setUsers] = useState([]);

  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState();
  const [totalElements, setTotalElements] = useState("");

  const [verifyUserTick, setVerifyUserTick] = useState("null");

  const today = new Date();
  let fileName = `users_data_${today.getDate()}${
    today.getMonth() + 1
  }${today.getFullYear()}_${today.getHours()}${today.getMinutes()}`;

  const [filterPayload, setFilterPayload] = useState({
    isApproved: null,
    isDeleted: null,
    isLocked: null,
    isVerified: null,
    isJoinedWhatsapp: null,
    isGSTVerified: null,
    state: null,
    district: null,
    pincode: null,
    sortBy: null,
    direction: null,
    mobile: null,
    roles: null,
    size: 50,
  });

  const [excelData, setExcelData] = useState([]);
  const getUsers = (page, size) => {
    UsersApi.GetUsersListByFilter({
      mobile: filterPayload.mobile,
      isApproved: filterPayload.isApproved,
      isDeleted: filterPayload.isDeleted,
      isLocked: filterPayload.isLocked,
      isVerified: filterPayload.isVerified,
      state: filterPayload.state,
      isJoinedWhatsapp: filterPayload.isJoinedWhatsapp,
      isGSTVerified: filterPayload.isGSTVerified,
      district: filterPayload.district,
      pincode: filterPayload.pincode,
      direction: filterPayload.direction,
      sortBy: filterPayload.sortBy,
      roles: filterPayload.roles,
      page: page,
      size: size,
    })
      .then((res) => {
        setUsers(res.data.data);
        if (filterPayload.mobile != null) {
          if (res.data.pages === 0) {
            setVerifyUserTick("notFound");
          } else if (res.data.pages === 1) {
            setVerifyUserTick("found");
          }
        }
        setTotalPages(res.data.pages);
        setTotalElements(res.data.totalElements);
        const excelDataArray = res.data.data.map((m) => ({
          name: m?.name,
          mobile: m?.mobile,
          email: m?.email,
          roles: m?.roles?.map((role) => role?.name).join(","),
          lastLoginOn: m?.lastLoginOn,
          isSubscribed: m?.isApproved,
          isMyDukanVerified: m?.isVerified,
          businessName: m?.business?.name,
          businessEmail: m?.business?.email,
          gstNumber: m?.business?.gstNumber,
          city: m?.business?.address?.city,
          state: m?.business?.address?.state?.name,
          country: m?.business?.address?.country,
          pincode: m?.business?.address?.pincode,
        }));
        setExcelData(excelDataArray);
      })
      .catch((err) => {
        toast(err?.response?.data?.message, {
          type: "error",
          position: toast.POSITION.TOP_CENTER,
          autoClose: 1000,
        });
      });
  };
  const [states, setState] = useState([]);
  const [district, setDistrict] = useState([]);
  const [pincode, setPincode] = useState(null);
  const [mobile, setMobile] = useState(null);
  const [selectedState, setSelectedState] = useState({
    name: "--Select State--",
    id: null,
  });
  const [selectedDistrict, setSelectedDistrict] = useState({
    name: "--Select District--",
    id: null,
    state: {
      name: "",
      id: null,
    },
  });

  const getStates = () => {
    dispatch(setLoadingPanel(true));
    StatesApi.GetStatesListByFilter()
      .then((res) => {
        setState(res.data.data);
        dispatch(setLoadingPanel(false));
      })
      .catch((err) => {
        dispatch(setLoadingPanel(false));
        toast(err?.response?.data?.message, {
          type: "error",
          position: toast.POSITION.TOP_CENTER,
          autoClose: 1000,
        });
        if (err.status === 401) {
          localStorage.clear();
          navigate("/");
        }
      });
  };

  const getDistricts = () => {
    dispatch(setLoadingPanel(true));
    DistrictApi.GetDistrictsListByFilter({
      stateId: filterPayload.state,
    })
      .then((res) => {
        setDistrict(res.data.data);
        dispatch(setLoadingPanel(false));
      })
      .catch((err) => {
        dispatch(setLoadingPanel(false));
        toast(err?.response?.data?.message, {
          type: "error",
          position: toast.POSITION.TOP_CENTER,
          autoClose: 1000,
        });
        if (err.status === 401) {
          localStorage.clear();
          navigate("/");
        }
      });
  };

  const [userSize, setUserSize] = useState(50);
  const [openExcelDialog, setOpenExcelDialog] = React.useState(false);
  const handleClickOpenExcelDialog = () => {
    setOpenExcelDialog(true);
  };

  const handleCloseExcelDialog = () => {
    setOpenExcelDialog(false);
  };

  const clearSearch = () => {
    setFilterPayload({
      isApproved: null,
      isDeleted: null,
      isLocked: null,
      isVerified: null,
      isJoinedWhatsapp: null,
      state: null,
      district: null,
      pincode: null,
      sortBy: null,
      direction: null,
      mobile: null,
      roles: null,
      size: 25,
    });
    setVerifyUserTick("null");
    setPincode("");
    setMobile("");
    setDistrict([]);

    setCurrentPage(1);
  };

  const handlePageChange = (event, page) => {
    setCurrentPage(page);
  };
  const [roles, setRoles] = useState([]);

  const getRoles = () => {
    dispatch(setLoadingPanel(true));
    MasterApi.GetRoles()
      .then((res) => {
        setRoles(res.data.data);
        dispatch(setLoadingPanel(false));
      })
      .catch((err) => {
        dispatch(setLoadingPanel(false));
        toast(err?.response?.data?.message, {
          type: "error",
          position: toast.POSITION.TOP_CENTER,
          autoClose: 1000,
        });
        if (err.response.status === 401) {
          localStorage.clear();
          navigate("/");
        }
      });
  };

  useEffect(() => {
    getUsers(currentPage - 1, filterPayload.size);
  }, [filterPayload, currentPage]);

  useEffect(() => {
    getDistricts();
  }, [filterPayload?.state]);

  useEffect(() => {
    getRoles();
    getUsers(currentPage - 1, filterPayload.size);
    getStates();
  }, []);
  return (
    <>
      <CircularIndeterminateModal open={isLoading && !states.length} />
      <div
        className={
          "relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded " +
          (color === "light" ? "bg-white" : "bg-lightBlue-900 text-white")
        }
      >
        <div className="block w-full overflow-x-auto p-8">
          <form>
            <Grid container spacing={2}>
              <Grid item xs={12} md={4}>
                <FormControl sx={{ width: "100%" }} variant="outlined">
                  <InputLabel
                    htmlFor="outlined-adornment-password"
                    sx={{
                      fontSize: {
                        xs: "12px",
                        sm: "13x",
                        md: "13px",
                        lg: "16px",
                      },
                    }}
                  >
                    Search by Mobile Number
                  </InputLabel>
                  <OutlinedInput
                    id="outlined-adornment-weight"
                    label="Search by Mobile Number"
                    endAdornment={
                      <InputAdornment position="end">
                        {verifyUserTick === "found" && (
                          <i
                            title="User found"
                            className="fa-solid fa-circle-check fa-lg"
                            style={{ color: "#2ef32b" }}
                          ></i>
                        )}
                        {verifyUserTick === "notFound" && (
                          <i
                            title="User not found"
                            className="fa-regular fa-circle-xmark fa-lg"
                            style={{ color: "#ec0909" }}
                          ></i>
                        )}
                        {verifyUserTick === "null" && <></>}
                      </InputAdornment>
                    }
                    aria-describedby="outlined-weight-helper-text"
                    onChange={(e) => {
                      setMobile(e.target.value);
                      if (e.target.value?.length === 10) {
                        setFilterPayload({
                          ...filterPayload,
                          mobile: e.target.value,
                        });
                      } else if (e.target.value?.length === 0) {
                        setFilterPayload({
                          ...filterPayload,
                          mobile: null,
                        });
                        setVerifyUserTick("null");
                      }
                    }}
                    value={mobile}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} md={4}>
                <FormControl fullWidth>
                  <InputLabel
                    size="small"
                    id="demo-simple-select-label"
                    sx={{
                      fontSize: {
                        xs: "12px",
                        sm: "13x",
                        md: "13px",
                        lg: "16px",
                      },
                    }}
                  >
                    Sort By
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={filterPayload?.sortBy}
                    label="Sort By"
                    onChange={(e) =>
                      setFilterPayload({
                        ...filterPayload,
                        sortBy: e.target.value,
                      })
                    }
                    sx={{
                      fontSize: {
                        xs: "12px",
                        sm: "13x",
                        md: "13px",
                        lg: "16px",
                      },
                    }}
                  >
                    <MenuItem
                      sx={{
                        fontSize: {
                          xs: "12px",
                          sm: "13x",
                          md: "13px",
                          lg: "16px",
                        },
                      }}
                      value={null}
                    >
                      --Sort By--
                    </MenuItem>
                    <MenuItem
                      sx={{
                        fontSize: {
                          xs: "12px",
                          sm: "13x",
                          md: "13px",
                          lg: "16px",
                        },
                      }}
                      value={"name"}
                    >
                      Name
                    </MenuItem>
                    <MenuItem
                      sx={{
                        fontSize: {
                          xs: "12px",
                          sm: "13x",
                          md: "13px",
                          lg: "16px",
                        },
                      }}
                      value={"createdOn"}
                    >
                      Created On
                    </MenuItem>
                    <MenuItem
                      sx={{
                        fontSize: {
                          xs: "12px",
                          sm: "13x",
                          md: "13px",
                          lg: "16px",
                        },
                      }}
                      value={"lastLoginOn"}
                    >
                      Last Login On
                    </MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={4}>
                <FormControl fullWidth>
                  <InputLabel
                    size="small"
                    id="demo-simple-select-label"
                    sx={{
                      fontSize: {
                        xs: "12px",
                        sm: "13x",
                        md: "13px",
                        lg: "16px",
                      },
                    }}
                  >
                    Direction
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={filterPayload?.direction}
                    label="Direction"
                    onChange={(e) =>
                      setFilterPayload({
                        ...filterPayload,
                        direction: e.target.value,
                      })
                    }
                    sx={{
                      fontSize: {
                        xs: "12px",
                        sm: "16px",
                        md: "16px",
                        lg: "16px",
                      },
                    }}
                  >
                    <MenuItem
                      value={null}
                      sx={{
                        fontSize: {
                          xs: "12px",
                          sm: "16px",
                          md: "16px",
                          lg: "16px",
                        },
                      }}
                    >
                      --Select Direction--
                    </MenuItem>
                    <MenuItem
                      sx={{
                        fontSize: {
                          xs: "12px",
                          sm: "16px",
                          md: "16px",
                          lg: "16px",
                        },
                      }}
                      value={"ASC"}
                    >
                      Ascending: Low to High
                    </MenuItem>
                    <MenuItem
                      sx={{
                        fontSize: {
                          xs: "12px",
                          sm: "16px",
                          md: "16px",
                          lg: "16px",
                        },
                      }}
                      value={"DESC"}
                    >
                      Descending: High to Low
                    </MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </form>
          <Grid container spacing={2} sx={{ marginTop: "5px" }}>
            <Grid
              item
              xs={12}
              md={3}
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Chip label="Address Filter" />
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              md={3}
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
              }}
            >
              <MatAutoComplete
                label={
                  <span className="text-xs sm:text-sm md:text-base lg:text-lg font-medium">
                    Select State
                  </span>
                }
                getData={getStates}
                options={states}
                key={selectedState}
                setOptions={setState}
                setValue={(value) => {
                  setSelectedState(value);
                  setFilterPayload({ ...filterPayload, state: value?.id });
                }}
                multiple={false}
                sx={{
                  fontSize: {
                    xs: "12px",
                    sm: "13x",
                    md: "13px",
                    lg: "16px",
                  },
                }}
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              md={3}
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
              }}
            >
              <MatAutoComplete
                label={
                  <span className="text-xs sm:text-sm md:text-base lg:text-lg font-medium">
                    Select District
                  </span>
                }
                getData={getDistricts}
                options={district}
                key={selectedDistrict}
                optionLabel={district}
                setOptions={setDistrict}
                setValue={(value) => {
                  setSelectedDistrict(value);
                  setFilterPayload({ ...filterPayload, district: value?.id });
                }}
                multiple={false}
                sx={{
                  fontSize: {
                    xs: "12px",
                    sm: "13x",
                    md: "13px",
                    lg: "16px",
                  },
                }}
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              md={3}
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
              }}
            >
              <TextField
                id="outlined-basic"
                label={
                  <span className="text-xs sm:text-sm md:text-base lg:text-lg font-medium">
                    Pincode
                  </span>
                }
                name="pincodes"
                variant="outlined"
                value={pincode}
                onChange={(e) => {
                  setPincode(e.target.value);
                  if (e.target.value?.length === 6) {
                    setFilterPayload({
                      ...filterPayload,
                      pincode: e.target.value,
                    });
                  } else if (e.target.value?.length === 0) {
                    setFilterPayload({
                      ...filterPayload,
                      pincode: null,
                    });
                  }
                }}
                sx={{
                  fontSize: {
                    xs: "12px",
                    sm: "13x",
                    md: "13px",
                    lg: "16px",
                  },
                  width: "100%",
                }}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2} sx={{ marginTop: "5px" }}>
            <Grid
              item
              xs={12}
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Chip label="General Filter" />
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              md={3}
              sx={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <FormControl sx={{ width: "100%" }}>
                <InputLabel
                  size="small"
                  id="demo-simple-select-label"
                  className="text-xs sm:text-sm md:text-base lg:text-lg font-medium"
                >
                  Roles
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={filterPayload?.roles}
                  label="Roles"
                  onChange={(e) =>
                    setFilterPayload({
                      ...filterPayload,
                      roles: e.target.value,
                    })
                  }
                  sx={{
                    fontSize: {
                      xs: "12px",
                      sm: "16px",
                      md: "16px",
                      lg: "16px",
                    },
                  }}
                >
                  <MenuItem
                    value={null}
                    sx={{
                      fontSize: {
                        xs: "12px",
                        sm: "16px",
                        md: "16px",
                        lg: "16px",
                      },
                    }}
                  >
                    --Roles--
                  </MenuItem>
                  {roles?.map((r, i) => (
                    <MenuItem
                      sx={{
                        fontSize: {
                          xs: "12px",
                          sm: "16px",
                          md: "16px",
                          lg: "16px",
                        },
                      }}
                      key={i}
                      value={r?.id}
                    >
                      {r?.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <FormControl sx={{ width: "100%" }}>
                <FormLabel id="demo-controlled-radio-buttons-group">
                  Is Subscribed
                </FormLabel>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group"
                  name="isSubscribed"
                  value={filterPayload?.isApproved}
                  onChange={(e) =>
                    setFilterPayload({
                      ...filterPayload,
                      isApproved: e.target.value,
                    })
                  }
                  sx={{
                    fontSize: {
                      xs: "12px",
                      sm: "16px",
                      md: "16px",
                      lg: "16px",
                    },
                  }}
                >
                  <FormControlLabel
                    value={true}
                    control={
                      <Radio
                        sx={{
                          padding: "8px",
                          "& .MuiSvgIcon-root": {
                            fontSize: {
                              xs: "18px",
                              md: "22px",
                            },
                          },
                        }}
                      />
                    }
                    label={
                      <span className="text-xs sm:text-sm md:text-base lg:text-lg font-medium">
                        True
                      </span>
                    }
                  />
                  <FormControlLabel
                    value={false}
                    control={
                      <Radio
                        sx={{
                          padding: "8px",
                          "& .MuiSvgIcon-root": {
                            fontSize: {
                              xs: "18px",
                              md: "22px",
                            },
                          },
                        }}
                      />
                    }
                    label={
                      <span className="text-xs sm:text-sm md:text-base lg:text-lg font-medium">
                        False
                      </span>
                    }
                  />
                </RadioGroup>
              </FormControl>
            </Grid>
            {[
              {
                label: "Is Deleted",
                value: filterPayload?.isDeleted,
                name: "isDeleted",
              },
              {
                label: "Is Locked",
                value: filterPayload?.isLocked,
                name: "isLocked",
              },
              {
                label: "Is Mydukan Verified",
                value: filterPayload?.isVerified,
                name: "isVerified",
              },
              {
                label: "Joined Whatsapp",
                value: filterPayload?.isJoinedWhatsapp,
                name: "isJoinedWhatsapp",
              },
              {
                label: "GST Verified",
                value: filterPayload?.isGSTVerified,
                name: "isGSTVerified",
              },
            ].map((filter, index) => (
              <Grid key={index} item xs={12} sm={6} md={3}>
                <FormControl sx={{ width: "100%" }}>
                  <FormLabel
                    id={`demo-radio-${filter.name}`}
                    sx={{
                      fontSize: {
                        xs: "12px",
                        sm: "16px",
                        md: "16px",
                        lg: "16px",
                      },
                    }}
                  >
                    {filter.label}
                  </FormLabel>
                  <RadioGroup
                    row
                    aria-labelledby={`demo-radio-${filter.name}`}
                    name={filter.name}
                    value={filter.value}
                    onChange={(e) =>
                      setFilterPayload({
                        ...filterPayload,
                        [filter.name]: e.target.value,
                      })
                    }
                  >
                    <FormControlLabel
                      value={true}
                      control={
                        <Radio
                          sx={{
                            padding: "8px",
                            "& .MuiSvgIcon-root": {
                              fontSize: {
                                xs: "18px",
                                md: "22px",
                              },
                            },
                          }}
                        />
                      }
                      label={
                        <span className="text-xs sm:text-sm md:text-base lg:text-lg font-medium">
                          True
                        </span>
                      }
                    />
                    <FormControlLabel
                      value={false}
                      control={
                        <Radio
                          sx={{
                            padding: "8px",
                            "& .MuiSvgIcon-root": {
                              fontSize: {
                                xs: "18px",
                                md: "22px",
                              },
                            },
                          }}
                        />
                      }
                      label={
                        <span className="text-xs sm:text-sm md:text-base lg:text-lg font-medium">
                          False
                        </span>
                      }
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
            ))}
          </Grid>

          <div>
            <Grid
              container
              spacing={2}
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: "10px",
              }}
            >
              <Grid
                sx={{
                  width: "100%",
                  justifyContent: { xs: "center", lg: "flex-start" },
                }}
              >
                <Button
                  variant="contained"
                  size="medium"
                  color="secondary"
                  onClick={clearSearch}
                  sx={{
                    height: { xs: "40px", md: "52px" },
                    fontSize: {
                      xs: "12px",
                      sm: "13px",
                      md: "13px",
                      lg: "16px",
                    },
                  }}
                >
                  Reset All
                </Button>
              </Grid>
            </Grid>
          </div>
        </div>
      </div>
      <div
        className={
          "relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded " +
          (color === "light" ? "bg-white" : "bg-lightBlue-900 text-white")
        }
      >
        <div className="block w-full overflow-x-auto py-3 px-5 lg:p-8">
          <div className="text-center border flex-col xl:flex-row flex justify-between p-2 mb-2">
            <div className="my-1">
              <Stack
                direction={{ md: "row", xs: "column" }}
                divider={<Divider orientation="vertical" flexItem />}
                spacing={2}
                justifyContent={"center"}
              >
                <FormControl>
                  <InputLabel id="demo-simple-select-label">Size</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    size="small"
                    value={filterPayload.size}
                    label="Size"
                    onChange={(e) => {
                      setCurrentPage(1);
                      setFilterPayload({
                        ...filterPayload,
                        size: e.target.value,
                      });
                    }}
                    sx={{
                      fontSize: {
                        xs: "12px",
                        sm: "13x",
                        md: "13px",
                        lg: "16px",
                      },
                    }}
                  >
                    <MenuItem
                      value={10}
                      sx={{
                        fontSize: {
                          xs: "12px",
                          sm: "13x",
                          md: "13px",
                          lg: "16px",
                        },
                      }}
                    >
                      10
                    </MenuItem>
                    <MenuItem
                      value={25}
                      sx={{
                        fontSize: {
                          xs: "12px",
                          sm: "13x",
                          md: "13px",
                          lg: "16px",
                        },
                      }}
                    >
                      25
                    </MenuItem>
                    <MenuItem
                      value={50}
                      sx={{
                        fontSize: {
                          xs: "12px",
                          sm: "13x",
                          md: "13px",
                          lg: "16px",
                        },
                      }}
                    >
                      50
                    </MenuItem>
                    <MenuItem
                      value={100}
                      sx={{
                        fontSize: {
                          xs: "12px",
                          sm: "13x",
                          md: "13px",
                          lg: "16px",
                        },
                      }}
                    >
                      100
                    </MenuItem>
                  </Select>
                </FormControl>
                <Pagination
                  count={totalPages}
                  page={currentPage}
                  color="primary"
                  onChange={handlePageChange}
                />
                <div className="flex text-center items-center my-0 lg:my-1.5">
                  <span className="text-sm text-center font-medium align-baseline">
                    Total :- {totalElements}
                  </span>
                </div>
              </Stack>
            </div>
            <Button
              variant="contained"
              color="success"
              onClick={handleClickOpenExcelDialog}
            >
              Export User Data
            </Button>
          </div>
          <div className="text-left ml-3 mt-2">
            <Dialog
              open={openExcelDialog}
              onClose={handleCloseExcelDialog}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">
                Export User Data
              </DialogTitle>
              <DialogContent>
                You are Downloading user data of{" "}
                <TextField
                  id="filled-basic"
                  onChange={(e) => {
                    getUsers(0, e.target.value);
                    setUserSize(e.target.value);
                  }}
                  variant="standard"
                  sx={{ width: "60px" }}
                  value={userSize}
                />{" "}
                users
              </DialogContent>
              <DialogActions>
                <ExcelExport excelData={excelData} fileName={fileName} />
              </DialogActions>
            </Dialog>
          </div>

          <table className="items-center w-full bg-transparent border-collapse">
            <thead className="bg-gray-100">
              <tr className="divide-x divide-gray-200 ">
                <TableHeader heading="" />
                <TableHeader heading="User" />
                <TableHeader heading="Roles" />
                <TableHeader heading="Business" />
                <TableHeader heading="Last Login" />
                <TableHeader heading="Logins" />
                <TableHeader heading="Subscription" />
                <TableHeader heading="Status" />
                <TableHeader heading="Joined" />
                <TableHeader heading="" />
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-200 bg-white">
              {users.map((u, i) => {
                return (
                  <>
                    <UserRow
                      roles={roles}
                      u={u}
                      i={i}
                      currentPage={currentPage}
                      setCurrentPage={setCurrentPage}
                      size={filterPayload.size}
                    />
                  </>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
}

const UserRow = ({ u, i, setCurrentPage, currentPage, size, roles }) => {
  const [openUserDetails, setOpenUserDetails] = useState(false);
  const [openBusinessDetails, setBusinessUserDetails] = useState(false);
  const [openSubscriptionDetails, setOpenSubscriptionDetails] = useState(false);
  const [openAddSubscription, setOpenAddSubscription] = useState(false);
  const [usersSubscriptions, setUsersSubscriptions] = useState([]);
  const [isEditUser, setIsEditUser] = useState(false);
  const [brands, setBrands] = useState([]);
  const [brandFilter, setBrandFilter] = useState({});
  const [formData, setFormData] = useState({
    createdOn: "",
    lastUpdatedOn: "",
    id: "",
    name: "",
    email: "",
    mobile: "",
    business: {
      name: "",
      email: "",
      mobile: "",
      gstNumber: "",
    },
    isApproved: true,
    isVerified: true,
    usedTrial: true,
    isDeleted: false,
    isJoinedWhatsapp: false,
    isLocked: false,
    lastLoginOn: "",
  });
  const [addressFromPIN, setAddressFromPIN] = useState({
    country: "",
    pincode: "",
    district: [],
    state: [],
  });
  const [businessProfilePayload, setBusinessProfilePayload] = useState({
    name: "",
    email: "",
    mobile: "",
    gstNumber: "",
    designation: "",
    brandId: "",
    addressDTO: {
      addresslineOne: "",
      addresslineTwo: "",
      landmark: "",
      city: "",
      pincode: addressFromPIN.pincode,
      districtId:
        addressFromPIN.district.length > 0 && addressFromPIN.district[0].id,
      stateId: addressFromPIN.state.length > 0 && addressFromPIN.state[0].id,
      country: addressFromPIN.country,
    },
    logo: "",
    documents: {
      gstCertificate: "",
      businessCard: "",
      shopImage: "",
      logo: "",
    },
  });
  const [userAddressData, setUserAddressData] = useState({
    addresslineOne: "",
    addresslineTwo: "",
    landmark: "",
    city: "",
    pincode: addressFromPIN.pincode,
    districtId:
      addressFromPIN.district.length > 0 && addressFromPIN.district[0].id,
    stateId: addressFromPIN.state.length > 0 && addressFromPIN.state[0].id,
    country: addressFromPIN.country,
  });

  const [subscription, setSubscription] = useState([]);
  const [addSubscription, setAddSubscription] = useState({
    userId: u.id,
    subscriptionId: null,
    startDate: new Date().toISOString().slice(0, 10),
  });

  const getBrands = () => {
    BrandsApi.GetBrandsListByFilter({
      page: 0,
      size: 99,
    })
      .then((res) => {
        setBrands(res.data.data);
      })
      .catch((err) => {
        if (err?.response?.data) {
          toast(err.response.status + " " + err?.response?.data?.message, {
            type: "error",
            position: toast.POSITION.TOP_CENTER,
            autoClose: 1000,
          });
        } else {
          toast(err?.response?.data?.message, {
            type: "error",
            position: toast.POSITION.TOP_CENTER,
            autoClose: 1000,
          });
        }
      });
  };

  const createSubscription = (payload) => {
    UsersSubscriptionsApi.AddUserSubscription(payload)
      .then((res) => {
        setOpenAddSubscription(false);
        setOpenSubscriptionDetails(false);
        setAddSubscription({
          userId: u.id,
          subscriptionId: null,
          startDate: new Date().toISOString().slice(0, 10),
        });
        toast("Subscripton Added", {
          type: "success",
          position: toast.POSITION.TOP_CENTER,
          autoClose: 1000,
        });
      })
      .catch((err) => {
        if (err?.response?.data) {
          toast(err.response?.status + " " + err?.response?.data?.message, {
            type: "error",
            position: toast.POSITION.TOP_CENTER,
            autoClose: 1000,
          });
        } else {
          toast(err?.response?.data?.message, {
            type: "error",
            position: toast.POSITION.TOP_CENTER,
            autoClose: 1000,
          });
        }
        if (err.response?.status === 401) {
          localStorage.clear();
        }
      });
  };

  const searchUserSubscription = (userId) => {
    UsersSubscriptionsApi.GetUserSubscriptions({
      page: 0,
      size: 20,
      userId: userId,
    })
      .then((res) => {
        setUsersSubscriptions(res.data.data);
        setOpenSubscriptionDetails(true);
      })
      .catch((err) => {
        toast(err?.response?.data?.message, {
          type: "error",
          position: toast.POSITION.TOP_CENTER,
          autoClose: 1000,
        });
      });
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
    setBusinessProfilePayload((prev) => ({ ...prev, [name]: value }));
  };

  const handleChangeBusinessEdit = (event) => {
    const { name, value } = event.target;
    setBusinessProfilePayload({
      ...businessProfilePayload,
      [name]: value,
    });
  };

  const handleAddressChange = (event) => {
    const { name, value } = event.target;
    setUserAddressData({
      ...userAddressData,
      [name]: value,
    });
  };

  const fetchFileURLOfDocs = (key, type, docsName) => {
    FilesApi.GetURL({
      key,
      type,
    })
      .then((res) => {
        setBusinessProfilePayload({
          ...businessProfilePayload,
          business: {
            ...businessProfilePayload.business,
            documents: {
              ...businessProfilePayload.business.documents,
              [docsName]: res.data.url,
            },
          },
        });
      })
      .catch((err) => {
        toast(err?.response?.data?.message, {
          type: "error",
          position: toast.POSITION.TOP_CENTER,
          autoClose: 1000,
        });
      });
  };

  const edit = async (payload) => {
    UsersApi.EditUsers(payload.id, payload)
      .then((res) => {
        toast("User Updated", {
          type: "success",
          position: toast.POSITION.TOP_CENTER,
          autoClose: 1000,
        });
        setIsEditUser(false);
        setCurrentPage(currentPage - 1);
      })
      .catch((err) => {
        toast(err?.response?.data?.message, {
          type: "error",
          position: toast.POSITION.TOP_CENTER,
          autoClose: 1000,
        });
      });
  };

  const editBusinessProfile = async (payload) => {
    UsersApi.EditBusinessProfile(formData.id, payload)
      .then((res) => {
        setIsEditUser(false);
        setCurrentPage(currentPage - 1);
      })
      .catch((err) => {
        toast(err?.response?.data?.message, {
          type: "error",
          position: toast.POSITION.TOP_CENTER,
          autoClose: 1000,
        });
      });
  };

  const getSubscription = () => {
    UsersSubscriptionsApi.GetSubscription()
      .then((res) => {
        setSubscription(res.data.data);
      })
      .catch((err) => {
        toast(err?.response?.data?.message, {
          type: "error",
          position: toast.POSITION.TOP_CENTER,
          autoClose: 1000,
        });
      });
  };

  const editUser = async (row) => {
    try {
      const addressResp = await MasterApi.GetAddress({
        pin: row?.business?.address?.pincode,
      });
      setAddressFromPIN(addressResp.data.data);
    } catch (err) {
      toast(err?.response?.data?.message, {
        type: "error",
        position: toast.POSITION.TOP_CENTER,
        autoClose: 1000,
      });
    }

    setIsEditUser(true);
    setFormData({
      id: row.id,
      name: row.name,
      roleId: row.roles[0].id,
      email: row.email,
      mobile: row.mobile,
      isApproved: row.isApproved,
      isDeleted: row.isDeleted,
      isLocked: row.isLocked,
      isVerified: row.isVerified,
      isJoinedWhatsapp: row.isJoinedWhatsapp,
    });
    setBusinessProfilePayload({
      name: row?.business?.name,
      email: row?.business?.email,
      mobile: row?.business?.mobile,
      gstNumber: row?.business?.gstNumber,
      designation: row?.business?.designation,
      brandId: row?.business?.brand?.id,
      addressDTO: {
        addresslineOne: row?.business?.address?.addresslineOne,
        addresslineTwo: row?.business?.address?.addresslineTwo,
        landmark: row?.business?.address?.landmark,
        city: row?.business?.address?.city,
        pincode: row?.business?.address?.pincode,
        districtId: row?.business?.address?.district?.id,
        stateId: row?.business?.address?.state?.id,
        country: row?.business?.address?.country,
      },
      logo: row?.business?.logo,
      documents: {
        gstCertificate: row?.business?.documents?.gstCertificate,
        businessCard: row?.business?.documents?.businessCard,
        shopImage: row?.business?.documents?.shopImage,
        logo: row?.business?.documents?.logo,
      },
    });
  };

  useEffect(() => {
    getBrands();
  }, []);

  return (
    <>
      <Dialog
        maxWidth="md"
        fullWidth
        open={openAddSubscription}
        onClose={() => setOpenAddSubscription(false)}
      >
        <DialogContent>
          <div className="flex flex-col ">
            <div className="mt-4">
              <table className="min-w-full divide-y divide-gray-200">
                <thead className="bg-gray-100">
                  <tr className="divide-x divide-gray-200 ">
                    <TableHeader heading="" />
                    <TableHeader heading="Name" />
                    <TableHeader heading="Mobile" />
                    <TableHeader heading="Start Date" />
                    <TableHeader heading="Select Subscription" />
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200 bg-white">
                  <tr
                    key={i}
                    style={{ padding: "5px" }}
                    className="divide-x divide-gray-200 hover:bg-slate-100"
                  >
                    <TableElement value={i + 1} />
                    <TableElement value={u?.name} />
                    <TableElement value={u?.mobile} />
                    <TableElement
                      value={
                        <>
                          <TextField
                            type="date"
                            onChange={(e) =>
                              setAddSubscription({
                                ...addSubscription,
                                startDate: e?.target.value,
                              })
                            }
                            value={addSubscription.startDate}
                            fullWidth
                            id="outlined-basic"
                            label="Start Date"
                            variant="outlined"
                          />
                        </>
                      }
                    />
                    <TableElement
                      value={
                        <>
                          <div className="p-2">
                            <Select
                              fullWidth
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              label="Select Subscription"
                              value={addSubscription.subscriptionId}
                              onChange={(e) => {
                                setAddSubscription({
                                  ...addSubscription,
                                  subscriptionId: e?.target?.value,
                                  userId: u?.id,
                                });
                              }}
                            >
                              {subscription?.map((m, i) => (
                                <MenuItem key={i} value={m?.id}>
                                  {m?.name}
                                </MenuItem>
                              ))}
                            </Select>
                          </div>
                        </>
                      }
                    />
                  </tr>
                </tbody>
              </table>
            </div>
            <Grid container spacing={2}>
              <div className="text-right">
                <Button
                  variant="contained"
                  size="medium"
                  color="primary"
                  sx={{ m: 4 }}
                  onClick={() => createSubscription(addSubscription)}
                >
                  Add Subscription
                </Button>
              </div>
            </Grid>
          </div>
        </DialogContent>
      </Dialog>
      <Dialog
        fullWidth
        maxWidth="md"
        open={openUserDetails}
        onClose={() => setOpenUserDetails(false)}
      >
        <DialogTitle>
          <div className=" relative flex justify-center w-full font-bold">
            <span>user details</span>
            <span
              className="absolute top-0 right-0 text-red-500 cursor-pointer"
              variant="text"
              onClick={() => setOpenUserDetails(false)}
            >
              close
            </span>
          </div>
        </DialogTitle>
        <DialogContent dividers>
          <table className="items-center w-full bg-transparent border-collapse">
            <thead className="bg-gray-300">
              <tr className="divide-x  divide-gray-200 ">
                <TableHeader heading="Mobile" />
                <TableHeader heading="Name" />
                <TableHeader heading="Email" />
                <TableHeader heading="Roles" />
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-200 bg-white">
              <tr
                key={i}
                className="divide-x divide-gray-200 hover:bg-slate-100"
              >
                <TableElement value={u.mobile} />
                <TableElement value={u.name} />
                <TableElement value={u.email} />
                <TableElement value={u?.roles?.map((e) => e.name).join(",")} />
              </tr>
            </tbody>
          </table>
        </DialogContent>
      </Dialog>
      <Dialog
        fullWidth
        maxWidth="md"
        open={openSubscriptionDetails}
        onClose={() => setOpenSubscriptionDetails(false)}
      >
        <DialogTitle>
          <div className=" relative flex justify-center w-full font-bold">
            <span>subscription details</span>
            <span
              className="absolute top-0 right-0 text-red-500 cursor-pointer"
              variant="text"
              onClick={() => setOpenSubscriptionDetails(false)}
            >
              close
            </span>
          </div>
        </DialogTitle>
        <DialogContent dividers>
          <table className="min-w-full divide-y divide-gray-200">
            <thead className="bg-gray-100">
              <tr className="divide-x divide-gray-200 ">
                <TableHeader heading="" />
                <TableHeader heading="Name" />
                <TableHeader heading="Mobile" />
                <TableHeader heading="Start date" />
                <TableHeader heading="End date" />
                <TableHeader heading="Price" />
                <TableHeader heading="Active" />
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-200 bg-white">
              {usersSubscriptions.map((us, index) => {
                return (
                  <tr
                    key={index}
                    className="divide-x divide-gray-200 hover:bg-slate-100"
                  >
                    <TableElement value={index + 1} />
                    <TableElement value={us.user.name} />
                    <TableElement value={us.user.mobile} />
                    <TableElement value={formatDate(us.startDate)} />
                    <TableElement value={formatDate(us.endDate)} />
                    <TableElement value={us.price} />
                    <TableElement value={us.isActive} type="boolean" />
                  </tr>
                );
              })}
            </tbody>
          </table>
          <Button
            sx={{ m: 2 }}
            variant="contained"
            onClick={() => {
              setOpenAddSubscription(true);
              getSubscription();
            }}
          >
            ADD Subscription
          </Button>
        </DialogContent>
      </Dialog>
      <Dialog
        fullWidth
        maxWidth="md"
        open={openBusinessDetails}
        onClose={() => setBusinessUserDetails(false)}
      >
        <DialogTitle>
          <div className=" relative flex justify-center w-full font-bold">
            <span>Business details</span>
            <span
              className="absolute top-0 right-0 text-red-500 cursor-pointer"
              variant="text"
              onClick={() => setBusinessUserDetails(false)}
            >
              close
            </span>
          </div>
        </DialogTitle>
        <DialogContent dividers>
          <table className="items-center w-full bg-transparent border-collapse">
            <thead className="bg-gray-300">
              <tr className="divide-x  divide-gray-200 ">
                <TableHeader heading="Mobile" />
                <TableHeader heading="Name" />
                <TableHeader heading="Email" />
                <TableHeader heading="GST" />
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-200 bg-white">
              <tr
                key={i}
                className="divide-x divide-gray-200 hover:bg-slate-100"
              >
                <TableElement value={u?.business?.mobile} />
                <TableElement value={u?.business?.name} />
                <TableElement value={u?.business?.email} />
                <TableElement value={u?.business?.gstNumber} />
              </tr>
            </tbody>
          </table>
          {/* <span className="font-bold mt-4">documents:- </span> */}
          <table className=" my-4 items-center w-full bg-transparent border-collapse">
            <thead className="bg-gray-300">
              <tr className="divide-x  divide-gray-200 ">
                <TableHeader heading="GST Certificate" />
                <TableHeader heading="Card " />
                <TableHeader heading="Shop" />
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-200 bg-white">
              <tr
                key={i}
                className="divide-x divide-gray-200 hover:bg-slate-100"
              >
                <TableElement
                  value={
                    u?.business?.documents?.gstCertificate ? (
                      <div>
                        <Button
                          variant="text"
                          href={u?.business?.documents?.gstCertificateURL}
                        >
                          Show
                        </Button>
                      </div>
                    ) : (
                      <></>
                    )
                  }
                />

                <TableElement
                  value={
                    u?.business?.documents?.businessCard ? (
                      <div>
                        <Button
                          variant="text"
                          href={u?.business?.documents?.businessCardURL}
                        >
                          Show
                        </Button>
                      </div>
                    ) : (
                      <></>
                    )
                  }
                />
                <TableElement
                  value={
                    u?.business?.documents?.shopImage ? (
                      <div>
                        <Button
                          variant="text"
                          href={u?.business?.documents?.shopImageURL}
                        >
                          Show
                        </Button>
                      </div>
                    ) : (
                      <></>
                    )
                  }
                />
              </tr>
            </tbody>
          </table>
          <table className="  my-4 items-center w-full bg-transparent border-collapse">
            <thead className="bg-gray-300">
              <tr className="divide-x  divide-gray-200 ">
                <TableHeader heading="District" />
                <TableHeader heading="State" />
                <TableHeader heading="Pin Code" />
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-200 bg-white">
              <tr
                key={i}
                className="divide-x divide-gray-200 hover:bg-slate-100"
              >
                <TableElement value={u?.business?.address?.district.name} />
                <TableElement value={u?.business?.address?.state.name} />
                <TableElement value={u?.business?.address?.pincode} />
              </tr>
            </tbody>
          </table>
          <table className="  my-4 items-center w-full bg-transparent border-collapse">
            <thead className="bg-gray-300">
              <tr className="divide-x  divide-gray-200 ">
                <TableHeader heading="Brand" />
                <TableHeader heading="Designation" />
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-200 bg-white">
              <tr
                key={i}
                className="divide-x divide-gray-200 hover:bg-slate-100"
              >
                <TableElement
                  value={u?.business?.brand ? u?.business?.brand?.name : "-"}
                />
                <TableElement
                  value={
                    u?.business?.designation ? u?.business?.designation : "-"
                  }
                />
              </tr>
            </tbody>
          </table>
        </DialogContent>
      </Dialog>
      <Dialog
        fullWidth
        maxWidth="lg"
        open={isEditUser}
        onClose={(e, r) => {
          if (r !== "backdropClick") setIsEditUser(false);
        }}
      >
        <DialogTitle>
          <div className=" relative flex justify-center w-full font-bold">
            <span>edit user - {u.name ? u.name : u.mobile}</span>
            <span
              className="absolute top-0 right-0 text-red-500 cursor-pointer"
              variant="text"
              onClick={() => setIsEditUser(false)}
            >
              close
            </span>
          </div>
        </DialogTitle>
        <DialogContent dividers>
          <span>user details:- </span>
          <Grid container v spacing={3}>
            <Grid item xs={12} md={4}>
              <TextField
                sx={{ marginY: 2 }}
                label="Name"
                fullWidth
                name="name"
                variant="standard"
                value={formData?.name}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <TextField
                disabled
                sx={{ marginY: 2 }}
                label="Mobile"
                fullWidth
                name="mobile"
                variant="standard"
                value={formData?.mobile}
                // onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <TextField
                fullWidth
                variant="standard"
                type="email"
                label="Email"
                name="email"
                sx={{ marginY: 2 }}
                value={formData?.email}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <FormControl sx={{ marginY: 2, minWidth: "100%" }}>
                <InputLabel id="demo-simple-select-label">
                  Select Role
                </InputLabel>
                <Select
                  key={i + 1}
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  sx={{ marginY: 2 }}
                  variant="standard"
                  value={formData?.roleId}
                  name="roleId"
                  label="Role"
                  onChange={(e) => {
                    setFormData({
                      ...formData,
                      roleId: e?.target?.value,
                    });
                  }}
                >
                  <MenuItem key={0} value="">
                    --Select Role--
                  </MenuItem>
                  {roles.map((r, i) => (
                    <MenuItem key={i + 1} value={r.id}>
                      {r.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
          <span>status:- </span>
          <Grid container spacing={3}>
            <Grid item xs={12} md={4}>
              <FormControl sx={{ marginY: 2 }}>
                <FormLabel id="demo-controlled-radio-buttons-group">
                  Subscribed
                </FormLabel>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group"
                  name="isApproved"
                  value={formData?.isApproved}
                  onChange={handleChange}
                >
                  <FormControlLabel
                    value={true}
                    control={<Radio />}
                    label="True"
                  />
                  <FormControlLabel
                    value={false}
                    control={<Radio />}
                    label="False"
                  />
                </RadioGroup>
              </FormControl>
              <FormControl sx={{ marginY: 2 }}>
                <FormLabel id="demo-controlled-radio-buttons-group">
                  Verified
                </FormLabel>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group"
                  name="isVerified"
                  value={formData?.isVerified}
                  onChange={handleChange}
                >
                  <FormControlLabel
                    value={true}
                    control={<Radio />}
                    label="True"
                  />
                  <FormControlLabel
                    value={false}
                    control={<Radio />}
                    label="False"
                  />
                </RadioGroup>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={4}>
              <FormControl sx={{ marginY: 2 }}>
                <FormLabel id="demo-controlled-radio-buttons-group">
                  Locked
                </FormLabel>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group"
                  name="isLocked"
                  value={formData?.isLocked}
                  onChange={handleChange}
                >
                  <FormControlLabel
                    value={true}
                    control={<Radio />}
                    label="True"
                  />
                  <FormControlLabel
                    value={false}
                    control={<Radio />}
                    label="False"
                  />
                </RadioGroup>
              </FormControl>
              <FormControl sx={{ marginY: 2 }}>
                <FormLabel id="demo-controlled-radio-buttons-group">
                  Deleted
                </FormLabel>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group"
                  name="isDeleted"
                  value={formData?.isDeleted}
                  onChange={handleChange}
                >
                  <FormControlLabel
                    value={true}
                    control={<Radio />}
                    label="True"
                  />
                  <FormControlLabel
                    value={false}
                    control={<Radio />}
                    label="False"
                  />
                </RadioGroup>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={4}>
              <FormControl sx={{ marginY: 2 }}>
                <FormLabel id="demo-controlled-radio-buttons-group">
                  Joined Whatsapp
                </FormLabel>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group"
                  name="isJoinedWhatsapp"
                  value={formData?.isJoinedWhatsapp}
                  onChange={handleChange}
                >
                  <FormControlLabel
                    value={true}
                    control={<Radio />}
                    label="True"
                  />
                  <FormControlLabel
                    value={false}
                    control={<Radio />}
                    label="False"
                  />
                </RadioGroup>
              </FormControl>
            </Grid>
          </Grid>
          <span>business details:- </span>
          <Grid container spacing={3}>
            <Grid item xs={12} md={4}>
              <TextField
                label="Name"
                name="name"
                variant="standard"
                fullWidth
                sx={{ marginY: 2 }}
                value={businessProfilePayload?.name}
                onChange={handleChangeBusinessEdit}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <TextField
                label="Mobile"
                name="mobile"
                variant="standard"
                fullWidth
                sx={{ marginY: 2 }}
                value={businessProfilePayload?.mobile}
                onChange={handleChangeBusinessEdit}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <TextField
                label="Email"
                name="email"
                variant="standard"
                fullWidth
                sx={{ marginY: 2 }}
                value={businessProfilePayload?.email}
                onChange={handleChangeBusinessEdit}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <FormControl sx={{ marginY: 2, minWidth: "100%" }}>
                <InputLabel id="demo-simple-select-label">
                  Select Brand
                </InputLabel>
                <Select
                  key={i + 1}
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  sx={{ marginY: 2 }}
                  variant="standard"
                  value={brandFilter?.name}
                  name="brandId"
                  label="Brand"
                  onChange={(e) => {
                    setBrandFilter(e?.target?.value);
                    setBusinessProfilePayload({
                      ...businessProfilePayload,
                      brandId: e?.target?.value?.id,
                    });
                  }}
                >
                  <MenuItem key={0} value="">
                    --Select Brand--
                  </MenuItem>
                  {brands.map((b, i) => (
                    <MenuItem key={i + 1} value={b}>
                      {b.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={4}>
              <TextField
                label="GST Number"
                name="gstNumber"
                variant="standard"
                fullWidth
                sx={{ marginY: 2 }}
                value={businessProfilePayload?.gstNumber}
                onChange={handleChangeBusinessEdit}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <TextField
                label="Designation"
                name="designation"
                variant="standard"
                fullWidth
                sx={{ marginY: 2 }}
                value={businessProfilePayload?.designation}
                onChange={handleChangeBusinessEdit}
              />
            </Grid>
          </Grid>
          <span>address details:- </span>
          <Grid container spacing={3}>
            <Grid item xs={12} md={4}>
              <TextField
                label="Pincode"
                name="pincode"
                variant="standard"
                fullWidth
                inputProps={{ maxLength: 6 }}
                sx={{ marginY: 2 }}
                value={businessProfilePayload?.addressDTO?.pincode}
                onChange={async (e) => {
                  setBusinessProfilePayload((prevProfile) => ({
                    ...prevProfile,
                    addressDTO: {
                      ...prevProfile.addressDTO,
                      pincode: e.target.value,
                    },
                  }));
                  if (e.target.value.length === 6) {
                    try {
                      const addressResp = await MasterApi.GetAddress({
                        pin: e.target.value,
                      });
                      setAddressFromPIN(addressResp.data.data);
                      setBusinessProfilePayload({
                        addressDTO: {
                          ...businessProfilePayload.addressDTO,
                          stateId:
                            addressResp.data.data.state.length > 0 &&
                            addressResp.data.data.state[0].id,
                          districtId:
                            addressResp.data.data.district.length > 0 &&
                            addressResp.data.data.district[0].id,
                          city:
                            addressResp.data.data.district.length > 0 &&
                            addressResp.data.data.district[0].name,
                          pincode: addressResp.data.data.pincode,
                        },
                      });
                    } catch (err) {
                      toast(err?.response?.data?.message, {
                        type: "error",
                        position: toast.POSITION.TOP_CENTER,
                        autoClose: 1000,
                      });
                    }
                  }
                }}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <FormControl
                variant="standard"
                sx={{ marginY: 2, minWidth: "100%" }}
              >
                <InputLabel id="demo-simple-select-standard-label">
                  District
                </InputLabel>
                <Select
                  // disabled
                  key={i}
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={businessProfilePayload?.addressDTO?.districtId}
                  label="District"
                >
                  {addressFromPIN.district?.map((d, i) => (
                    <MenuItem key={i} value={d.id}>
                      {d.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={4}>
              <FormControl
                variant="standard"
                sx={{ marginY: 2, minWidth: "100%" }}
              >
                <InputLabel id="demo-simple-select-standard-label">
                  State
                </InputLabel>
                <Select
                  // disabled
                  key={i + 1}
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  sx={{ marginY: 2 }}
                  value={businessProfilePayload?.addressDTO?.stateId}
                  label="State"
                >
                  {addressFromPIN.state?.map((s, i) => (
                    <MenuItem key={i} value={s.id}>
                      {s.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={4}>
              <TextField
                label="Line 1"
                name="addresslineOne"
                variant="standard"
                fullWidth
                sx={{ marginY: 2 }}
                value={businessProfilePayload?.addressDTO?.addresslineOne}
                onChange={(event) => {
                  setBusinessProfilePayload((prevProfile) => ({
                    ...prevProfile,
                    addressDTO: {
                      ...prevProfile.addressDTO,
                      addresslineOne: event.target.value,
                    },
                  }));
                }}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <TextField
                label="Line 2"
                name="addresslineTwo"
                variant="standard"
                fullWidth
                sx={{ marginY: 2 }}
                value={businessProfilePayload?.addressDTO?.addresslineTwo}
                onChange={(event) => {
                  setBusinessProfilePayload((prevProfile) => ({
                    ...prevProfile,
                    addressDTO: {
                      ...prevProfile.addressDTO,
                      addresslineTwo: event.target.value,
                    },
                  }));
                }}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <TextField
                label="Landmark"
                name="landmark"
                variant="standard"
                fullWidth
                sx={{ marginY: 2 }}
                value={businessProfilePayload?.addressDTO?.landmark}
                onChange={(event) => {
                  setBusinessProfilePayload((prevProfile) => ({
                    ...prevProfile,
                    addressDTO: {
                      ...prevProfile.addressDTO,
                      landmark: event.target.value,
                    },
                  }));
                }}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <TextField
                label="City"
                name="city"
                variant="standard"
                fullWidth
                sx={{ marginY: 2 }}
                value={businessProfilePayload?.addressDTO?.city}
                onChange={(event) => {
                  setBusinessProfilePayload((prevProfile) => ({
                    ...prevProfile,
                    addressDTO: {
                      ...prevProfile.addressDTO,
                      city: event.target.value,
                    },
                  }));
                }}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <TextField
                disabled
                label="Country"
                name="country"
                variant="standard"
                fullWidth
                sx={{ marginY: 2 }}
                value={businessProfilePayload?.addressDTO?.country}
                onChange={(event) => {
                  setBusinessProfilePayload((prevProfile) => ({
                    ...prevProfile,
                    addressDTO: {
                      ...prevProfile.addressDTO,
                      country: event.target.value,
                    },
                  }));
                }}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={async () => {
              await edit(formData);

              setTimeout(async () => {
                await editBusinessProfile(businessProfilePayload);
              }, 1000);
            }}
          >
            Save Changes
          </Button>
        </DialogActions>
      </Dialog>

      <tr key={i} className="divide-x divide-gray-200 hover:bg-slate-100">
        <TableElement
          value={size * (currentPage ? currentPage - 1 : 0) + i + 1}
        />
        <TableElement
          value={
            <div className="flex flex-col">
              <span className="my-1">{u.name ? u.name : u.mobile}</span>
              <Button variant="text" onClick={() => setOpenUserDetails(true)}>
                More
              </Button>
            </div>
          }
          type="element"
        />
        <TableElement
          value={u.roles.map((e) => (
            <>{e.name}</>
          ))}
          type="element"
        />
        <TableElement
          value={
            <div className="flex flex-col">
              <span className="my-1">{u.business?.name}</span>
              <Button
                variant="text"
                onClick={() => {
                  // TODO need to check with ari
                  // if (u.business?.documents?.gstCertificate) {
                  //   fetchFileURLOfDocs(
                  //     "41c11847-7d3a-432f-8313-05ef2c722b2a.",
                  //     "gstcertificate",
                  //     "gstCertificateURL"
                  //   );
                  // }
                  setBusinessUserDetails(true);
                }}
              >
                More
              </Button>
            </div>
          }
          type="element"
        />
        <TableElement
          value={formatDateTimeComponent(u.lastLoginOn)}
          type="element"
        />
        <TableElement value={u.logins} />

        <TableElement
          value={
            <>
              <Button
                variant="text"
                onClick={() => searchUserSubscription(u.id)}
              >
                Show
              </Button>
            </>
          }
          type="element"
        />

        <TableElement
          value={
            <>
              <p
                className={
                  u.isApproved
                    ? " text-emerald-500 font-bold mb-2"
                    : "text-red-500 font-bold mb-2"
                }
              >
                SUBSCRIBED
              </p>
              <p
                className={
                  u.isGSTVerified
                    ? " text-emerald-500 font-bold my-2"
                    : "text-red-500 font-bold my-2"
                }
              >
                GST VERIFIED
              </p>
              <Button
                onDoubleClick={() => {
                  const user = { ...u, isVerified: !u.isVerified };
                  edit(user);
                }}
              >
                <Tooltip title={"Double click to change."} arrow>
                  <span
                    className={
                      u.isVerified
                        ? " text-emerald-500 font-bold"
                        : "text-red-500 font-bold "
                    }
                  >
                    VERIFIED
                  </span>
                </Tooltip>
              </Button>
              <br />
              <Button
                onDoubleClick={() => {
                  const user = { ...u, isJoinedWhatsapp: !u.isJoinedWhatsapp };
                  edit(user);
                }}
              >
                <Tooltip title={"Double click to change."} arrow>
                  <span
                    className={
                      u.isJoinedWhatsapp
                        ? " text-emerald-500 font-bold"
                        : "text-red-500 font-bold "
                    }
                  >
                    WHATSAPP
                  </span>
                </Tooltip>
              </Button>
            </>
          }
          type="element"
        />
        <TableElement
          value={formatDateTimeComponent(u.createdOn)}
          type="element"
        />
        <TableElement
          value={
            <>
              <span
                className="cursor-pointer text-sky-500 hover:text-sky-950 font-bold gr "
                onClick={async () => {
                  await editUser(u);
                }}
              >
                EDIT
              </span>
            </>
          }
          type="element"
        />
      </tr>
    </>
  );
};
