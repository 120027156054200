import React, { useState, useEffect } from "react";
import { Button, Input, TextField, Typography } from "@mui/material";
import * as XLSX from "xlsx";
import { UsersSubscriptionsApi } from "apis/UsersSubscriptionsApi";
import { UsersApi } from "apis/UsersApi";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import { toast } from "react-toastify";
import TableHeader from "components/Table/TableHeader";
import TableElement from "components/Table/TableElement";
// import { useNavigate } from "react-router";
import CloseIcon from "@mui/icons-material/Close";
import { useDispatch, useSelector } from "react-redux";
import { setLoadingPanel } from "../../redux/features/loading";
import CircularIndeterminateModal from "../../utils/Loading";

const BulkSubscription = () => {
  const [userData, setUserData] = useState([]);
  const [subscription, setSubscription] = useState([]);
  const dispatch = useDispatch();
  const isLoading = useSelector((state) => state.loadingReducer.isLoading);
  const date = new Date();
  const currentDate =
    date.getFullYear() + "-" + date.getMonth() + "-" + date.getDate();

  const [filterPayload, setFilterPayload] = useState({
    userId: null,
    subscriptionId: null,
    startDate: currentDate,
  });

  const getSubscription = () => {
    dispatch(setLoadingPanel(true));
    UsersSubscriptionsApi.GetSubscription()
      .then((res) => {
        setSubscription(res.data.data);
        dispatch(setLoadingPanel(false));
      })
      .catch((err) => {
        dispatch(setLoadingPanel(false));
        toast(err?.response?.data?.message, {
          type: "error",
          position: toast.POSITION.TOP_CENTER,
          autoClose: 1000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          style: {
            maxWidth: "90vw",
            width: "auto",
            margin: "5px  auto",
            padding: "5px 5px",
            fontSize: "14px",
          },
          closeButton: (
            <div className="flex justify-end">
              <button className="text-gray-500 hover:text-gray-800 focus:outline">
                <CloseIcon className="w-7 h-7" />
              </button>
            </div>
          ),
        });
      });
  };

  const getUsers = async (mobile) => {
    return UsersApi.GetUsersListByFilter({
      mobile: mobile,
      page: 0,
      size: 25,
    });
  };

  const createSubscription = async (filterPayload) => {
    await UsersSubscriptionsApi.AddUserSubscription(filterPayload);
  };

  const addSubscription = async (userData) => {
    if (filterPayload.subscriptionId === null) {
      toast("Select Subscription First", {
        type: "error",
        position: toast.POSITION.TOP_CENTER,
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        style: {
          maxWidth: "90vw",
          width: "auto",
          margin: "5px  auto",
          padding: "5px 5px",
          fontSize: "14px",
        },
        closeButton: (
          <div className="flex justify-end">
            <button className="text-gray-500 hover:text-gray-800 focus:outline">
              <CloseIcon className="w-7 h-7" />
            </button>
          </div>
        ),
      });
    } else {
      for (let i = 0; i < userData.length; i++) {
        const userResponse = await getUsers(userData[i].mobile);
        const userId = userResponse.data.data[0].id;
        setTimeout(async () => {
          try {
            await createSubscription({
              userId,
              subscriptionId: filterPayload.subscriptionId,
              startDate: filterPayload.startDate,
            });
            toast(`${i + 1} Subscription Added`, {
              type: "success",
              position: toast.POSITION.TOP_CENTER,
              autoClose: 200,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              style: {
                maxWidth: "90vw",
                width: "auto",
                margin: "5px  auto",
                padding: "5px 5px",
                fontSize: "14px",
              },
              closeButton: (
                <div className="flex justify-end">
                  <button className="text-gray-500 hover:text-gray-800 focus:outline">
                    <CloseIcon className="w-7 h-7" />
                  </button>
                </div>
              ),
            });
          } catch (err) {
            toast(`Error in adding ${i + 1}`, {
              type: "error",
              position: toast.POSITION.TOP_CENTER,
              autoClose: 2000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              style: {
                maxWidth: "90vw",
                width: "auto",
                margin: "5px  auto",
                padding: "5px 5px",
                fontSize: "14px",
              },
              closeButton: (
                <div className="flex justify-end">
                  <button className="text-gray-500 hover:text-gray-800 focus:outline">
                    <CloseIcon className="w-7 h-7" />
                  </button>
                </div>
              ),
            });
          }
        }, i * 100);
      }

      setFilterPayload({
        startDate: currentDate,
        subscriptionId: null,
      });
    }
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const data = new Uint8Array(e.target.result);
        const workbook = XLSX.read(data, { type: "array" });
        const sheetName = workbook.SheetNames[0];
        const sheet = workbook.Sheets[sheetName];
        // Extract headers from the first row
        const headers = XLSX.utils.sheet_to_json(sheet, {
          header: 1,
          range: 0,
        })[0];

        // Extract data starting from the second row
        const jsonData = XLSX.utils.sheet_to_json(sheet, {
          header: headers,
          range: 1,
        });
        setUserData(jsonData);
      };

      reader.readAsArrayBuffer(file);
    } else {
      setUserData([]);
    }
  };

  useEffect(() => {
    getSubscription();
  }, []);

  return (
    <>
      <CircularIndeterminateModal open={isLoading} />
      <div
        className={
          "relative flex flex-col justify-start items-center  break-words w-full m-h-[80dvh] shadow-lg rounded bg-white"
        }
      >
        <Typography
          sx={{ paddingY: 2, textAlign: "center" }}
          variant="h4"
          gutterBottom
        >
          Bulk Subscription
        </Typography>
        <div className="flex flex-col lg:gap-0 gap-2 lg:flex-row">
          <div className="m-2">
            <TextField
              type="date"
              onChange={(e) =>
                setFilterPayload({
                  ...filterPayload,
                  startDate: e?.target.value,
                })
              }
              value={filterPayload.startDate}
              fullWidth
              id="outlined-basic"
              label={
                <span className="text-sm md:text-base font-medium">
                  Start Date
                </span>
              }
              InputProps={{
                sx: {
                  fontSize: {
                    xs: "12px",
                    sm: "13px",
                    md: "13px",
                    lg: "16px",
                  },
                },
              }}
              variant="outlined"
            />
          </div>
          <div className="m-2 w-60">
            <FormControl fullWidth>
              <InputLabel
                id="demo-simple-select-label"
                sx={{
                  fontSize: {
                    xs: "12px",
                    sm: "13px",
                    md: "13px",
                    lg: "16px",
                  },
                  width: "100%",
                }}
              >
                Select Subscription
              </InputLabel>
              <Select
                fullWidth
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                label={
                  <span className="text-sm md:text-base font-medium">
                    Select Subscription
                  </span>
                }
                value={filterPayload.subscriptionId}
                onChange={(e) => {
                  setFilterPayload({
                    ...filterPayload,
                    subscriptionId: e?.target?.value,
                  });
                }}
                InputProps={{
                  sx: {
                    fontSize: {
                      xs: "12px",
                      sm: "13px",
                      md: "13px",
                      lg: "16px",
                    },
                  },
                }}
              >
                {subscription?.map((m, i) => (
                  <MenuItem
                    key={i}
                    value={m?.id}
                    sx={{
                      fontSize: {
                        xs: "12px",
                        sm: "13px",
                        md: "13px",
                        lg: "16px",
                      },
                    }}
                  >
                    {m?.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
          <div className="my-auto mx-3">
            <div className=" flex justify-center w-auto my-auto">
              <label htmlFor="file-input">
                <Button
                  variant="contained"
                  component="span"
                  fullWidth
                  color="primary"
                  sx={{
                    height: { xs: "40px", md: "56px" },
                    fontSize: {
                      xs: "12px",
                      sm: "13px",
                      md: "14px",
                      lg: "16px",
                    },
                    maxWidth: { xs: "100%", md: "200px" },
                    width: "100%",
                  }}
                >
                  Upload Excel File
                </Button>
              </label>
            </div>
          </div>
        </div>

        <Input
          key={userData.length}
          type="file"
          accept=".xlsx, .xls"
          onChange={handleFileChange}
          style={{ display: "none" }}
          id="file-input"
        />
        <div className="flex flex-col justify-center items-center px-3 text-center">
          <span className="capitalize">
            upload a excel file containing column "name" and "mobile" in small
            case
          </span>
          <Button
            sx={{ m: 1 }}
            color="secondary"
            onClick={async () => await addSubscription(userData)}
            variant="contained"
            component="span"
          >
            Add Bulk Subscription
          </Button>
          <Button
            sx={{ marginY: 2 }}
            type="reset"
            variant="contained"
            onClick={() => {
              setUserData([]);
              setFilterPayload({
                startDate: currentDate,
                subscriptionId: null,
              });
            }}
          >
            Reset
          </Button>
        </div>

        <div className="w-full py-3 px-4 lg:p-8 overflow-x-auto">
          <table className=" py-4 items-center w-full bg-transparent border-collapse">
            <thead className="bg-gray-300">
              <tr className="divide-x  divide-gray-200 ">
                <TableHeader heading="" />
                <TableHeader heading="Name" />
                <TableHeader heading="Mobile " />
                <TableHeader heading="Start Date" />
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-200 bg-white">
              {userData &&
                userData.map((e, i) => {
                  return (
                    <tr
                      key={i + 1}
                      className="divide-x divide-gray-200 hover:bg-slate-100"
                    >
                      <TableElement value={i + 1} />
                      <TableElement value={e.name} />
                      <TableElement value={e.mobile} />
                      <TableElement value={filterPayload.startDate} />
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default BulkSubscription;
